import { Injectable } from '@angular/core';
import { Events } from '@echofin/libraries';
import { DeletedData } from '@echofin/libraries/api/message/models/deleted-data';
import { EditedData } from '@echofin/libraries/api/message/models/edited-data';
import { IUrlData } from '@echofin/libraries/api/message/models/iurl-data';
import { ReactionModel } from '@echofin/libraries/api/message/models/reaction-model';
import { Signal } from '@echofin/libraries/api/message/models/signal';
import { Socket } from 'ngx-socket-io';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { LocalUserStatusChanged } from '../../../_shared/models/enums/user-status';
import { AuthService } from '../auth.service';
import { LocalUsernameChanged } from '../profile.service';
import { TrackingService } from '../tracking.service';
import { LocalMessageProcessed, Message, MessageStatus } from './../../../_shared/models/commons/message';
import { EvType, SocketEvent } from './models/all.models';
import { SocketStatus } from './models/socket-status';
import { SocketService } from './socket.service';

@Injectable({ providedIn: 'root' })
export class SocketIOService implements SocketService {

  socket: Socket;
  status$: BehaviorSubject<SocketStatus> = new BehaviorSubject<SocketStatus>(SocketStatus.Loading);

  checkForVersion$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private streamData$: { [key: string]: Subject<any> } = {};
  private authenticationRetries = 0;

  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private tracking: TrackingService
  ) {
    Object.keys(EvType).forEach(k => {
      this.streamData$[k] = new Subject<any>();
    });
  }

  async connect(): Promise<void> {

    // this.log('setup socket');

    if (this.socket) {
      this.socket.disconnect();
    }

    this.socket = new Socket({ url: environment.config.endpoints.socketio });

    this.socket.on('connect_error', async (err) => {
      // this.log('connect_error', err);
    });

    this.socket.on('reconnect_error', async (err) => {
      // this.log('connect_error', err);
    });

    this.socket.on('connect', () => {
      // this.log('connected');

      this.authenticate();
      this.dispatchCypress({
        type: 'status',
        status: 'connected'
      });

      // get version file and check
      fetch(`/assets/version.json?t=${Date.now()}`)
        .then(async (response) => {
          const res = await response.json();
          if (res.hash !== environment.version.hash) {
            this.checkForVersion$.next(true);
          }
        })
        .catch((err) => {
          console.log('VERSION.TS error', err);
        });
    });

    this.socket.on('authenticated', () => {
      // this.log('authenticated');
      this.authenticationRetries = 0;
      this.dispatchCypress({
        type: 'status',
        status: 'authenticated'
      });
    });

    this.socket.on('unauthenticated', () => {
      // this.log('unauthenticated');
      if (this.authenticationRetries < 5) {
        this.authenticate();
      } else {
        this.authenticationRetries = 0;
        this.toastr.error('Could not authenticate socket');
      }
      this.dispatchCypress({
        type: 'status',
        status: 'unauthenticated'
      });
    });

    this.socket.on('joined', (channels) => {
      // this.log('joined', channels);
      if (this.status$.value === SocketStatus.Connected) { return; }
      this.status$.next(SocketStatus.Connected);
      this.dispatchCypress({
        channels,
        type: 'status',
        status: 'joined',
      });
    });

    this.socket.on('disconnecting', () => {
      // this.log('disconnecting');
    });

    this.socket.on('disconnect', async (reason) => {
      // this.log('disconnected:', reason);
      this.tracking.trackEvent('socket-disconnect', { reason });
      this.authenticationRetries = 0;
      this.status$.next(SocketStatus.Disconnected);
      this.dispatchCypress({
        type: 'status',
        status: 'disconnected'
      });
      if (reason === 'io server disconnect') {
        setTimeout(
          () => {
            this.connect();
          },
          2000);
      }
    });

    this.socket.on('reconnecting', (attempt) => {
      // this.log('reconnecting', attempt);
    });

    this.socket.on('reconnect', (attempt) => {
      // this.log('reconnected', attempt);
    });

    this.socket.on('reload', (data) => {
      this.streamData$[EvType.Reload].next(data);
    });

    this.socket.on('typing', (data) => {
      // this.log(`[${data.room}] User ${data.username} is typing`);
      this.streamData$[EvType.Typing].next({
        chatroomId: data.room,
        created: new Date,
        profileId: data.userId,
        profileName: data.username,
      });
    });

    this.socket.on('event', (event: SocketEvent) => {
      console.log(event);
      this.handleEvent(event);
      this.dispatchCypress({
        event,
        type: 'event',
      });
    });
  }

  getStream(type: EvType) {
    return this.streamData$[EvType[type]];
  }

  disconnect() {
    if (!this.socket) return;
    this.socket.disconnect();
  }

  // deprecated in favor of alive()
  idle(isIdle: boolean) {
    if (this.status$.value !== SocketStatus.Connected) return;
    this.socket.emit('idle', {
      idle: isIdle,
    });
  }

  alive() {
    if (this.status$.value !== SocketStatus.Connected) return;
    this.socket.emit('alive');
  }

  focus(data) {
    if (this.status$.value !== SocketStatus.Connected) return;
    this.socket.emit('focus', data);
  }

  typing(data: { room: string, participants?: string[], userId: string, username: string }) {
    if (this.status$.value !== SocketStatus.Connected) return;
    this.socket.emit('typing', data);
  }

  private async authenticate() {
    this.authenticationRetries++;
    // this.log('try authenticated', this.authenticationRetries);
    this.status$.next(SocketStatus.Authenticating);
    const token = await this.authService.getShortToken();
    setTimeout(
      () => {
        this.socket.emit('authenticate', { token });
      },
      this.authenticationRetries * 1000);
  }

  private handleEvent(event: SocketEvent) {
    switch (event.type as EvType) {
      case EvType.MessageProcessed: {
        const d = event.data as Events.MessageProcessed;
        // this.log(`[${event.channel}] Message Received ${d.message.id}`);
        const mapped: Message = this.parseMessage(d.message);
        this.streamData$[event.type].next({ ...event.data, message: mapped, type: d.type.toString() } as LocalMessageProcessed);
        break;
      }
      case EvType.UserStatusChanged: {
        const d = event.data as Events.UserStatusChanged;
        const possibleTeamId = (event && event.channel && event.channel.length === 25) ? event.channel.slice(5, 17) : null;
        const validatedTeamId = (possibleTeamId && possibleTeamId.indexOf('tnm_') === 0) ? possibleTeamId : null;
        if (!validatedTeamId) {
          throw new Error('WRONG CHANNEL ID PARSING ON USERSTATUSCHANGED - TEAM ID CONVENTION CHANGED?');
        }
        this.streamData$[event.type].next({ ...event.data, teamId: validatedTeamId } as LocalUserStatusChanged);
        break;
      }
      case EvType.UsernameChanged: {
        const d = event.data as Events.UsernameChanged;
        const possibleTeamId = (event && event.channel && event.channel.length === 25) ? event.channel.slice(5, 17) : null;
        const validatedTeamId = (possibleTeamId && possibleTeamId.indexOf('tnm_') === 0) ? possibleTeamId : null;
        if (!validatedTeamId) {
          throw new Error('WRONG CHANNEL ID PARSING ON USERNAMECHANGED - TEAM ID CONVENTION CHANGED?');
        }
        this.streamData$[event.type].next({ ...event.data, teamId: validatedTeamId } as LocalUsernameChanged);
        break;
      }
      default: {
        // this.log(`[${event.channel}] Event: ${event.type}`, event.data);
        this.streamData$[event.type].next(event.data);
      }
    }
  }

  private dispatchCypress(detail) {
    window.dispatchEvent(new CustomEvent('cypress_socket', {
      detail
    }));
  }

  private parseMessage(m: Events.MessageResp) {
    let urlData: IUrlData = null;
    if (m.urlData) {
      urlData = {
        ...m.urlData,
        type: Events.UrlDataType[Events.UrlDataType[m.urlData.type]] as 'GENERAL' | 'YOUTUBE' | 'TRADINGVIEW'
      };
    }
    let signal: Signal = null;
    if (m.signal) {
      signal = {
        ...m.signal,
        orderType: Events.OrderTypesEnum[Events.OrderTypesEnum[m.signal.orderType]] as 'NONE' | 'BUY' | 'BUY_MARKET' | 'BUY_STOP' | 'BUY_LIMIT' | 'SELL' | 'SELL_MARKET' | 'SELL_STOP' | 'SELL_LIMIT'
      };
    }
    let edited: EditedData = null;
    if (m.edited) {
      edited = {
        ...m.edited
      };
    }
    let deleted: DeletedData = null;
    if (m.deleted) {
      deleted = {
        ...m.deleted,
        timestamp: (new Date(m.deleted.timestamp)).toISOString()
      };
    }

    const mapped: Message = {
      ...m,
      signal,
      urlData,
      edited,
      deleted,
      chatroom: {
        ...m.chatroom,
        type: Events.ChatroomType[Events.ChatroomType[m.chatroom.type]] as 'Direct' | 'Group' | 'Team'
      },
      type: Events.MessageType[Events.MessageType[m.type]] as 'TEXT' | 'SECRET' | 'SHOUT' | 'SIGNAL' | 'TTS',
      status: MessageStatus.DELIVERED,
      timestamp: new Date(m.timestamp as any),
      reactions: m.reactions as unknown as ReactionModel[]
    };
    return mapped;
  }

  private log(...arg: any[]) {
    /* istanbul ignore if  */
    if (environment.config.debug) {
      console.log('%c[SOCKET]', 'color:#FF9800', ...arg);
    }
  }
}
