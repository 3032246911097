<div class="text-center p-4" *ngIf="!loading && status==='success'">
    <div class="text-success mb-4">
        <div class="mb-2">
            <i class="fal fa-check-circle fa-4x"></i>
        </div>
        <h4>Thank you!</h4>
    </div>
    <p>We have sent you an email at<br><strong>{{email.value}}</strong></p>
    <p><small>Please check your email to complete your registration.</small></p>
</div>

<div class="text-center p-4" *ngIf="!loading && status==='LINK_ALREADY_USED'">
    <div class="text-danger mb-4">
        <div class="mb-2">
            <i class="fal fa-sad-tear fa-4x"></i>
        </div>
        <h4>Email is taken</h4>
    </div>
    <p><a href="javascript:" (click)="status='start'" [ngStyle]="{'color': colorCode}">Sign up</a> with a different
        email address or <a [ngStyle]="{'color': colorCode}" routerLink="/login">Sign In</a>.</p>
    <p><a routerLink="/reset-password" [ngStyle]="{'color': colorCode}">Forgot Password</a>?</p>
</div>

<div class="text-center p-4" *ngIf="!loading && status==='fail-generic'">
    <div class="text-danger mb-4">
        <div class="mb-2">
            <i class="fal fa-sad-tear fa-4x"></i>
        </div>
        <h4>Oh no!</h4>
    </div>
    <p>Something went wrong</p>
    <p><a [ngStyle]="{'color': colorCode}" href="javascript:" (click)="status='start'">try again</a>.</p>
</div>

<div *ngIf="status==='start'||status==='validation-error'">
    <div class="header">
        <h1>Sign Up</h1>
        <p *ngIf="team.name && team.name!=='app'">
            Join <span [ngStyle]="{'color': colorCode}" class="one-line-ellipsis team-name">{{ teamName }}</span>
            <ng-container *ngIf="!isEchofin"> community</ng-container>
        </p>
    </div>
    <ng-container
        *ngIf="team && team.config && team.config.join && team.config.join.state && team.config.join.state === 'NONE'">
        <p class="text-center">This is a private community.<br />You need to have an invitation link to join.</p>
    </ng-container>
    <ng-container
        *ngIf="(team && team.config && team.config.join && team.config.join.state && team.config.join.state !== 'NONE') || !team || !team.config || !team.config.join || !team.config.join.state">
        <p class="text-center"
            *ngIf="team && team.config && team.config.join && team.config.join.state && team.config.join.state === 'CUSTOM'">
            This community limits members to specific email addresses.
            Contact the community owner if you experience any problem.
        </p>
        <!-- <p class="text-center"
            *ngIf="!team || !team.config || !team.config.join || !team.config.join.state || team.config.join.state === 'ALL'">
            We will send you an email. Follow the link inside to complete
            your registration <span *ngIf="team.name && team.name!=='app'">and join {{team.name}}</span>
        </p> -->
        <form (ngSubmit)="requestEmail()" #regForm="ngForm">
            <div class="custom-form-group">
                <input class="custom-form-control" [ngStyle]="{'border-color': colorCode}" [formControl]="email"
                    type="email" placeholder="Email">
            </div>
            <p *ngIf="email.dirty && email.touched && email.invalid && email.errors.email" class="text-danger">
                <small>The email is no a valid email address.</small>
            </p>
            <div>
                <button type="submit" [ngStyle]="{'background-color': colorCode, color:contrastColor}"
                    [disabled]="loading">
                    <i *ngIf="loading" class="fas fa-sync-alt fa-fw fa-spin"></i>
                    <span class="ml-2">SIGN UP</span>
                </button>
            </div>
            <p class="text-center mt-3" style="filter:brightness(.8)">
                <small>
                    By Signing Up, you agree to Ensemble's <br>
                    <a href="https://www.ensemble.chat/terms/" target="_blank" [ngStyle]="{'color': colorCode}">Terms &
                        Conditions</a>,
                    <a href="https://www.ensemble.chat/privacy/" target="_blank" [ngStyle]="{'color': colorCode}">Privacy
                        & Cookies Policy</a> and
                    <a href="https://www.ensemble.chat/guidelines/" target="_blank" [ngStyle]="{'color': colorCode}">Team
                        Guidelines</a>.
                </small>
            </p>
        </form>
    </ng-container>

    <div class="info">
        <p>Do you have an account? Please <a routerLink="/login" [ngStyle]="{'color': colorCode}">Sign In</a> instead.
        </p>
    </div>
</div>