<div class="modal-header">
    <h4 class="modal-title">Message share link</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div *ngIf="thumbUrl" class="share-thumb">
        <div class="share-loader">
            <i class="fas fa-spinner fa-spin"></i>
        </div>
        <img [src]="thumbUrl" (error)="thumbUrl=null">
    </div>
    <div class="form-group">
        <div class="input-group">
            <input #messageUrl type="text" class="form-control bg-dark " (keydown)="preventChange()"
                (click)="copyLink()" [disabled]="!!(error||errorMessage)"
                [ngClass]="{'disabled': !!(error||errorMessage)}">
            <div *ngIf="loading" class="text-white loader">
                <i class="fas fa-spinner fa-spin"></i>
            </div>
            <div class="input-group-append">
                <button class="btn btn-primary copy" type="button" (click)="copyLink()"
                    [disabled]="!!(error||errorMessage)" [ngClass]="{'disabled': !!(error||errorMessage)}">Copy Link
                </button>
            </div>
            <div class="borderfx"></div>
        </div>
    </div>
    <div style="font-size: 13px; color: #989898;font-family: 'Poppins';">Privacy notice: Public chatroom messages can be
        shared by any community member whereas private and premium chatroom messages can only be shared by their author or
        admins.</div>
</div>
<div class="modal-body text-center text-danger p-2" *ngIf="error">
    You are not allowed to share this message
</div>
<div class="modal-body text-center text-danger p-2" *ngIf="errorMessage">
    {{errorMessage}}
</div>
<div class="modal-footer text-center">
    <div>
        <span><small>Share to: </small></span>
        <a [href]="(error||errorMessage)?deactiveLink:'https://www.facebook.com/sharer/sharer.php?u='+messageUrl.value"
            class="share-social-btn" [target]="error||errorMessage?'_self':'_blank'" (click)="track('facebook')"
            [ngClass]="{'not-allowed': error||errorMessage, 'loading': loading}">
            <i class="fab fa-facebook-square"></i> Facebook
        </a>
        <a [href]="(error||errorMessage)?deactiveLink:'http://twitter.com/share?text=Join '+teamlabel+' on Ensemble&url='+messageUrl.value+'&hashtags=ensemblechat'"
            class="share-social-btn" [target]="error||errorMessage?'_self':'_blank'" (click)="track('twitter')"
            [ngClass]="{'not-allowed': error||errorMessage, 'loading': loading}">
            <i class="fab fa-twitter-square"></i> Twitter
        </a>
        <a [href]="(error||errorMessage)?deactiveLink:'https://telegram.me/share/url?url='+messageUrl.value+'&text=Join '+teamlabel+' on Ensemble'"
            class="share-social-btn" [target]="error||errorMessage?'_self':'_blank'" (click)="track('telegram')"
            [ngClass]="{'not-allowed': error||errorMessage, 'loading': loading}">
            <i class="fab fa-telegram"></i> Telegram
        </a>
    </div>
</div>