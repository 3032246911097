<!--GENERIC FAIL ALL STEPS-->
<div class="text-center p-4" *ngIf="!loading && status==='fail-generic'">
    <div class="text-danger mb-4">
        <div class="mb-2">
            <i class="fal fa-sad-tear fa-4x"></i>
        </div>
        <h4>Oh no!</h4>
    </div>
    <p>Something went wrong</p>
    <p><a href="javascript:" (click)="step='request-pin';status='start'">try again</a>.</p>
</div>

<ng-container *ngIf="step==='request-pin'">
    <!--EMAIL TAKEN - REQUEST PIN STEP-->
    <div class="text-center p-4" *ngIf="!loading && status==='EMAIL_ALREADY_EXIST'">
        <div class="text-danger mb-4">
            <div class="mb-2">
                <i class="fal fa-sad-tear fa-4x"></i>
            </div>
            <h4>Email is taken</h4>
        </div>
        <p><a href="javascript:" (click)="status='start'" [ngStyle]="{'color': colorCode}">Sign up</a> with a different
            email address or <a [ngStyle]="{'color': colorCode}" routerLink="/login">Sign In</a>.</p>
        <p><a routerLink="/reset-password" [ngStyle]="{'color': colorCode}">Forgot Password</a>?</p>
    </div>
    <!--INVITATION INVALID EMAIL-->
    <div class="text-center p-4" *ngIf="!loading && status==='INVALID_EMAIL'">
        <div class="text-danger mb-4">
            <div class="mb-2">
                <i class="fal fa-sad-tear fa-4x"></i>
            </div>
            <h4>Sorry. This invitation is not associated with the email address your have entered.</h4>
        </div>
        <p><a href="javascript:" (click)="resetComponent()" [ngStyle]="{'color': colorCode}">Sign up</a> with a
            different email address or <a [ngStyle]="{'color': colorCode}" routerLink="/login">Sign In</a>.</p>
        <p><a routerLink="/reset-password" [ngStyle]="{'color': colorCode}">Forgot Password</a>?</p>
    </div>
</ng-container>

<div *ngIf="step==='request-pin' && (status==='start'||status==='validation-error')">
    <div class="header">
        <h1 *ngIf="!invitationName">Sign Up</h1>
        <h1 *ngIf="invitationName">You have been invited!</h1>
        <p *ngIf="teamName">
            Join <span [ngStyle]="{'color': colorCode}" class="one-line-ellipsis team-name">{{ teamName }}</span>
            <ng-container *ngIf="!isEchofin"> community</ng-container>
        </p>
    </div>
    <ng-container
        *ngIf="team && team.config && team.config.join && team.config.join.state && team.config.join.state === 'NONE' && !verifiedInvitation && !invitationName">
        <p class="text-center">This is a private community.<br />You need to have an invitation link to join.</p>
    </ng-container>
    <ng-container
        *ngIf="(team && team.config && team.config.join && team.config.join.state && team.config.join.state !== 'NONE') || !team || !team.config || !team.config.join || !team.config.join.state || (verifiedInvitation && invitationName)">
        <p class="text-center"
            *ngIf="team && team.config && team.config.join && team.config.join.state && team.config.join.state === 'CUSTOM'">
            This community limits members to specific email addresses.
            Contact the community owner if you experience any problem.
        </p>
        <form (ngSubmit)="requestEmail()" #regForm="ngForm">
            <div class="custom-form-group">
                <input class="custom-form-control" [ngStyle]="{'border-color': colorCode}" [formControl]="email"
                    type="email" placeholder="Email">
            </div>
            <p *ngIf="email.dirty && email.touched && email.invalid && email.errors.email" class="text-danger">
                <small>The email is not a valid email address.</small>
            </p>
            <div>
                <button type="submit" [ngStyle]="{'background-color': colorCode, color:contrastColor}"
                    [disabled]="loading">
                    <i *ngIf="loading" class="fas fa-sync-alt fa-fw fa-spin"></i>
                    <span class="ml-2">NEXT</span>
                </button>
            </div>
            <p class="text-center mt-3" style="filter:brightness(.8)">
                <small>
                    By Signing Up, you agree to Ensemble's <br>
                    <a href="https://www.ensemble.chat/terms/" target="_blank" [ngStyle]="{'color': colorCode}">Terms &
                        Conditions</a>,
                    <a href="https://www.ensemble.chat/privacy/" target="_blank" [ngStyle]="{'color': colorCode}">Privacy
                        & Cookies Policy</a> and
                    <a href="https://www.ensemble.chat/guidelines/" target="_blank" [ngStyle]="{'color': colorCode}">Team
                        Guidelines</a>.
                </small>
            </p>
        </form>
    </ng-container>

    <div class="info">
        <p>Do you have an account? Please <a routerLink="/login" queryParamsHandling="preserve"
                [ngStyle]="{'color': colorCode}">Sign In </a><span *ngIf="invitationName">to claim you invitation
            </span>instead.
        </p>
    </div>
</div>






<div *ngIf="step==='verify-pin'">
    <div class="header">
        <h1 *ngIf="!resetLegacyMode">Create your account</h1>
        <h1 *ngIf="resetLegacyMode">Reset password</h1>
        <p *ngIf="teamName && teamName!=='app' && !resetLegacyMode">
            Join <span [ngStyle]="{'color': colorCode}" class="one-line-ellipsis team-name">{{ teamName }}</span>
            <ng-container *ngIf="!isEchofin"> team</ng-container>
        </p>
        <p *ngIf="resetLegacyMode">Fill in your new password below and click "Change"</p>
    </div>
    <p *ngIf="!resetLegacyMode">
        A 6-digit code was sent to <span style="font-weight: bold;">{{email.value}}</span>. Enter it within 5 minutes.
    </p>
    <form name="form" #regForm="ngForm" (ngSubmit)="verifyPin(regForm)">
        <div class="custom-form-group text-center mb-3">

            <div>
                <input #pin id="pin-0" ngbAutofocus autocomplete="off" class="text-center pin-digit"
                    name="pin0" [(ngModel)]="pin0" maxlength="1" type="tel" [disabled]="loading"
                    (keyup)="handlePinKeyUp($event, 0)" (keydown)="handlePinKeyDown($event, 0)"
                    (focus)="focusPin($event)" (blur)="focusedPin=null;" (paste)="inputPaste($event)"
                    [ngStyle]="{'border-color': focusedPin==='pin-0' ? colorCode:''}">
                <input #pin id="pin-1" autocomplete="off" class="text-center pin-digit" name="pin1"
                    [(ngModel)]="pin1" maxlength="1" type="tel" [disabled]="loading" (keyup)="handlePinKeyUp($event, 1)"
                    (keydown)="handlePinKeyDown($event, 1)" (focus)="focusPin($event)" (blur)="focusedPin=null;"
                    [ngStyle]="{'border-color': focusedPin==='pin-1' ? colorCode:null}">
                <input #pin id="pin-2" autocomplete="off" class="text-center pin-digit" name="pin2"
                    [(ngModel)]="pin2" maxlength="1" type="tel" [disabled]="loading" (keyup)="handlePinKeyUp($event, 2)"
                    (keydown)="handlePinKeyDown($event, 2)" (focus)="focusPin($event)" (blur)="focusedPin=null;"
                    [ngStyle]="{'border-color': focusedPin==='pin-2' ? colorCode:null}">
                <input #pin id="pin-3" autocomplete="off" class="text-center pin-digit" name="pin3"
                    [(ngModel)]="pin3" maxlength="1" type="tel" [disabled]="loading" (keyup)="handlePinKeyUp($event, 3)"
                    (keydown)="handlePinKeyDown($event, 3)" (focus)="focusPin($event)" (blur)="focusedPin=null;"
                    [ngStyle]="{'border-color': focusedPin==='pin-3' ? colorCode:null}">
                <input #pin id="pin-4" autocomplete="off" class="text-center pin-digit" name="pin4"
                    [(ngModel)]="pin4" maxlength="1" type="tel" [disabled]="loading" (keyup)="handlePinKeyUp($event, 4)"
                    (keydown)="handlePinKeyDown($event, 4)" (focus)="focusPin($event)" (blur)="focusedPin=null;"
                    [ngStyle]="{'border-color': focusedPin==='pin-4' ? colorCode:null}">
                <input #pin id="pin-5" autocomplete="off" class="text-center pin-digit" name="pin5"
                    [(ngModel)]="pin5" maxlength="1" type="tel" [disabled]="loading" (keyup)="handlePinKeyUp($event, 5)"
                    (keydown)="handlePinKeyDown($event, 5)" (focus)="focusPin($event)" (blur)="focusedPin=null;"
                    [ngStyle]="{'border-color': focusedPin==='pin-5' ? colorCode:''}">
            </div>

            <div class="mt-1">
                <small class="text-secondary">Verification Code</small>
            </div>
            <div class="mt-1" [ngStyle]="{'visibility':status==='INVALID_PIN'?'visible':'hidden'}">
                <small class="text-danger">The Verification Code is invalid</small>
            </div>
        </div>

        <button type="submit" [ngStyle]="{'background-color': colorCode, color:contrastColor}" [disabled]="loading">
            <i *ngIf="loading" class="fas fa-sync-alt fa-fw fa-spin"></i>
            <span class="ml-2">NEXT</span>
        </button>

    </form>
    <div class="info">
        <p>Do you have an account? Please <a routerLink="/login" [ngStyle]="{'color': colorCode}">Sign In</a> instead.
        </p>
        <p> <a class="new-vc" [ngStyle]="{'color': colorCode}" href="javascript:"
                (click)="step='request-pin';status='start'">Request a
                new Verification Code</a></p>
    </div>
</div>




<ng-container *ngIf="step==='register'">
    <!--SUCCESS - REGISTER STEP-->
    <div class="text-center p-4" *ngIf="status==='success'">
        <div class="text-success mb-4">
            <div class="mb-2">
                <i class="fal fa-check-circle fa-4x"></i>
            </div>
            <h4>Registered!</h4>
        </div>
        <p>Logging you in now...</p>
    </div>

    <div class="text-center p-4" *ngIf="!loading && status==='USERNAME_ALREADY_EXIST'">
        <!--USERNAME EXISTS - REGISTER STEP-->
        <div class="text-danger mb-4">
            <div class="mb-2">
                <i class="fal fa-sad-tear fa-4x"></i>
            </div>
            <h4>Username is taken</h4>
        </div>
        <p>Please <a [ngStyle]="{'color': colorCode}" href="javascript:" (click)="status='register'">try again</a> with
            a different username.</p>
        <p>If you forgot your account credentials you may reset them <a [ngStyle]="{'color': colorCode}"
                routerLink="/reset-password">here</a>.</p>
    </div>

    <div class="text-center p-4" *ngIf="!loading && status==='INVALID_PIN'">
        <div class="text-danger mb-4">
            <div class="mb-2">
                <i class="fal fa-sad-tear fa-4x"></i>
            </div>
            <h4>The Verification Code is invalid.</h4>
        </div>
        <p>If you forgot your account credentials you may reset them<br /><a [ngStyle]="{'color': colorCode}"
                routerLink="/reset-password">here</a>.</p>
        <p>If you mistyped your email<br /> <a [ngStyle]="{'color': colorCode}" routerLink="/signup">try again</a>.</p>
    </div>
</ng-container>

<div *ngIf="step==='register' && status==='register'">
    <div class="header">
        <h1 *ngIf="!resetLegacyMode">Create your account</h1>
        <h1 *ngIf="resetLegacyMode">Reset password</h1>
        <p *ngIf="teamName && teamName!=='app' && !resetLegacyMode">
            Join <span [ngStyle]="{'color': colorCode}" class="one-line-ellipsis team-name">{{ teamName }}</span>
            <ng-container *ngIf="!isEchofin"> team</ng-container>
        </p>
        <p *ngIf="resetLegacyMode">The Verification Code is correct!<br>Fill in your new password below and click
            "Change"</p>
    </div>
    <form name="form" (ngSubmit)="regForm.valid && register(regForm)" #regForm="ngForm">
        <div *ngIf="!resetLegacyMode" class="custom-form-group">
            <input class="custom-form-control" [ngStyle]="{'border-color': colorCode}" name="username"
                #usernameControl="ngModel" [(ngModel)]="username" placeholder="Username"
                pattern="^[a-zA-Z0-9][a-zA-Z0-9_.-]*$" [disabled]="loading" minlength="2" maxlength="18"
                [ngClass]="{ 'is-invalid': regForm.submitted && usernameControl.invalid }">
            <div *ngIf="regForm.submitted && usernameControl.invalid" class="invalid-feedback">
                <small *ngIf="usernameControl.errors.minlength">Username must be at least 2 characters long<br></small>
                <small *ngIf="usernameControl.errors.maxlength">Username must be up to 18 characters long<br></small>
                <small *ngIf="usernameControl.errors.pattern">Usernames include lower or upper case characters, numbers,
                    dashes, dots and underscores and must start with a lower or upper case character or number</small>
            </div>
        </div>
        <div class="custom-form-group">
            <input class="custom-form-control" [ngStyle]="{'border-color': colorCode}" name="password"
                #passwordControl="ngModel" [(ngModel)]="password" type="password"
                [placeholder]="resetLegacyMode?'New Password':'Password'" pattern="^\S*$" [disabled]="loading"
                minlength="6" [ngClass]="{ 'is-invalid': regForm.submitted && passwordControl.invalid }">
            <div *ngIf="regForm.submitted && passwordControl.invalid" class="invalid-feedback">
                <small *ngIf="passwordControl.errors.minlength">Password must be at least 6 characters long<br></small>
                <small *ngIf="passwordControl.errors.pattern">No white space allowed in password</small>
            </div>
        </div>
        <div *ngIf="resetLegacyMode" class="custom-form-group">
            <input class="custom-form-control" [ngStyle]="{'border-color': colorCode}"
                [ngModelOptions]="{standalone: true}" [(ngModel)]="confirmPassword" type="password"
                placeholder="Confirm new password" [disabled]="loading">
        </div>

        <button type="submit" class="btn" [ngStyle]="{'background-color': colorCode, color:contrastColor}"
            [disabled]="loading">
            <i *ngIf="loading" class="fas fa-sync-alt fa-fw fa-spin"></i>
            <span *ngIf="!resetLegacyMode" class="ml-2">SIGN UP</span>
            <span *ngIf="resetLegacyMode" class="ml-2">CHANGE</span>
        </button>

    </form>
    <ng-container *ngIf="!resetLegacyMode">
        <div class="info">
            <p>Do you have an account? Please <a routerLink="/login" [ngStyle]="{'color': colorCode}">Sign In</a>
                instead.</p>
        </div>
    </ng-container>
</div>