<div class="title">
    <i class="fas fa-sticky-note"></i> Story
</div>
<div [innerHTML]="parsedHtml" class="content"></div>
<div *ngIf="urlData" class="mt-2 url-preview" [title]="urlData.title">
    <app-lightbox *ngIf="urlData.image" [src]="urlData.image" [thumb]="urlData.image" [width]="urlData.width"
        [height]="urlData.height" [filename]="urlData.title" [fullView]="fullView && fullViewType==='ma-url'"
        [messageId]="messageId" [type]="'ma-url'">
    </app-lightbox>
</div>
<div *ngIf="files && files.length">
    <div *ngIf="messageStatus === status.SEND" class="text-center p-4 text-secondary">
        <i class="fas fa-cog fa-3x fa-fw fa-spin"></i>
        <br>
        <small>Uploading...</small>
    </div>
    <app-file-attachment *ngIf="messageStatus === status.DELIVERED" [attachments]="files" [hideFilename]="true"
        [fullView]="fullView && fullViewType==='file'" [messageId]="messageId">
    </app-file-attachment>
</div>