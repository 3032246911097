<p class="h6">Billing</p>

<ng-container *ngIf="connected; else loader">
  <ng-container *ngIf="connected.length; else empty">
    <table class="table table  table-striped table-light">
      <ng-container *ngFor="let account of connected">
        <tr>
          <td><span class="text-dark">{{teamNames[account.teamId]}}</span></td>
          <td class="text-right">
            <a *ngIf="!teamErrors[account.teamId]" href="javascript:void" class="btn btn-sm btn-primary"
              (click)="navigateToPortal(account.teamId)">Manage</a>
            <span *ngIf="teamErrors[account.teamId]" class="text-danger">This team has not configure stripe portal
              correctly</span>
          </td>
        </tr>
      </ng-container>
    </table>
  </ng-container>
</ng-container>



<ng-template #loader>loader</ng-template>
<ng-template #empty>empty</ng-template>