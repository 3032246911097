import { Injectable, NgZone } from '@angular/core';
import { Events, ProfileMeServiceApi, ProfileProfilesServiceApi, TeamRoomsServiceApi } from '@echofin/libraries';
import { Subject } from 'rxjs';
import { Me } from '../../_shared/models/commons/me';
import { User } from '../../_shared/models/commons/user';
import { UserStatus, LocalUserStatusChanged } from '../../_shared/models/enums/user-status';
import { DirectRoom } from '../../_shared/models/room/room';
import { BaseService } from './base.service';
import { DateService } from './date.service';
import { FileUploadService } from './file-upload.service';
import { EvType } from './socket.service/models/all.models';
import { SocketStatus } from './socket.service/models/socket-status';
import { SocketService } from './socket.service/socket.service';
import { UserExtResp } from '@echofin/libraries/api/profile/models/user-ext-resp';
import { LocalStorage } from '@efaps/ngx-store';
import * as Sentry from '@sentry/angular';

export interface LocalUsernameChanged extends Omit<Events.UsernameChanged, 'teamIds'> {
  teamId: string;
}
export interface LocalAvatarUpdated extends Omit<Events.AvatarUpdated, 'userId' | 'avatar'> {
  profileId: string;
  avatarUrl: string;
}

@Injectable({ providedIn: 'root' })
export class ProfileService extends BaseService {

  me: Me;
  me$: Subject<Me> = new Subject<Me>();

  users: User[] = [];
  avatarChanged$: Subject<Events.AvatarUpdated> = new Subject<Events.AvatarUpdated>();
  userStatusChanged$: Subject<LocalUserStatusChanged> = new Subject<LocalUserStatusChanged>();
  usernameChanged$: Subject<LocalUsernameChanged> = new Subject<LocalUsernameChanged>();
  muteAdded$: Subject<string> = new Subject<string>();
  muteRemoved$: Subject<string> = new Subject<string>();

  unfinishedRequests: { [key: string]: Promise<User> } = {};
  loaded: boolean;

  @LocalStorage() whatsNew: { lastSeen: number, firstLoad: boolean, save?: any } = { lastSeen: null, firstLoad: true };
  @LocalStorage() widgetsDisclaimer: { acknowledged: boolean, save?: any } = { acknowledged: false };
  @LocalStorage() walkthrough: { acknowledged: boolean, save?: any } = { acknowledged: false };
  @LocalStorage() workspace: { theme: string, save?: any } = { theme: 'light' };


  constructor(
    private ngZone: NgZone,
    private profileApi: ProfileProfilesServiceApi,
    private profileMeApi: ProfileMeServiceApi,
    private roomApi: TeamRoomsServiceApi,
    private dateService: DateService,
    private socketService: SocketService,
    private fileUploadService: FileUploadService
  ) {
    super();
  }

  setup() {
    this.me$.subscribe((me) => {
      // this.log('Profile (me$) loaded', me);
      if (!me) {
        return;
      }
      this.fileUploadService.setup();

      this.me = me;
      this.dateService.setTimeFormat(me.is24h);

      this.me.currentStatus = this.me.userStatus;
    });

    this.socketService
      .getStream(EvType.UserStatusChanged)
      .subscribe((data: LocalUserStatusChanged) => {
        this.ngZone.run(() => {
          if (data.userId === this.me.id) {
            this.me.currentStatus = data.currentStatus as 'AVAILABLE' | 'BUSY' | 'AWAY' | 'OFFLINE';
          }
          this.userStatusChanged$.next(data);
        });
      });

    this.socketService
      .getStream(EvType.AvatarUpdated)
      .subscribe(async (data: LocalAvatarUpdated) => {
        if (this.me.id === data.profileId) {
          this.me.avatar = data.avatarUrl;
        }
        const user = await this.getUser(data.profileId);
        if (user) {
          user.avatar = data.avatarUrl;
        }
        this.avatarChanged$.next({ ...data, userId: data.profileId, avatar: data.avatarUrl } as Events.AvatarUpdated);
      });

    this.socketService
      .getStream(EvType.UsernameChanged)
      .subscribe(async (data: LocalUsernameChanged) => {
        if (this.me.id === data.userId) {
          this.me.username = data.username;
        }
        this.usernameChanged$.next(data);
      });

    this.socketService
      .status$
      .subscribe((status: SocketStatus) => {
        if (status === SocketStatus.Disconnected) {
          this.me.currentStatus = 'OFFLINE';
        }
        if (status === SocketStatus.Connected) {
          if (!this.loaded) {
            this.loaded = true;
            return;
          }
          // tot should reload
          // this.loadMe()
        }
      });

    this.socketService
      .getStream(EvType.UserMuteChanged)
      .subscribe(async (data: Events.UserMuteChanged) => {
        if (data.enabled) {
          if (!this.me.mutedUsers.includes(data.mutedUserId)) {
            this.me.mutedUsers.push(data.mutedUserId);
          }
          this.muteAdded$.next(data.mutedUserId);
        } else {
          if (this.me.mutedUsers.includes(data.mutedUserId)) {
            this.me.mutedUsers = this.me.mutedUsers.filter(m => m !== data.mutedUserId);
          }
          this.muteRemoved$.next(data.mutedUserId);
        }
      });
  }

  async loadMe() {
    this.me = await this.profileMeApi.GetMeProfile().toPromise();
    // LogRocket.identify(this.me.id, { name: this.me.username });
    this.me$.next(this.me);
    return this.me.id;
  }

  async setUserStatus(userId: string, status: UserStatus) {
    await this
      .profileApi
      .SetUserStatus({
        profileId: userId,
        request: {
          isIdle: false,
          status: status as 'AVAILABLE' || 'BUSY'
        }
      })
      .toPromise()
      .catch((err) => {
        return Promise.reject('Could not change status');
      });
  }

  async acceptDisclaimer(teamId: string) {
    const prefs = { ...this.me.teamPreferences.find(x => x.teamId === teamId) };
    prefs.disclaimerAccepted = true;
    // TODO: save user preferences
  }

  leaveGroup(roomId: any): Promise<any> {
    return this.roomApi
      .RemoveParticipant({
        roomId,
        userId: this.me.id,
      })
      .toPromise();
  }

  updateDirect(room: DirectRoom) {
    return this.roomApi
      .UpdateChatroom({
        roomId: room.id,
        model: room
      })
      .toPromise();
  }

  addUsers(users: User[]) {
    users.forEach((user) => {
      const ui = this.users.findIndex(u => u.id === user.id);
      if (ui !== -1) {
        this.users[ui] = user;
      } else {
        this.users.push(user);
      }
    });
  }

  async getUser(userId: string): Promise<User> {
    const user = this.users.find(u => u.id === userId);
    if (user) {
      return user;
    }
    const newUser = await this.profileApi.GetProfile(userId).toPromise();
    if (!newUser) {
      return null;
    }
    this.users.push(newUser);
    return newUser;
  }

  async getUsers(userIds: string[]): Promise<UserExtResp[]> {
    return await this.profileApi.GetProfiles({
      ids: userIds
    }).toPromise();
  }

  setupSentry() {
    Sentry.configureScope((scope) => {
      scope.setUser({
        id: this.me.id,
        username: this.me.username,
        email: this.me.email
      });
    });
  }

  hideDirect(roomId: string): Promise<any> {
    return this.roomApi
      .HideFromMenu(roomId)
      .toPromise();
  }

  async toggleMute(userId) {
    return this.profileApi.ToggleMute({ userId }).toPromise();
  }
}
