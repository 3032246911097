import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BrandingService } from '../../_core/services/branding.service';
import { LoginService } from '../_commons/login.service';

@Component({
  selector: 'app-intro-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loading: boolean;
  error: string;
  identity: string;
  password: string;
  isLogged = false;
  colorCode: string;
  contrastColor: string;
  invCode: string;

  get isEchofin() {
    return this.brandingService.team.name === 'app';
  }

  get teamName() {
    if (this.isEchofin) {
      return 'Ensemble';
    }
    if (this.brandingService.team.label) {
      return this.brandingService.team.label;
    }
    return this.brandingService.team.name;
  }

  constructor(
    private route: ActivatedRoute,
    private brandingService: BrandingService,
    private loginService: LoginService
  ) {
  }

  ngOnInit() {
    this.colorCode = this.getColorCode();
    this.contrastColor = this.getContrastYIQ(this.colorCode);

    this.invCode = this.route.snapshot.queryParams['inv'];
  }

  async login() {
    if (typeof (this.identity) === 'undefined' || typeof (this.password) === 'undefined' || this.identity === '' || this.password === '') {
      this.error = 'Please Enter Valid Username and Password';
      return;
    }
    this.loading = true;
    this.error = null;

    this.invCode = this.route.snapshot.queryParams['inv'];

    const ret = await this.loginService.login(this.identity, this.password, this.brandingService.team.name, this.invCode);

    if (ret && ret.error) {
      this.error = ret.error;
    }
    this.loading = false;
  }

  async logout() {
    await this.loginService.logout();
    this.identity = null;
    this.isLogged = false;

    this.invCode = null;
  }

  getColorCode() {
    if (this.brandingService.team &&
      this.brandingService.team.config &&
      this.brandingService.team.config.branding &&
      this.brandingService.team.config.branding.colorCode) {
      return this.brandingService.team.config.branding.colorCode;
    }
    return '#f99500';
  }

  getContrastYIQ(hexcolor) {
    const hc = hexcolor.replace('#', '');
    const r = parseInt(hc.substr(0, 2), 16);
    const g = parseInt(hc.substr(2, 2), 16);
    const b = parseInt(hc.substr(4, 2), 16);
    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 136) ? 'black' : 'white';
  }
}
