import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ProfileRegisterServiceApi } from '@echofin/libraries';
import { BrandingService } from '../../_core/services/branding.service';
import { LoginService } from '../_commons/login.service';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-intro-register',
  templateUrl: './register.component.html',
})
export class RegisterComponent implements OnInit {
  loading: boolean;
  username: string;
  password: string;
  status: string = 'register';
  err: string;

  token: string;

  resetLegacyMode = false;
  tokenTeamName: string;
  inviteCode: string;
  confirmPassword: string;

  get isEchofin() {
    return this.brandingService.team.name === 'app';
  }

  get teamName() {
    if (this.isEchofin) {
      return 'Ensemble';
    }
    if (this.brandingService.team.label) {
      return this.brandingService.team.label;
    }
    return this.brandingService.team.name;
  }

  get teamLabel() {
    return this.brandingService.team.label;
  }

  colorCode: string;
  contrastColor: string;

  constructor(
    private route: ActivatedRoute,
    private brandingService: BrandingService,
    private profileServiceApi: ProfileRegisterServiceApi,
    private loginService: LoginService
  ) { }

  ngOnInit() {

    this.colorCode = this.getColorCode();
    this.contrastColor = this.getContrastYIQ(this.colorCode);
    this.loading = true;

    if (!environment.production) {
      const rand = Math.floor(Math.random() * 10000) + 1000;
      this.username = `user.${rand}`;
      this.password = `user.${rand}`;
    }
    this.route.queryParams.subscribe((params: Params) => {
      this.token = params.t;

      this.profileServiceApi.VerifyToken(
        {
          token: this.token
        }).toPromise()
        .then(res => {
          if (res.username) {
            this.username = res.username;
            this.tokenTeamName = res.joinTeam;
            this.inviteCode = res.inviteCode;
            this.resetLegacyMode = true;
          }
          this.loading = false;
        })
        .catch((resp) => {
          if (!resp.error) {
            this.status = 'fail-generic';
          }
          switch (resp.error) {
            case 'LINK_ALREADY_USED':
              this.status = 'LINK_ALREADY_USED';
              break;
            case 'INVALID_TOKEN':
              this.status = 'INVALID_TOKEN';
              break;
            case 'RESERVED_USERNAME':
              this.status = 'RESERVED_USERNAME';
              break;
          }
          this.loading = false;
        });
    });
  }

  async register(form: any) {
    console.log(form);
    this.loading = true;

    this.profileServiceApi.VerifyAndRegister(
      {
        token: this.token,
        username: this.username,
        password: this.password,
        joinTeam: this.tokenTeamName ? this.tokenTeamName : this.brandingService.team.name,
        bypassLegacyUserCheck: this.resetLegacyMode
      }).toPromise()
      .then(async (res) => {
        this.status = 'success';

        await this.loginService.login(this.username, this.password, this.brandingService.team.name, res.invitationState ? this.inviteCode : null);
      })
      .catch((resp) => {
        if (!resp.error) {
          this.status = 'fail-generic';
        }
        switch (resp.error) {
          case 'USERNAME_ALREADY_EXIST': {
            this.status = 'USERNAME_ALREADY_EXIST';
            break;
          }
          case 'LINK_ALREADY_USED': {
            this.status = 'LINK_ALREADY_USED';
            break;
          }
          case 'INVALID_TOKEN': {
            this.status = 'INVALID_TOKEN';
            break;
          }
          case 'RESERVED_USERNAME': {
            this.status = 'RESERVED_USERNAME';
            break;
          }
          default: {
            this.status = 'fail-generic';
            break;
          }
        }
        this.err = resp.message;
        this.loading = false;
      });
  }

  getColorCode() {
    if (this.brandingService.team &&
      this.brandingService.team.config &&
      this.brandingService.team.config.branding &&
      this.brandingService.team.config.branding.colorCode) {
      return this.brandingService.team.config.branding.colorCode;
    }
    return '#f99500';
  }

  getContrastYIQ(hexcolor) {
    const hc = hexcolor.replace('#', '');
    const r = parseInt(hc.substr(0, 2), 16);
    const g = parseInt(hc.substr(2, 2), 16);
    const b = parseInt(hc.substr(4, 2), 16);
    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 136) ? 'black' : 'white';
  }
}
