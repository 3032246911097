import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Events } from '@echofin/libraries';
import { debounceTime, skip, tap } from 'rxjs/operators';
import { LocalUsernameChanged, ProfileService } from '../../_core/services/profile.service';
import { SocketStatus } from '../../_core/services/socket.service/models/socket-status';
import { SocketService } from '../../_core/services/socket.service/socket.service';
import { TeamService } from '../../_core/services/team.service';
import { DEFAULTS } from '../../_shared/models/defaults';
import { CurrentStatus } from '../../_shared/models/enums/current-status';
import { LocalUserStatusChanged, UserStatus } from '../../_shared/models/enums/user-status';
import { Member } from '../../_shared/models/teams/member';
import { Role } from '../../_shared/models/teams/role';
import { BaseComponent } from '../base-component';

export const PAGE_SIZE = 100;

@Component({
  selector: 'app-member-directory',
  templateUrl: './member-directory.component.html',
  styleUrls: ['./member-directory.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MemberDirectoryComponent extends BaseComponent implements OnInit {

  @ViewChild('membersList') membersList: ElementRef;

  members: Member[] = [];
  roles: Role[] = [];

  searchField = new FormControl();
  term: string;

  missingPageRecordCount = 0;

  canBan = false;

  CurrentStatus = CurrentStatus;
  userStatuses = UserStatus;
  defaults = DEFAULTS;

  defaultRole: Role = {
    id: null, // changed from _members
    name: 'Recently Online',
    color: '#5071ab',
  };
  bannedRole: Role = {
    id: '_banned',
    name: 'Banned',
    color: '#b54242',
  };

  roleToggle: { [key: string]: boolean } = {};
  membersCounter: { [key: string]: number } = {};
  teamId: string;

  haveMoreMembers: boolean;

  searching = false;

  constructor(
    public teamService: TeamService,
    private socketService: SocketService,
    private profileService: ProfileService,
    private ch: ChangeDetectorRef
  ) {
    super();
  }

  async ngOnInit() {

    this.loadRoles();
    await this.searchMembers(0, PAGE_SIZE);

    // console.log(this.members);

    // // make sure the user's status is the correct one (possible race condition fix)
    // const myself = this.members.find(c => c.userId === this.profileService.me.id);
    // if (myself && myself.user.status !== this.profileService.me.status) {
    //   // console.log('FIXED STATUS!');
    //   myself.user.status = this.profileService.me.status;
    // }

    // console.log(this.members);

    this.subscribe(this.socketService.status$.pipe(skip(1)), async (status: SocketStatus) => {
      if (status === SocketStatus.Connected) {
        await this.searchMembers(0, PAGE_SIZE, true);
      }
    });

    if (this.teamService.activeTeam) {
      this.teamId = this.teamService.activeTeam.id;
      if (!this.teamService.activeTeam.member.isBanned) {
        this.canBan = this.teamService.activeTeam.permissions?.ban_users ?? false;
      }
    }

    this.subscribe(
      this.searchField.valueChanges.pipe(
        tap(term => {
          this.searching = true;
          this.term = term;
        }),
        // filter(term => !term || term.length === 0 || term.length),
        debounceTime(1000)),
      async (term) => {
        await this.searchMembers(0, PAGE_SIZE, true);
      });

    this.subscribe(
      this.teamService.activeTeamId$,
      async (teamId: string) => {
        this.loadRoles();
        await this.searchMembers(0, PAGE_SIZE, true);
      });

    this.subscribe(
      this.teamService.memberRoleChanged$,
      async (data: Events.MemberRoleChanged) => {
        if (!data || data.teamId !== this.teamService.activeTeamId) return;

        const startLength = this.members.length;
        // remove user
        let member = this.removeMember(data.userId, false);
        if (member) {
          // if found mutate
          member.roleId = data.roleId;
        } else {
          // if not found fetch
          member = await this.teamService.getMemberRequest(data.userId);
        }
        // insert user again
        this.insertMember(member);

        const endLength = this.members.length;
        this.handleLastPage(startLength, endLength);

        this.calcCounters();
        this.ch.markForCheck();
      });

    this.subscribe(
      this.teamService.memberJoined$,
      async (data: Events.MemberJoined) => {
        if (!data || data.teamId !== this.teamService.activeTeamId) return;

        const startLength = this.members.length;
        // fetch new member and insert
        const member = await this.teamService.getMemberRequest(data.userId);
        this.insertMember(member);

        const endLength = this.members.length;
        this.handleLastPage(startLength, endLength);

        this.calcCounters();
        this.ch.markForCheck();
      });

    this.subscribe(
      this.teamService.memberLeft$,
      async (data: Events.MemberLeft) => {
        if (!data || data.teamId !== this.teamService.activeTeamId) return;

        const startLength = this.members.length;
        // remove user
        this.removeMember(data.userId, true);

        const endLength = this.members.length;
        this.handleLastPage(startLength, endLength);

        this.calcCounters();
        this.ch.markForCheck();
      });

    this.subscribe(
      this.teamService.teamMemberBanned$,
      async (data: Events.MemberBanned) => {
        if (!data || data.teamId !== this.teamService.activeTeamId) return;

        const startLength = this.members.length;
        // remove user
        this.removeMember(data.userId, true);

        const endLength = this.members.length;
        this.handleLastPage(startLength, endLength);

        this.calcCounters();
        this.ch.markForCheck();
        // console.log('done teamMemberBanned', this.roles, this.members);
      });

    this.subscribe(
      this.teamService.teamMemberUnbanned$,
      async (data: Events.MemberUnbanned) => {
        if (!data || data.teamId !== this.teamService.activeTeamId) return;

        const startLength = this.members.length;
        // fetch new member and insert
        const member = await this.teamService.getMemberRequest(data.userId);
        this.insertMember(member);

        const endLength = this.members.length;
        this.handleLastPage(startLength, endLength);

        this.calcCounters();
        this.ch.markForCheck();
        // console.log('done teamMemberUnbanned', this.roles, this.members);
      });

    this.subscribe(
      this.teamService.roleChanged$,
      async () => {
        this.loadRoles();
        await this.searchMembers(0, PAGE_SIZE, true);
        this.calcCounters();
        this.ch.markForCheck();
      });

    this.subscribe(
      this.teamService.rolesReordered$,
      async () => {
        this.loadRoles();
        await this.searchMembers(0, PAGE_SIZE, true);
        this.calcCounters();
        this.ch.markForCheck();
      });

    this.subscribe(
      this.profileService.userStatusChanged$,
      async (data: LocalUserStatusChanged) => {
        if (!data || data.teamId !== this.teamService.activeTeamId) return;

        const startLength = this.members.length;
        let member = this.members.find(m => m.user.id === data.userId);
        // if we show all offline (search results only at the moment) and new status is OFFLINE
        // or user has any role
        // or new status IS NOT OFFLINE and user is found in list, just mutate and refresh
        if (member !== null && typeof (member) !== 'undefined') {
          if (data.currentStatus !== 'OFFLINE' || member.roleId || (data.currentStatus === 'OFFLINE' && this.term && this.term.length && !this.searching)) {
            // console.log('NEW STATUS CHANGED - RETURNS RIGHT AFTER', data);
            member.user.status = CurrentStatus[data.currentStatus];
            this.ch.markForCheck();
            return;
          }

          // in any other case
          // if user goes OFFLINE, then if in list and has no role remove, otherwise mutate
          if (data.currentStatus === 'OFFLINE') {
            // console.log('SHOW ONLY ONLINE - AND STATUS IS OFFLINE', data);
            if (member.roleId || this.members.length <= 200) {
              // console.log('HAS ROLE - SO MUTATE AND RETURN RIGHT AFTER', data);
              member.user.status = CurrentStatus[data.currentStatus];
              this.ch.markForCheck();
              return;
            }

            // console.log('HAS NO ROLE - REMOVE AND RETURN RIGHT AFTER', data);
            this.removeMember(data.userId, false);

            const endLength = this.members.length;
            this.handleLastPage(startLength, endLength);

            this.calcCounters();
            this.ch.markForCheck();
            return;
          }
        }
        // console.log('NOT FOUND IN LIST - INSERT AND RETURN RIGHT AFTER', data);
        // otherwise it means that user came online, so fetch him to insert in list
        member = await this.teamService.getMemberRequest(data.userId);

        // status is validated again inside insertMember
        this.insertMember(member);

        const endLength = this.members.length;
        this.handleLastPage(startLength, endLength);

        this.calcCounters();
        this.ch.markForCheck();
      });

    this.subscribe(
      this.profileService.avatarChanged$,
      (data: Events.AvatarUpdated) => {
        const m = this.members.find(c => c.userId === data.userId);
        if (m && m.user) {
          m.user.avatar = data.avatar;
          this.ch.markForCheck();
        }
      });

    this.subscribe(
      this.profileService.usernameChanged$,
      async (data: LocalUsernameChanged) => {
        if (!data || data.teamId !== this.teamService.activeTeamId) return;
        const startLength = this.members.length;

        // remove user
        let member = this.removeMember(data.userId, false);
        if (member) {
          // if found mutate
          member.user.username = data.username;
        } else {
          // if not found fetch
          member = await this.teamService.getMemberRequest(data.userId);
        }
        // insert user again
        this.insertMember(member);

        const endLength = this.members.length;
        this.handleLastPage(startLength, endLength);

        this.calcCounters();
        this.ch.markForCheck();
      });

    this.ch.markForCheck();
  }

  async onScroll() {
    if ((this.membersList.nativeElement as HTMLDivElement).scrollHeight - (this.membersList.nativeElement.scrollTop + this.membersList.nativeElement.clientHeight) === 0) {
      await this.loadMoreMembers();
    }
  }

  resolveFaIcon(icon: string) {
    return icon.replace(':', ' fa-');
  }

  handleLastPage(previousCount, currentCount) {
    if (this.missingPageRecordCount < 1) { return; }
    this.missingPageRecordCount -= (currentCount - previousCount);
  }

  insertMember(newMember: Member) {
    // if user OFFLINE and we only show ONLINE (only search results OR members more than 200), then RETURN
    if (((this.term && this.term.length) || this.searching) && (!newMember.user.roleId && this.members.length > 200) && newMember.user.status === 'OFFLINE') {
      return;
    }

    const sameRoleMembers = this.members.filter(c => c.roleId === newMember.roleId);
    // if the role is not rendered until now (and we dont have enough space to add the role)
    if ((!sameRoleMembers || sameRoleMembers.length === 0) && this.missingPageRecordCount < 1) {
      return;
    }

    // if full page was loaded last
    if (this.missingPageRecordCount < 1) {
      // find last user in role
      const lastUserInRole = sameRoleMembers.reduce((p, c, i) => {
        if (p.user.username > c.user.username) {
          return p;
        }
        return c;
      });

      // if last user in role is less than new member username alphabetically, we must NOT insert so RETURN
      if (lastUserInRole.user.username <= newMember.user.username) {
        return;
      }
    }

    // if in list already mutate and return
    let found = this.members.find(c => c.userId === newMember.user.id);
    if (found) {
      found = { ...newMember };
    } else {
      // if we are here, we must insert and reorder the list
      this.members.push(newMember);
    }
    this.orderMembers();
  }

  removeMember(idToRemove: string, forceRemove: boolean) {
    const position = this.members.map(c => c.userId).indexOf(idToRemove);
    // remove only if more than 200 members found - up to 200 we show everyone - or MUST remove (banned, left)
    if (position >= 0 && (this.members.length > 200 || forceRemove)) {
      const removed = this.members.splice(position, 1);

      // return removed user if found and removed
      return removed[0];
    }
    // else return null
    return null;
  }

  async searchMembers(skip: number, take: number, reset = false) {
    let error = false;
    this.ch.markForCheck();
    let members = await this.teamService.searchMembers(this.term, !(this.term && this.term.length && this.searching), skip, (this.term && this.term.length && this.searching) ? 100 : take).catch(() => { error = true; return []; });
    if (error) {
      this.searching = false;
      this.ch.markForCheck();
      return;
    }
    this.haveMoreMembers = members.length === PAGE_SIZE;
    this.missingPageRecordCount = PAGE_SIZE - members.length;

    if (!this.canBan) {
      members = members.filter(m => !m.isBanned);
    }

    if (reset) {
      this.members = [];
    }

    this.members.push(...members);

    this.members = this.members
      .map(m => {
        if (m.isBanned) {
          m.roleId = '_banned';
        } else {
          m.roleId = m.roleId;
        }
        // m.currentStatus = CurrentStatus[m.user.currentStatus];
        return m;
      });
    this.orderMembers();
    this.calcCounters();
    this.searching = false;
    this.ch.markForCheck();
  }

  async loadMoreMembers() {
    await this.searchMembers(this.members.length, PAGE_SIZE);
  }

  loadRoles() {
    this.roles = (this.teamService.activeTeam && this.teamService.activeTeam.roles) ? [...this.teamService.activeTeam.roles] : [];
    this.roles.push(this.defaultRole);
    // this.roles.push(this.bannedRole);
    this.roleToggle = this.roles.reduce(
      (result, item, index, array) => {
        result[item.id] = true;
        return result;
      },
      {}) as any;
  }

  orderMembers() {
    const orderedRoleIds = this.roles.map(c => c.id);
    this.members
      .sort((a, b) => (a.user.username.toLowerCase() > b.user.username.toLowerCase()) ? 1 : ((b.user.username.toLowerCase() > a.user.username.toLowerCase()) ? -1 : 0))
      .sort((a, b) => {
        if (a.roleId === b.roleId) {
          return 0;
        }
        if (a.roleId && !b.roleId) {
          return -1;
        }
        if (!a.roleId && b.roleId) {
          return 1;
        }
        if (orderedRoleIds.indexOf(a.roleId) > orderedRoleIds.indexOf(b.roleId)) {
          return 1;
        }
        return -1;
      });
  }

  calcCounters() {
    this.membersCounter = this.roles.reduce(
      (result, item, index, array) => {
        result[item.id] = this.members.filter(m => m.roleId === item.id).length;
        return result;
      },
      {}) as any;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
