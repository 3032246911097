<div class="text-center p-4" *ngIf="!loading && status==='success'">
  <div class="text-success mb-4">
    <div class="mb-2">
      <i class="fal fa-check-circle fa-4x"></i>
    </div>
    <h4>Registered!</h4>
  </div>
  <p>Logging you in now...</p>
</div>

<div class="text-center p-4" *ngIf="!loading && status==='RESERVED_USERNAME'">
  <div class="text-danger mb-4">
    <div class="mb-2">
      <i class="fal fa-sad-tear fa-4x"></i>
    </div>
    <h4>Username is taken</h4>
  </div>
  <p>Please <a [ngStyle]="{'color': colorCode}" href="javascript:" (click)="status='register'">try again</a> with a
    different username.</p>
</div>

<div class="text-center p-4" *ngIf="!loading && status==='USERNAME_ALREADY_EXIST'">
  <div class="text-danger mb-4">
    <div class="mb-2">
      <i class="fal fa-sad-tear fa-4x"></i>
    </div>
    <h4>Username is taken</h4>
  </div>
  <p>Please <a [ngStyle]="{'color': colorCode}" href="javascript:" (click)="status='register'">try again</a> with a
    different username.</p>
  <p>If you forgot your account credentials you may reset them <a [ngStyle]="{'color': colorCode}"
      routerLink="/reset-password">here</a>.</p>
</div>

<div class="text-center p-4" *ngIf="!loading && status==='LINK_ALREADY_USED'">
  <div class="text-danger mb-4">
    <div class="mb-2">
      <i class="fal fa-sad-tear fa-4x"></i>
    </div>
    <h4>This link has already been used.</h4>
  </div>
  <p>If you forgot your account credentials you may reset them<br /><a [ngStyle]="{'color': colorCode}"
      routerLink="/reset-password">here</a>.</p>
  <p>If you mistyped your email<br /> <a [ngStyle]="{'color': colorCode}" routerLink="/signup">try again</a>.</p>
</div>

<div class="text-center p-4" *ngIf="!loading && status==='fail-generic'">
  <div class="text-danger mb-4">
    <div class="mb-2">
      <i class="fal fa-sad-tear fa-4x"></i>
    </div>
    <h4>Oh no!</h4>
  </div>
  <p>Something went wrong</p>
  <p class="text-muted"><small>{{err}}</small></p>
  <p><a [ngStyle]="{'color': colorCode}" href="javascript:" (click)="status='register'">try again</a>.</p>
</div>

<div *ngIf="!loading && status==='INVALID_TOKEN'" class="text-center p-4">
  <div class="text-danger mb-4">
    <div class="mb-2">
      <i class="fal fa-sad-tear fa-4x"></i>
    </div>
    <h4>Something is wrong with the link you provided.</h4>
  </div>
  <div class="text-light">
    <p>You can try to request a new email <a [ngStyle]="{'color': colorCode}" routerLink="/signup">here</a>.</p>
  </div>
</div>

<div *ngIf="status==='register'">
  <div class="header">
    <h1 *ngIf="!resetLegacyMode">Create your account</h1>
    <h1 *ngIf="resetLegacyMode">Reset password</h1>
    <p *ngIf="teamName && teamName!=='app' && !resetLegacyMode">
      Join <span [ngStyle]="{'color': colorCode}" class="one-line-ellipsis team-name">{{ teamName }}</span>
      <ng-container *ngIf="!isEchofin"> community</ng-container>
    </p>
    <p *ngIf="resetLegacyMode">Fill in your new password below and click "Change"</p>
  </div>
  <form name="form" (ngSubmit)="regForm.valid && register(regForm)" #regForm="ngForm">
    <div *ngIf="!resetLegacyMode" class="custom-form-group">
      <input class="custom-form-control" [ngStyle]="{'border-color': colorCode}" name="username"
        #usernameControl="ngModel" [(ngModel)]="username" placeholder="Username" pattern="^[a-zA-Z0-9][a-zA-Z0-9_.-]*$"
        [disabled]="loading" minlength="2" maxlength="18"
        [ngClass]="{ 'is-invalid': regForm.submitted && usernameControl.invalid }">
      <div *ngIf="regForm.submitted && usernameControl.invalid" class="invalid-feedback">
        <small *ngIf="usernameControl.errors.minlength">Username must be at least 2 characters long</small>
        <small *ngIf="usernameControl.errors.maxlength">Username must be up to 18 characters long</small>
        <small *ngIf="usernameControl.errors.pattern">Usernames include lower or upper case characters, numbers, dashes,
          dots and underscores and must start with a lower or upper case character or number</small>
      </div>
    </div>
    <div class="custom-form-group">
      <input class="custom-form-control" [ngStyle]="{'border-color': colorCode}" name="password" [(ngModel)]="password"
        type="password" [placeholder]="resetLegacyMode?'New Password':'Password'" [disabled]="loading">
    </div>
    <div *ngIf="resetLegacyMode" class="custom-form-group">
      <input class="custom-form-control" [ngStyle]="{'border-color': colorCode}" [ngModelOptions]="{standalone: true}"
        [(ngModel)]="confirmPassword" type="password" placeholder="Confirm new password" [disabled]="loading">
    </div>

    <button type="submit" class="btn" [ngStyle]="{'background-color': colorCode, color:contrastColor}"
      [disabled]="loading">
      <i *ngIf="loading" class="fas fa-sync-alt fa-fw fa-spin"></i>
      <span *ngIf="!resetLegacyMode" class="ml-2">SIGN UP</span>
      <span *ngIf="resetLegacyMode" class="ml-2">CHANGE</span>
    </button>

  </form>
  <ng-container *ngIf="!resetLegacyMode">
    <div class="info">
      <p>Do you have an account? Please <a routerLink="/login" [ngStyle]="{'color': colorCode}">Sign In</a> instead.</p>
    </div>
  </ng-container>
</div>