import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, NgZone, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { CreateMessageReq } from '@echofin/libraries/api/message/models/create-message-req';
import { FileAttachment } from '@echofin/libraries/api/message/models/file-attachment';
import { NgbModal, NgbPopover, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { EmojiService } from 'app/_core/services/emoji.service';
import { ToastrService } from 'ngx-toastr';
import { from, fromEvent, Subscription } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { StreamComponent } from '../../../chat/stream/stream.component';
import { FileUploadService } from '../../../_core/services/file-upload.service';
import { MessageTextFormatterService } from '../../../_core/services/message-text-formatter.service';
import { MAX_CHARS, MAX_CHARS_THRESHOLD, MessageService } from '../../../_core/services/message.service';
import { PanelsService } from '../../../_core/services/panels.service';
import { ProfileService } from '../../../_core/services/profile.service';
import { RoomService } from '../../../_core/services/room.service';
import { Smilie } from '../../../_core/services/smilies.service';
import { TeamService } from '../../../_core/services/team.service';
import { TitleHandlerService } from '../../../_core/services/title-handler.service';
import { FileUploaded } from '../../../_shared/models/commons/filestack.models';
import { Message } from '../../../_shared/models/commons/message';
import { RULE } from '../../../_shared/models/commons/permissions.constants';
import { RoomType } from '../../../_shared/models/enums/room-type';
import { MessageType } from '../../../_shared/models/message/message-type';
import { PanelType } from '../../../_shared/models/room/channel';
import { Participant } from '../../../_shared/models/room/participant';
import { Chatroom } from '../../../_shared/models/room/room';
import { DirectLabelPipe } from '../../../_shared/pipes/direct-channel-members.pipe';
import { BaseComponent } from '../../base-component';
import { TypingService } from '../../is-typing/services/typing.service';
import { MarkdownComponent } from '../../markdown/markdown.component';
import { MarketOverviewComponent } from '../../market-overview/market-overview.component';
import { MultilineModalComponent } from '../../multiline-modal/multiline-modal.component';
import { SignalComponent } from '../../signal/signal.component';
import { GiphyComponent } from '../../giphy/giphy.component';
@Component({
  selector: 'app-chatbox',
  templateUrl: './chatbox.component.html',
  styleUrls: ['./chatbox.component.scss']
})
export class ChatboxComponent extends BaseComponent implements OnInit {

  @Input() room: Chatroom;
  @Input() conversationJustStarted = false;
  @Input() panelType: PanelType;
  @Input() replyParentId: string;
  @Input() panelId: string;
  @Input() chatroomTabIndex: number;

  @Output('send') send: EventEmitter<any> = new EventEmitter<any>();
  @Output('focus') focus: EventEmitter<any> = new EventEmitter<any>();
  @Output('blur') blur: EventEmitter<any> = new EventEmitter<any>();
  @Output() roomJustCreated: EventEmitter<string> = new EventEmitter<string>();

  @HostBinding('class.readonly') get readonly() {
    return this.room ? !this.room.permissions.user[RULE.POST_MESSAGES] : true;
  }

  @HostBinding('class.isMaximized') get isMaximized() {
    // return this.room && (this.panelsService.maximizedPanels[this.teamService.activeTeamId] === this.room.id) || (this.panelsService.maximizedPanels['direct'] === this.room.id);
    return false;
  }

  @ViewChild('text', { static: true }) text: ElementRef;
  @ViewChild('inputImage') inputImage: ElementRef;
  @ViewChild('smiliePopover') smiliePopover: NgbPopover;
  @ViewChild('autocomplete') autocomplete: ElementRef;
  @ViewChild('commandPopup') commandPopup: NgbTypeahead;

  @HostListener('document:click', ['$event'])
  public onClick(event) {
    if (!this.autocompleteList.length) {
      return;
    }
    const clickedInside = this.autocomplete.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.autocompleteList = [];
      this.autocompleteIndex = 0;
      this.cd.detectChanges();
    }
  }

  charsLeft: number = null;
  roomName = '';
  smilieTabs: { key: string, smilies: Smilie[] }[];
  smilieSelTab = null;
  defaultPlaceholder = '';
  disabled = false;
  inFocus = false;
  canShareGIFS: boolean = false;
  hasMeeting;
  showExpandButton = false;
  temporarilyDisabledEnter = false;
  autocompleteList = [];
  autocompleteBaseLength = 0;
  autocompleteIndex = 0;
  private unlisten: Function[] = [];

  caretInfo: { start: number, end: number };

  isTypingSubscription: Subscription;

  get isReplyPanel(): boolean {
    return this.panelType === PanelType[PanelType.ReplyMessage];
  }

  get isQuote(): boolean {
    return !!this.messageService.isInQuotes[this.panelId];
  }

  get isReply(): boolean {
    if (this.isReplyPanel) {
      return true;
    }
    return !!this.messageService.isInReplies[this.room.id];
  }

  get isSecret(): boolean {
    return this.text.nativeElement.value && this.text.nativeElement.value.startsWith('/secret ');
  }

  get isReadOnly(): boolean {
    return this.room ? !this.room.permissions.user[RULE.POST_MESSAGES] : true;
  }

  get noCharsLeft(): boolean {
    return this.charsLeft !== null && this.charsLeft < 0;
  }

  get placeholder(): string {
    return !this.isReadOnly ? this.defaultPlaceholder : 'This is a read-only chatroom';
  }

  get allowPost(): boolean {
    return !this.isReadOnly && (this.charsLeft === null || (this.charsLeft !== null && this.charsLeft > -1));
  }

  // get canPost(): boolean {
  //   return !!this.text.nativeElement.value && !!this.text.nativeElement.value.trim() && !this.disabled;
  // }

  constructor(
    private panelsService: PanelsService,
    private messageService: MessageService,
    private teamService: TeamService,
    private profileService: ProfileService,
    private roomService: RoomService,
    private uploadService: FileUploadService,
    private typingService: TypingService,
    private mtf: MessageTextFormatterService,
    private modal: NgbModal,
    private toastr: ToastrService,
    private directLabelPipe: DirectLabelPipe,
    private titleHandler: TitleHandlerService,
    private cd: ChangeDetectorRef,
    private hostElement: ElementRef,
    private zone: NgZone,
    private renderer: Renderer2,
    private emojiService: EmojiService
  ) {
    super();
  }

  ngOnInit() {
    this.subscribe(this.messageService.insertEmojiOrReact$, (emojiData) => {
      if (this.panelId === emojiData.panelId) {
        const prev: string = this.text.nativeElement.value;
        let current = '';
        if (this.caretInfo) {
          current = prev.slice(0, this.caretInfo.start) + emojiData.emoji + prev.slice(this.caretInfo.end);
        } else {
          current = prev + emojiData.emoji;
        }
        this.text.nativeElement.value = current;
        const newCaretPosition = this.caretInfo ? this.caretInfo.start + emojiData.emoji.length : current.length;
        this.caretInfo = null;
        setTimeout(() => {
          this.text.nativeElement.focus({ preventScroll: true });
          this.text.nativeElement.selectionStart = newCaretPosition;
          this.text.nativeElement.selectionEnd = newCaretPosition;
        }, 100);
      }
    });
    this.subscribe(
      this.panelsService.showedItem$,
      (panelId) => {
        if (((this.panelType !== PanelType.ReplyMessage && panelId === this.room.id) ||
          (this.panelType === PanelType.ReplyMessage && panelId === this.replyParentId))
          && this.room.permissions && this.room.permissions.user && this.room.permissions.user[RULE.POST_MESSAGES]) {
          this.text.nativeElement.focus({ preventScroll: true });
        }
      });
    this.subscribe(
      this.messageService.quote$,
      (data) => {
        if (data.room === this.panelId && this.room.permissions) {
          this.text.nativeElement.focus({ preventScroll: true });
        }
      });

    this.subscribe(
      this.teamService.meetingsUpdated$,
      (meetings) => this.calculateMeeting(meetings[this.teamService.activeTeamId]));

    if (this.panelType !== PanelType[PanelType.ReplyMessage]) { // don't focus on thread chatbox when a user push "reply"
      this.subscribe(
        this.messageService.reply$,
        (data) => {
          if (data.room === this.room.id && this.room.permissions) {
            this.text.nativeElement.focus({ preventScroll: true });
          }
        });
    }

    this.calculateMeeting(this.teamService.meetings[this.teamService.activeTeamId]);
    this.setupPlaceholder();

    this.zone.runOutsideAngular(() => {
      this.unlisten.push(this.renderer.listen(
        this.text.nativeElement,
        'keydown',
        (e) => { this.onKeydown(e); }
      ));

      const input$ = fromEvent(this.text.nativeElement, 'input');

      this.subscribe(input$, (async () => {
        const charsLeft = MAX_CHARS - this.text.nativeElement.value.trim().length;
        this.charsLeft = charsLeft <= MAX_CHARS_THRESHOLD ? charsLeft : null;
      }));

      this.isTypingSubscription = this.typingService.subscribeChatboxTyping(input$, this.room.id);

      this.subscribe(input$, (async () => {
        if (this.text.nativeElement.value.startsWith('/')) {
          this.autocompleteList = this.suggestCommands(this.text.nativeElement.value);
          this.autocompleteBaseLength = this.text.nativeElement.value.length;
          this.autocompleteIndex = 0;
          this.cd.detectChanges();
        }
      }));

      this.subscribe(
        input$.pipe(
          debounceTime(200),
          switchMap(() => {
            const lastTerm = this.getLastTerm(this.text.nativeElement.value);
            return from(this.suggestMentions(lastTerm));
          }),
        ),
        (s: { mentions, lastTerm }) => {
          if (!this.text.nativeElement.value.startsWith('/')) {
            if (!s.mentions) {
              this.autocompleteList = [];
              this.autocompleteBaseLength = 0;
              this.autocompleteIndex = 0;
              this.cd.detectChanges();
            } else {
              if ('@everyone'.toLowerCase().startsWith(s.lastTerm.toLowerCase())) {
                s.mentions.unshift('@everyone');
              }
              this.autocompleteList = s.mentions.slice(0, 5);
              this.autocompleteBaseLength = s.lastTerm.length;
              this.autocompleteIndex = 0;
              this.cd.detectChanges();
            }
          }
        });
    });
  }

  calculateMeeting(meetings) {
    if (!meetings) {
      this.hasMeeting = false;
      this.cd.markForCheck();
      return;
    }
    const meeting = meetings.find(x => x.chatroomId === this.room?.id);
    this.hasMeeting = !!meeting;
    this.cd.markForCheck();
  }

  removeQuotes() {
    this.messageService.removeQuotes(this.panelId);
  }

  removeReplies() {
    this.messageService.removeReplies(this.room.id);
  }

  handleReplyRequest(model: CreateMessageReq) {
    if (this.isReplyPanel) {
      model.reply = {
        parent: false,
        parentId: this.replyParentId
      };
    }
    // Reply Message
    if (this.messageService.isInReplies[model.chatroomId]) {
      model.reply = {
        parent: false,
        parentId: this.messageService.replies[model.chatroomId]
      };
      this.messageService.isInReplies[model.chatroomId] = false;
      this.messageService.replies[model.chatroomId] = '';
    }
  }

  async inputSend(event: Event, text: string, files: FileUploaded[], chatroomIds: string[], type?: MessageType) {
    if (event) {
      event.preventDefault();
      // tslint:disable-next-line: no-parameter-reassignment
      text = this.text.nativeElement.value;
    }

    if (event && (!this.text.nativeElement.value || !this.text.nativeElement.value.trim() || this.autocompleteList.length)) {
      return;
    }

    if (!this.room.permissions.user[RULE.POST_MESSAGES]) {
      return;
    }

    if (this.isReadOnly || this.noCharsLeft) {
      return;
    }

    if (this.text.nativeElement.value.trim() === '/shout') {
      return;
    }

    if (this.panelId.indexOf('local-id-') === 0) {
      this.disabled = true;
    }
    this.cd.detectChanges();

    if (this.room.type === RoomType.Group && this.conversationJustStarted) {
      const usersIds = this.room.participants.map(p => p.user.id);
      const instanceRoom = await this.roomService.createRoom(this.teamService.activeTeamId, usersIds)
        .catch((err) => {
          this.toastr.error(err.error.Message, 'Error');
          this.disabled = false;
          return null;
        });
      if (instanceRoom === null) {
        this.disabled = false;
        return;
      }
      const droom = this.roomService.directs.find(d => d.id === this.room.id);
      if (droom) {
        droom.id = instanceRoom.id;
      }
      this.room.id = instanceRoom.id;

      this.roomJustCreated.emit(instanceRoom.id);

      this.roomName = this.createName();

      this.titleHandler.removeTitle(this.room.id);
      this.titleHandler.setTitle(`${this.getTitleEmoji(this.room.type)}${this.room.name ? this.room.name : this.roomName} | ${this.teamService.activeTeam?.name ?? ''}`, this.room.id);

      this.conversationJustStarted = false;
    }

    await this.sendMessage(text, files, chatroomIds, type);
    this.disabled = false;
  }

  async sendMessage(text: string, files: FileUploaded[], chatroomIds: string[], type: MessageType) {
    const timestamp = Date.now();
    const msgObj = await this.mtf.getFormatter(text);

    if (!msgObj || (msgObj.text === null && msgObj.type === MessageType.SECRET)) {
      return;
    }

    const messageReq: CreateMessageReq = {
      files,
      chatroomId: this.room.id,
      type: type ? type : msgObj.type,
      text: msgObj.text,
      secret: msgObj.secret,
    };
    this.charsLeft = null;
    this.text.nativeElement.value = null;

    this.handleReplyRequest(messageReq);

    if (this.isReplyPanel) {
      await this.messageService.sendFromReplyPanel(messageReq);
    } else {
      if (chatroomIds && chatroomIds.length) {
        chatroomIds.forEach(async chatroomId => {
          messageReq.chatroomId = chatroomId;
          await this.messageService.send(messageReq);
        });
      } else {
        await this.messageService.send(messageReq);
      }
    }

    // settimeout 200ms to make sure GET api will respond with message that was just sent (crate)
    setTimeout(() => {
      this.text.nativeElement.focus({ preventScroll: true });
      this.send.emit();
    }, 200);
  }

  // inputChanged($event) {
  //   $event.preventDefault();

  //   const charsLeft = MAX_CHARS - this.text.nativeElement.value.trim().length;
  //   this.charsLeft = charsLeft <= MAX_CHARS_THRESHOLD ? charsLeft : null;
  //   if (this.charsLeft) {
  //     this.cd.detectChanges();
  //   }
  //   this.typingService.addIsTyping(this.profileService.me.id, this.room.id, this.profileService.me.username);
  // }

  inputFocus($event) {
    this.caretInfo = null;
    this.inFocus = true;
    this.focus.emit($event);
  }

  inputBlur($event) {
    this.caretInfo = { start: this.text.nativeElement.selectionStart, end: this.text.nativeElement.selectionEnd };
    this.blur.emit($event);
    setTimeout(() => {
      this.inFocus = false
    }, 200);
  }

  async handleTextPaste(text: string) {
    await this.openMultiLineInput(null, text);
  }

  async inputPaste(event: any) {
    const items = event.clipboardData.items;
    let blob = null;
    if (items.length > 0) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].type === 'text/plain') {
          const pasteText = event.clipboardData.getData('text');
          if (pasteText.indexOf('\n') > 0) {
            const currentText = (this.text.nativeElement as HTMLInputElement).value;
            const caretStart = (this.text.nativeElement as HTMLInputElement).selectionStart;
            const caretEnd = (this.text.nativeElement as HTMLInputElement).selectionEnd;
            const finalText = `${currentText.substr(0, caretStart)}${pasteText}${currentText.substr(caretEnd)}`;
            await this.handleTextPaste(finalText);
          }
          return;
        }
        if (items[i].type.indexOf('image/') === 0) {
          blob = items[i].getAsFile();
        }
      }
    } else {
      return;
    }

    const res = await this.composeMessageWhileUploading(blob, this.isReplyPanel).catch(reason => {
      return null;
    });
    if (!res) {
      return;
    }

    const messageReq: CreateMessageReq = {
      text: res.text,
      chatroomId: this.room.id,
      type: MessageType.TEXT,
      files: res.files ? res.files.map(f => {
        return {
          filename: f.filename,
          mime: f.mimetype,
          url: f.url,
          handle: f.handle,
        };
      }) : null,
    };

    this.handleReplyRequest(messageReq);

    await this.inputSend(null, res.text, res.files, res.chatrooms);

    // const res = await this.composeMessageAfterUpload(attachments, this.isReplyPanel).catch(reason => {
    //   return null;
    // });
    // if (!res) {
    //   return;
    // }

    // const messageReq: CreateMessageReq = {
    //   text: res.text,
    //   chatroomId: this.room.id,
    //   type: MessageType.TEXT,
    //   files: res.files ? res.files.map(f => {
    //     return {
    //       filename: f.filename,
    //       mime: f.mimetype,
    //       url: f.url,
    //       handle: f.handle,
    //     };
    //   }) : null,
    // };
    // this.handleReplyRequest(messageReq);

    // await this.inputSend(null, res.text, res.files, res.chatrooms);
  }

  async openAttachment() {
    const files = await this.uploadService.pickFiles();
    if (!files.length) return
    const attachments = files.map(f => {
      return {
        filename: f.filename,
        mime: f.mimetype,
        url: f.url,
        handle: f.handle,
      };
    });
    const res = await this.composeMessageAfterUpload(attachments, this.isReplyPanel).catch(reason => {
      return null;
    });

    if (!res) {
      return;
    }
    const messageReq: CreateMessageReq = {
      text: res.text,
      chatroomId: this.room.id,
      type: MessageType.TEXT,
      files: res.files ? res.files.map(f => {
        return {
          filename: f.filename,
          mime: f.mimetype,
          url: f.url,
          handle: f.handle,
        };
      }) : null,
    };
    this.handleReplyRequest(messageReq);

    await this.inputSend(null, res.text, res.files, res.chatrooms);
  }

  async openSignal() {
    const modal = this.modal.open(SignalComponent, {
      backdrop: 'static',
      centered: true,
      windowClass: 'modal-dark'
    });
    modal.componentInstance.chatroomId = this.room.id;
    modal.componentInstance.teamId = this.room.teamId;
    modal.componentInstance.isTeamChatroom = this.room.type === 'Team';
    modal.componentInstance.isReplyPanel = this.isReplyPanel;
    modal.componentInstance.replyParentId = this.replyParentId;
    await modal.result.catch((err) => {
      if (err && err !== 1) {
        this.toastr.error(err);
      }
    });
    this.send.emit();
    this.text.nativeElement.focus({ preventScroll: true });
  }

  async openGiphy() {

    if (!this.room.permissions.user[RULE.POST_SIGNALS]) {
      this.toastr.error('Your user role does not have permissions for this action.');
      return;
    }

    const modal = this.modal.open(GiphyComponent, {
      centered: true,
      windowClass: 'modal-dark',
      size: 'lg'
    });

    const res = await modal.result.catch((err) => {
      if (err && err !== 1) {
        this.toastr.error(err);
      }
      return null;
    });
    if (res) {
      await this.inputSend(null, res.text, res.files, null, MessageType.SIGNAL);
    }
  }

  openMarkdown() {
    const modal = this.modal.open(MarkdownComponent, {
      centered: true,
      windowClass: 'modal-dark',
      size: 'lg'
    });
  }

  openMeeting() {
    const modal = this.modal.open(StreamComponent, {
      centered: true,
      windowClass: 'modal-dark'
    });
    modal.componentInstance.room = this.room;
    if (this.room.type !== 'Team') {
      modal.componentInstance.participants = this.getDirectMembersString(this.profileService.me.id, this.room.participants);
    } else {
      modal.componentInstance.participants = null;
    }
  }

  async openMarket() {
    if (!this.room.permissions.user[RULE.POST_MARKET_ANALYSIS]) {
      this.toastr.error('Your user role does not have permissions for this action.');
      return;
    }

    const modal = this.modal.open(MarketOverviewComponent, {
      backdrop: 'static',
      centered: true,
      windowClass: 'modal-dark'
    });

    modal.componentInstance.chatroomId = this.room.id;
    modal.componentInstance.isTeamChatroom = this.room.type === 'Team';
    modal.componentInstance.isReplyPanel = this.isReplyPanel;
    const res = await modal.result.catch((err) => {
      if (err && err !== 1) {
        this.toastr.error(err);
      }
      return null;
    });
    if (res) {
      await this.inputSend(null, res.text, res.files, res.chatrooms, MessageType.MARKET_ANALYSIS);
    }
  }

  addSmilie(icon) {
    if (this.text.nativeElement.value) {
      this.text.nativeElement.value = (this.text.nativeElement.value.slice(-1) === ' ') ? this.text.nativeElement.value : `${this.text.nativeElement.value} `;
    } else if (!this.text.nativeElement.value) {
      this.text.nativeElement.value = '';
    }
    // const emojiCode = `${icon.img}`;
    // this.text.nativeElement.value += `${String.fromCodePoint(parseInt(emojiCode, 16))}`;
    this.text.nativeElement.value += icon.char;
    this.smiliePopover.close();
    this.text.nativeElement.focus({ preventScroll: true });
  }

  getTitleEmoji(type: 'Direct' | 'Group' | 'Team') {
    switch (type) {
      case RoomType.Direct:
        return '@';
      case RoomType.Group:
        return '💬';
      case RoomType.Team:
      default:
        return '#';
    }
  }

  createName() {
    return this.directLabelPipe.transform(this.room as any, this.profileService.me.id);
  }

  selectItem(indexItem: number) {
    const item = this.autocompleteList[indexItem];
    this.autocompleteList = [];

    const caretPos = this.text.nativeElement.selectionStart;
    const leftPart = this.text.nativeElement.value.substr(0, caretPos);
    const rightPart = this.text.nativeElement.value.substr(caretPos);
    const parts = leftPart.split(' ');
    parts[parts.length - 1] = item;
    const newLeftPart = parts.join(' ');
    const newText = newLeftPart + rightPart;
    this.text.nativeElement.value = `${newText} `;

    this.cd.detectChanges();
    setTimeout(
      () => {
        this.text.nativeElement.selectionStart = newLeftPart.length + 1;
        this.text.nativeElement.selectionEnd = newLeftPart.length + 1;
      },
      0);
  }

  getLastTerm(text: string) {
    const caretPos = this.text.nativeElement.selectionStart;
    const subText = text.substr(0, caretPos);
    const terms = subText.split(' ');
    const lastTerm = terms[terms.length - 1];
    return lastTerm;
  }

  private async suggestMentions(lastTerm: string) {
    if (!lastTerm.startsWith('@')) {
      return { mentions: null, lastTerm };
    }
    let members = [];
    const searchTerm = lastTerm.substr(1);

    if (this.room.type === 'Team') {
      members = (await this.teamService.searchMembers(searchTerm))
        .filter(member => !member.isBanned && member.user.username.toLowerCase().indexOf(searchTerm.toLowerCase()) === 0)
        .map(member => `@${member.user.username}`);
    } else {
      members = [...this.room.participants]
        .filter(member => member.user.username.toLowerCase().indexOf(searchTerm.toLowerCase()) === 0)
        .map(participant => `@${participant.user.username}`);
    }
    return { mentions: members.sort((a, b) => a > b ? 1 : -1), lastTerm: lastTerm };
  }

  private suggestCommands(lastTerm: string) {
    return ['/secret', '/shout']
      .filter(user => user.startsWith(lastTerm))
      .sort((a, b) => a > b ? 1 : -1);
  }

  private setupPlaceholder() {
    if (this.isReplyPanel) {
      return 'Reply to thread';
    }
    const name = this.room.name
      ? this.room.name
      : this.getDirectMembersString(this.profileService.me.id, this.room.participants);

    this.defaultPlaceholder = `Message ${name}`;
    if (this.room.teamId) {
      const team = this.teamService.teams.find(t => t.id === this.room.teamId);
      const teamLabel = team.label && team.label.length ? team.label : team.name;
      this.defaultPlaceholder = `${teamLabel ? teamLabel : ''} #${name}`;
    }
  }

  private getDirectMembersString(ownProfileId: string, members: Participant[]) {
    const m = members || [];
    const fMembers = m.filter(m => m.user.id !== ownProfileId);

    if (fMembers.length === 0) {
      return '--???--';
    }
    if (fMembers.length === 1) {
      return `${fMembers[0].user.username}`;
    }
    if (fMembers.length === 2) {
      return `${fMembers[0].user.username} & ${fMembers[1].user.username}`;
    }

    return `${fMembers[0].user.username}, ${fMembers[1].user.username} & ${fMembers.length - 2} more`;

  }

  async onExpandInput() {
    await this.openMultiLineInput(null, this.text.nativeElement.value);
  }

  async openMultiLineInput(msg?: Message, text?: string) {
    // expression changed console error if this is removed
    (this.text.nativeElement as HTMLInputElement).blur();

    if (msg) {
      // tslint:disable-next-line: no-parameter-reassignment
      text = msg.text;
    }

    let modal;
    if (msg && msg.type === MessageType.MARKET_ANALYSIS) {
      modal = this.modal.open(MarketOverviewComponent, {
        backdrop: 'static',
        centered: true,
        windowClass: 'modal-dark'
      });
      modal.componentInstance.textToEdit = text;
      modal.componentInstance.files = msg.files;
    } else {
      modal = this.modal.open(MultilineModalComponent, {
        backdrop: 'static',
        size: 'lg',
        centered: true,
        windowClass: 'modal-dark'
      });
      modal.componentInstance.messageText = text;
      modal.componentInstance.chatroomName = this.room.type === 'Team' ? this.room.name : '';
      modal.componentInstance.participants = this.room.participants;
    }

    modal.componentInstance.isTeamChatroom = this.room.type === 'Team';
    modal.componentInstance.chatroomId = this.room.id;
    modal.componentInstance.isReplyPanel = this.isReplyPanel;
    if (msg) {
      modal.componentInstance.messageTimestamp = msg.timestamp;
      modal.componentInstance.senderId = msg.sender.id;
      modal.componentInstance.okBtnLabel = 'Update message';
    }
    await modal.result
      .then(async res => {
        if (res) {
          (this.text.nativeElement as HTMLInputElement).value = '';
          if (msg && res.text !== undefined && res.text !== null) {
            await this.messageService.replaceMessage(msg.id, res.text, this.panelId);
          } else {
            if (res.text !== undefined && res.text !== null) {
              await this.inputSend(null, res.text, res.files, res.chatrooms);
            }
          }
        }
      })
      .catch((err) => {
        if (err && err !== 'cancel' && err !== 1) {
          this.toastr.error(err);
        }
        this.text.nativeElement.focus({ preventScroll: true });
      });

    this.text.nativeElement.focus({ preventScroll: true });
  }

  async composeMessageAfterUpload(files: FileAttachment[], isReplyPanel: boolean): Promise<{ text: string, files: FileAttachment, chatrooms: string[] }> {
    // expression changed console error if this is removed
    (this.text.nativeElement as HTMLInputElement).blur();

    const text = this.text.nativeElement.value;

    const modal = this.modal.open(MultilineModalComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
      windowClass: 'modal-dark'
    });
    modal.componentInstance.messageText = text;
    modal.componentInstance.chatroomId = this.room.id;
    modal.componentInstance.chatroomName = this.room.type === 'Team' ? this.room.name : '';
    modal.componentInstance.participants = this.room.participants;
    modal.componentInstance.title = 'Add a comment';
    modal.componentInstance.files = files;
    modal.componentInstance.isReplyPanel = isReplyPanel;
    modal.componentInstance.isTeamChatroom = this.room.type === 'Team';

    const returnValue = await modal.result
      .then(async res => {
        return res;
      })
      .catch((reason) => {
        if (reason && reason === 'cancel') {
          throw reason;
        }
      });

    this.text.nativeElement.focus({ preventScroll: true });

    if (returnValue && returnValue.text && returnValue.text.length) {
      (this.text.nativeElement as HTMLInputElement).value = '';
    }

    return returnValue;
  }

  async composeMessageWhileUploading(file: File | Blob, isReplyPanel: boolean): Promise<{ text: string, files: FileAttachment, chatrooms: string[] }> {
    // expression changed console error if this is removed
    (this.text.nativeElement as HTMLInputElement).blur();

    const text = this.text.nativeElement.value;

    const modal = this.modal.open(MultilineModalComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
      windowClass: 'modal-dark'
    });
    modal.componentInstance.messageText = text;
    modal.componentInstance.chatroomId = this.room.id;
    modal.componentInstance.chatroomName = this.room.type === 'Team' ? this.room.name : '';
    modal.componentInstance.participants = this.room.participants;
    modal.componentInstance.title = 'Add a comment';
    modal.componentInstance.fileToUpload = file;
    modal.componentInstance.isReplyPanel = isReplyPanel;
    modal.componentInstance.isTeamChatroom = this.room.type === 'Team';

    const returnValue = await modal.result
      .then(async res => {
        return res;
      })
      .catch((reason) => {
        if (reason && reason === 'cancel') {
          throw reason;
        }
      });

    this.text.nativeElement.focus({ preventScroll: true });

    if (returnValue && returnValue.text && returnValue.text.length) {
      (this.text.nativeElement as HTMLInputElement).value = '';
    }

    return returnValue;
  }

  async onKeydown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      if (event.shiftKey) {
        event.preventDefault();
        await this.onExpandInput();
      } else if (this.autocompleteList.length) {
        event.preventDefault();
        this.selectItem(this.autocompleteIndex);
      } else if (!this.disabled) {
        event.preventDefault();
        await this.inputSend(event, null, null, null);
      }
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      // allow up key to select from list popup
      if (this.autocompleteList.length) {
        this.autocompleteIndex = (this.autocompleteList.length + this.autocompleteIndex - 1) % this.autocompleteList.length;
        this.cd.detectChanges();
        return;
      } else {
        if (this.messageService.messages[this.room.id]) {
          const myMessages = (this.messageService.messages[this.room.id] || []).filter(m => m.sender.id === this.profileService.me.id);
          if (!myMessages || !myMessages.length) { return; }
          const msgToEdit = myMessages.reduce((p, c, i) => { return c.timestamp > p.timestamp ? c : p; });

          await this.openMultiLineInput(msgToEdit);
        }
      }
    } else if (event.key === 'ArrowDown' && this.autocompleteList.length) {
      event.preventDefault();
      // allow down key to select from list popup
      this.autocompleteIndex = (this.autocompleteIndex + 1) % this.autocompleteList.length;
      this.cd.detectChanges();
      return;
    } else if (event.key === 'Tab' && this.autocompleteList.length) {
      event.preventDefault();
      this.selectItem(this.autocompleteIndex);
      return;
    } else if (event.key === 'Escape' && this.autocompleteList.length) {
      event.preventDefault();
      this.autocompleteList = [];
      this.autocompleteIndex = 0;
      this.cd.detectChanges();
      return;
    }
  }

  toggleEmojis(event) {
    this.emojiService.openPanelPicker(event.target, this.panelId);
  }

  async importFile(event) {
    if (event.target.files.length == 0) {
      console.log("No file selected!");
      return
    }
    let selectedFile: File = event.target.files[0];

    const res = await this.composeMessageWhileUploading(selectedFile, this.isReplyPanel).catch(reason => {
      return null;
    });
    if (!res) {
      return;
    }

    const messageReq: CreateMessageReq = {
      text: res.text,
      chatroomId: this.room.id,
      type: MessageType.TEXT,
      files: res.files ? res.files.map(f => {
        return {
          filename: f.filename,
          mime: f.mimetype,
          url: f.url,
          handle: f.handle,
        };
      }) : null,
    };

    this.handleReplyRequest(messageReq);

    await this.inputSend(null, res.text, res.files, res.chatrooms);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.isTypingSubscription) {
      this.isTypingSubscription.unsubscribe();
    }

    for (let i = 0; i < this.unlisten.length; i++) {
      this.unlisten[i]();
    }
    if (this.hostElement && this.hostElement.nativeElement) {
      (this.hostElement.nativeElement as HTMLElement).innerHTML = '';
    }
  }
}
