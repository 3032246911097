<div class="add-team">
    <div class="modal-header">
        <h4 class="modal-title">What do you want to do?</h4>
        <button type="button" class="close" aria-label="Close" (click)="dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="d-flex p-3 justify-content-between">
            <a [routerLink]="['/discover']" class="btn d-flex flex-column justify-content-center btn-add-team"
                (click)="trackEvent('discover')">
                <i class="fad fa-compass fa-fw fa-4x m-auto"></i>
                <br>
                Discover communities<br><br>
            </a>
            <a [routerLink]="['/create']" class="btn d-flex flex-column justify-content-center btn-add-team"
                (click)="trackEvent('creation')">
                <i class="fad fa-users-crown fa-fw fa-4x m-auto"></i>
                <br>
                Create your community<br><br>
            </a>
        </div>
        <div *ngIf="isAdmin" class="text-muted">
            <small><fa-icon [icon]="['far','info-circle']"></fa-icon> Remove Community Discovery &amp; Creation from your users'
                workspace with <a href="https://www.ensemble.chat/detailed-features?utm_source=team-discovery"
                    target="_blank">Pro Plan</a></small>
        </div>
    </div>
</div>