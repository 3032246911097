<div class="modal-content">
    <div class="modal-header">
        <button 
            *ngIf="(slideIndex!=lastSlide)"
            class="close" type="button" data-dismiss="modal" aria-label="Close" 
            (click)="closeWalkthrough()">
            <span aria-hidden="true" class="text-dark">SKIP</span>
        </button>
    </div>
    <div class="modal-body">      
        <div class="blog-slider__container">
            <div class="blogSlides" *ngFor="let slide of slides">
                <div class="row">
                    <div class="col-md-5 text-center">
                        <video width="240" height="440" autoplay loop *ngIf="slide.type==='video'">
                            <source 
                                src="./assets/walkthrough/{{slide.src}}.mp4?v2" 
                                type="video/mp4" />
                        </video>
                        <img 
                            *ngIf="slide.type==='image'"
                            src="./assets/walkthrough/{{slide.src}}.png" 
                            />
                    </div>
                    <div class="col-md-7 justiflex">
                        <h4 class="text-secondary mb-3">{{slide.title}}</h4>
                        <div [innerHTML]="slide.description"></div>
                    </div>
                </div>
            </div>
          </div>
         
    </div>
    <div class="modal-footer">
        <div class="slide-buttons">
            <div class="blogSlider__dots" style="text-align:center">
                <span *ngFor="let slide of slides; let i = index" [ngClass]="{'active':i==0}" class="blogSlider__dots--dot" (click)="currentSlide((i+1))"></span> 
            </div>
            <a *ngIf="(slideIndex!=lastSlide)" class="next" (click)="plusSlides(1)">Next <fa-icon [icon]="['far','long-arrow-alt-right']"></fa-icon></a>
            <a *ngIf="(slideIndex==lastSlide)" class="last" (click)="closeWalkthrough()">Get Started</a>
        </div>
    </div>
</div>