import { Injectable } from '@angular/core';
import { Angulartics2Amplitude } from 'angulartics2/amplitude';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  constructor(
    private angulartics2Amplitude: Angulartics2Amplitude,
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager
  ) { }

  public trackEvent(name, params = null) {
    if (environment.config.name !== 'ensemble') return;
    this.angulartics2Amplitude.eventTrack(name, !!params ? { ...params, visibilityState: document.visibilityState } : { visibilityState: document.visibilityState });
    this.angulartics2GoogleTagManager.eventTrack(name, !!params ? { ...params, visibilityState: document.visibilityState } : { visibilityState: document.visibilityState });
  }

  public setUserProperties(properties) {
    if (environment.config.name !== 'ensemble') return;
    this.angulartics2Amplitude.setUserProperties(properties);
    this.angulartics2GoogleTagManager.pushLayer(properties);
  }
}
