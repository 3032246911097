<div class="modal-content" [formGroup]="marketForm">
    <div class="modal-header">
        <h4 class="modal-title">Story</h4>
        <button class="close" type="button" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <!-- <div class="form-group">
                        <label for="mcomment">Headline</label>
                        <div class="input-group">
                            <input [formControl]="subjectField" name="msubject" class="form-control"
                                (input)="typing($event.target.value,0)" />
                        </div>
                    </div> -->
                    <div class="form-group">
                        
                        <div class="input-group" style="flex-direction: column;">
                            <textarea [formControl]="textField" name="mcomment" class="form-control" rows="8"
                                [ngClass]="{'is-invalid': submitted && textField.invalid }"
                                (input)="typing($event.target.value,1)"></textarea>
                            <div class="invalid-feedback">
                                <small class="d-block" *ngIf="submitted && textField.invalid">The
                                    Story content is required.</small>
                            </div>
                        </div>
                       
                    </div>
                    <div class="form-group">
                        <h6>Link</h6>
                        <div class="input-group">
                            <input [formControl]="linkField" type="text" name="mlink" class="form-control"
                                placeholder="optional" [ngClass]="{'is-invalid': submitted && linkField.invalid }"
                                (input)="typing($event.target.value,2)" />
                        </div>
                        <div class="borderfx"></div>
                        <div *ngIf="submitted && linkField.invalid" class="invalid-feedback d-block">
                            <small>The Link should be a valid URL.</small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="col pl-0">
                    <small [ngClass]="{'text-danger': charsLeft<0, 'text-warning': charsLeft>=0}"
                        *ngIf="charsLeft != null">{{charsLeft}} characters left</small>
                    <small *ngIf="textToEdit" class="d-block"
                        [ngClass]="{'text-primary': canEdit, 'text-danger': !canEdit}">{{canEditMessage}}</small>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div>
                <div *ngIf="!messageTimestamp" class="position-relative mr-3">
                    <ng-container *ngIf="!uploadImagePreview && files && files[0]">
                        <i class="far fa-paperclip mr-2"></i>
                        <span>{{files[0].filename}}</span>
                        <i (click)="clearFiles()" class="fas fa-times align-middle text-white hand-cursor ml-1"></i>
                    </ng-container>
                    <ng-container *ngIf="!fileToUpload && (!files || !files[0])">
                        <input type="file" (change)="importFile($event)" class="d-none" #file>
                        <button type="button" class="btn btn-secondary" title="Attach file"
                            (click)="file.value=null;file.click()">
                            <i class="far fa-paperclip"></i>
                            Attach file
                        </button>
                    </ng-container>
                    <ng-container *ngIf="fileToUpload && uploadImagePreview">
                        <div class="attach-container">
                            <img [src]="uploadImagePreview" class="img-thumb">
                            <div *ngIf="!files || !files[0]"
                                class="upload-percent text-white shadowed h-100 d-flex justify-content-center align-items-center">
                                {{uploadPercent+'%'}}</div>
                            <div class="img-loader" [ngStyle]="{'width': (100-uploadPercent)+'%'}"></div>
                        </div>
                        <i *ngIf="files && files[0]" (click)="clearFiles()"
                            class="fas fa-times text-white hand-cursor circle-icon clear-thumb"></i>
                    </ng-container>
                    <ng-container *ngIf="fileToUpload && !uploadImagePreview  && (!files || !files[0])">
                        <div class="attach-container">
                            <div class="loading-bar">
                                <div class="upload-percent text-center text-white shadowed">{{uploadPercent+'%'}}</div>
                            </div>
                            <div class="img-loader" [ngStyle]="{'width': (100-uploadPercent)+'%'}"></div>
                        </div>
                    </ng-container>
                </div>
            </div>

            <div class="btn-group mr-3">
                <button type="submit" class="btn btn-success" (click)="send()"
                    [disabled]="(fileToUpload && uploading) || (charsLeft!==null && charsLeft<0) || (messageTimestamp && !canEdit)">Publish</button>
                <div *ngIf="!messageTimestamp && isTeamChatroom && !isInReplyMode" class="btn-group" ngbDropdown
                    [placement]="['bottom-right','top-right']">
                    <button class="btn btn-success dropdown-toggle-split" ngbDropdownToggle
                        (click)="$event.preventDefault()"
                        [disabled]="(charsLeft!==null && charsLeft<0) || (messageTimestamp && !canEdit)"></button>
                    <div class="dropdown-menu p-0" ngbDropdownMenu style="width: 200px">
                        <div *ngFor="let chatroom of chatrooms; let i = index" class="px-3 py-2">
                            <div class="custom-control custom-checkbox" formArrayName="chatrooms">
                                <input [formControlName]="i" type="checkbox" class="custom-control-input"
                                    id="signal_room_{{i}}">
                                <label class="custom-control-label"
                                    for="signal_room_{{i}}">#{{chatrooms[i].label}}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>