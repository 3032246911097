<div class="discover-modal">
    <div class="modal-header">
        <h4 class="modal-title">Communities Directory</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body modal-teams">
        <div class="form-group">
            <div class="input-group has-search">
                <input #search type="text" class="form-control bg-dark" ngbAutofocus
                    [formControl]="searchField" placeholder="Enter a keyword" autofocus
                    (keydown.enter)="discoverTeams()">
                <i class="fal fa-search form-control-icon hand-cursor" (click)="discoverTeams()"></i>
            </div>
        </div>
        <div class="result-box overflow-auto mr-2">
            <app-team-info *ngFor="let featured of featuredTeams; trackByFn" [team]="featured" [listItem]="true"
                class="clickable z-100 mt-2" [featured]="true" (click)="checkOutTeam(featured.name);">
            </app-team-info>
            <div class="team-results">
                <ng-container *ngIf="teams && teams.length">
                    <div *ngFor="let team of teams; let i=index; trackByFn" class="mt-3">
                        <app-team-info [team]="team" [listItem]="true" class="clickable z-100"
                            (click)="checkOutTeam(team.name);">
                        </app-team-info>
                    </div>
                </ng-container>
                <div *ngIf="!teams || !teams.length"
                    class="d-flex align-items-center justify-content-center search-help z-0">
                    <p class="text-center">Search through the Communities Directory<br>to discover communities that
                        match your<br>interests and preferences.</p>
                </div>
            </div>
        </div>
        <!-- <div *ngIf="isAdmin" class="text-muted">
            <small><i class="far fa-info-circle"></i> Remove Team Discovery & Creation from your users' workspace with
                the Business or Enterprise <a href="https://www.echofin.com/plans/?utm_source=team-discovery"
                    target="_blank">plan</a></small>
        </div> -->
    </div>
</div>