import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ProfileRegisterServiceApi } from '@echofin/libraries';
import { BrandingService } from '../../_core/services/branding.service';

@Component({
  selector: 'app-request-email',
  templateUrl: './request-email.component.html',
  styleUrls: ['./request-email.component.scss']
})
export class RequestEmailComponent implements OnInit {
  loading: boolean;
  email = new FormControl('', [Validators.required, Validators.email]);
  status: string = 'start';
  err: string;

  token: string;

  colorCode: string;
  contrastColor: string;

  get isEchofin() {
    return this.brandingService.team.name === 'app';
  }

  get teamName() {
    if (this.isEchofin) {
      return 'Ensemble';
    }
    if (this.brandingService.team.label) {
      return this.brandingService.team.label;
    }
    return this.brandingService.team.name;
  }

  constructor(
    private brandingService: BrandingService,
    private profileServiceApi: ProfileRegisterServiceApi
  ) { }

  ngOnInit() {
    this.colorCode = this.getColorCode();
    this.contrastColor = this.getContrastYIQ(this.colorCode);
  }

  get team() {
    return this.brandingService.team;
  }

  async requestEmail() {
    if (this.email.valid) {
      this.loading = true;

      this.profileServiceApi.RequestRegisterEmail(
        {
          email: this.email.value,
          joinTeam: this.brandingService.team.name
        }).toPromise()
        .then((res) => {
          this.status = 'success';
          this.loading = false;
        })
        .catch((resp) => {
          if (!resp.error) {
            this.status = 'fail-generic';
          }
          if (resp.error === 'LINK_ALREADY_USED') {
            this.status = 'LINK_ALREADY_USED';
          }
          this.err = resp.message;
          this.loading = false;
        });
    }
  }

  getColorCode() {
    if (this.brandingService.team &&
      this.brandingService.team.config &&
      this.brandingService.team.config.branding &&
      this.brandingService.team.config.branding.colorCode) {
      return this.brandingService.team.config.branding.colorCode;
    }
    return '#f99500';
  }

  getContrastYIQ(hexcolor) {
    const hc = hexcolor.replace('#', '');
    const r = parseInt(hc.substr(0, 2), 16);
    const g = parseInt(hc.substr(2, 2), 16);
    const b = parseInt(hc.substr(4, 2), 16);
    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 136) ? 'black' : 'white';
  }
}
