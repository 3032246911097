<div class="front">

  <a id="reaction" href="javascript:void(0)" (click)="addReaction($event)" class="reaction" title="Add a reaction">
    <i class="fas fa-smile-plus fa-md"></i>
  </a>

  <a href="javascript:void(0)" id="quote" (click)="quote()" *ngIf="canQuote" class="quote" title="Reply">
    <i class="fas fa-quote-right"></i>
  </a>

  <!-- SHARE -->
  <!-- <a id="share" href="javascript:void(0)" (click)="shareMessage()" class="share">
    <i class="fas fa-sign-out fa-rotate-270"></i>
  </a> -->

  <!-- REPLY -->
  <!-- 
  <a href="javascript:void(0)" id="reply" (click)="reply()" *ngIf="canReply" title="Reply to thread">
    <fa-icon [icon]="['fas','reply']"></fa-icon>
  </a> -->

  <!-- BOOKMARK -->
  <!-- <a href="javascript:void(0)" id="addBookmark" (click)="addBookmarkMessage()" *ngIf="!bookmarked"
    ngbTooltip="Add to Bookmarks" container="body">
    <fa-icon [icon]="['fal','bookmark']"></fa-icon>
  </a>
  <a href="javascript:void(0)" id="removeBookmark" (click)="removeBookmarkMessage()" *ngIf="bookmarked"
    ngbTooltip="Remove from Bookmarks" container="body">
    <fa-icon [icon]="['fas','bookmark']"></fa-icon>
  </a> -->

  <a id="extra-menu" [ngbPopover]="menuModal" #menuPopover="ngbPopover" href="javascript:void(0)" container="body"
    [placement]="['bottom-right', 'top-right']" popoverClass="message-edit-menu" (shown)="clickMenuShown(menuPopover)"
    autoClose="false" class="extra-menu">
    <i class="fal fa-ellipsis-v fa-lg"></i>
  </a>
</div>

<ng-template #menuModal>
  <div class="msg-menu-container" (mouseenter)="beginHovering()" (mouseleave)="stopHovering()">

    <a id="edit-message" href="javascript:void(0)" (click)="clickMenuHoveringInterrupted();edit()" *ngIf="canEdit"
      title="Edit message">
      <div class="d-inline-block msg-menu-icon-container">
        <i class="fas fa-pencil"></i>
      </div>Edit
    </a>

    <!-- REPLY -->
    <a href="javascript:void(0)" id="reply" *ngIf="canReply" (click)="clickMenuHoveringInterrupted();reply()"
      title="Start a thread here">
      <div class="d-inline-block msg-menu-icon-container">
        <i class="fas fa-code-branch"></i>
      </div>Start Thread
    </a>

    <!-- BOOKMARK -->
    <a href="javascript:void(0)" id="addBookmark" *ngIf="canBookmark && !bookmarked"
      (click)="clickMenuHoveringInterrupted();addBookmarkMessage()" title="Add to personal Bookmarks">
      <div class="d-inline-block msg-menu-icon-container">
        <i class="fas fa-bookmark"></i>
      </div>Bookmark
    </a>
    <a href="javascript:void(0)" id="removeBookmark" *ngIf="canBookmark && bookmarked"
      (click)="clickMenuHoveringInterrupted();removeBookmarkMessage()" title="Remove from personal Bookmarks">
      <div class="d-inline-block msg-menu-icon-container">
        <i class="fas fa-bookmark"></i>
      </div>Unbookmark
    </a>

    <!-- PINBOARD -->
    <a href="javascript:void(0)" id="addToPinboard" *ngIf="!pinned && canPinMessage()"
      (click)="clickMenuHoveringInterrupted();addPinboardMessage()" title="Add to Team Pinboard">
      <div class="d-inline-block msg-menu-icon-container">
        <i class="fas fa-thumbtack"></i>
      </div>Pinboard
    </a>
    <a href="javascript:void(0)" id="removeFromPinboard" *ngIf="pinned && canPinMessage()"
      (click)="clickMenuHoveringInterrupted();removePinboardMessage()" title="Remove from Team Pinboard">
      <div class="d-inline-block msg-menu-icon-container">
        <i class="fas fa-thumbtack"></i>
      </div>Unpin
    </a>

    <!-- SHARE -->
    <!-- <a id="share" href="javascript:void(0)" (click)="shareMessage()" title="Share message">
      <fa-icon [icon]="['fas','share']"></fa-icon>Share
    </a> -->

    <!-- REPORT -->
    <a href="javascript:void(0)" id="reportMessage" (click)="clickMenuHoveringInterrupted();reportMessage()"
      title="Report Message as inappropriate">
      <div class="d-inline-block msg-menu-icon-container">
        <i class="fas fa-flag"></i>
      </div>Report
    </a>

    <!-- DELETE -->
    <a id="delete-message" href="javascript:void(0)" *ngIf="canDeleteMessage()"
      (click)="clickMenuHoveringInterrupted();delete()" title="Delete message">
      <div class="d-inline-block msg-menu-icon-container">
        <i class="fas fa-trash"></i>
      </div>Delete
    </a>
  </div>
</ng-template>