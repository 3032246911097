import { Injectable, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { PanelsService } from '../../../_core/services/panels.service';
import { ProfileService } from '../../../_core/services/profile.service';
import { RoomService } from '../../../_core/services/room.service';
import { TeamService } from '../../../_core/services/team.service';
import { Chatroom } from '../../../_shared/models/room/room';
import { Message, MessageStatus, LocalMessageProcessed } from './../../../_shared/models/commons/message';
import { Team } from '../../../_shared/models/teams/team';
import { ChatroomResp } from '@echofin/libraries/api/team/models/chatroom-resp';
import { SocketService } from '../../../_core/services/socket.service/socket.service';
import { EvType } from '../../../_core/services/socket.service/models/all.models';
import { Events, TeamTeamsServiceApi } from '@echofin/libraries';
import { PinboardMessagesService } from '../../panel-pinboard-messages/services/pinboard-messages.service';
import { SocketStatus } from '../../../_core/services/socket.service/models/socket-status';

@Injectable({
  providedIn: 'root'
})
export class MessageCountService implements OnDestroy {
  subs = new Subscription();

  chatroomMessageCounterChanged$: Subject<Chatroom> = new Subject<ChatroomResp>();
  chatroomLastTimestampChanged$: Subject<{ chatroomId: string, timestamp: Date }> = new Subject<{ chatroomId: string, timestamp: Date }>();
  teamMessageCounterChanged$: Subject<Team> = new Subject<Team>();
  pinboardMessageCounterChanged$: Subject<Team> = new Subject<Team>();
  procEvents = Events.ProcessedTypesEnum;

  eventsToEmit: any[] = [];

  constructor(
    private panelsService: PanelsService,
    private teamService: TeamService,
    private profileService: ProfileService,
    private roomService: RoomService,
    private socketService: SocketService,
    private teamApi: TeamTeamsServiceApi,
    private pinboardMessagesService: PinboardMessagesService
  ) {
    this.listenToEvents();
  }

  listenToEvents() {
    // this.log('listen to events');
    this.subs.add(this.socketService // listen from socket
      .getStream(EvType.MessageProcessed)
      .subscribe((m: LocalMessageProcessed) => {
        // this.log('MessageProcessed', m);
        if (m.type.toString() !== this.procEvents[this.procEvents.NEW]) { // only if it's a new message
          return;
        }
        const message = m.message;
        // check to increase
        this.checkToIncrease({
          ...message,
          status: MessageStatus.DELIVERED,
          timestamp: new Date(message.timestamp)
        });
      }));

    this.subs.add(this.socketService
      .getStream(EvType.ChatroomFocusChanged)
      .subscribe((data: Events.ChatroomFocusChanged) => {
        data.chatrooms.forEach(chatroom => {
          // this.log('ChatroomFocusChanged', chatroom);
          if (chatroom.focused) {
            this.setRoomReaded(chatroom.id);
          } else {
            this.updateUnreadMessagesTimestamp(chatroom.id, data.timestamp);
          }
        });
      }));

    this.subs.add(this.socketService.status$.subscribe(s => {
      if (s === SocketStatus.Connected) {
        while (this.eventsToEmit.length) {
          this.socketService.focus(this.eventsToEmit[0]);
          this.eventsToEmit.splice(0, 1);
        }
      }
    }));

    this.subs.add(this.pinboardMessagesService.pinboardMessageAdded$.subscribe(pin => {
      if (this.teamService.activeTeam.id === pin.teamId) {
        this.teamService.activeTeam.pinboardUnread += 1;
        this.pinboardMessageCounterChanged$.next(this.teamService.activeTeam);
      }
    }));

    this.subs.add(this.roomService.teamRoomRemoved$.subscribe((r: Chatroom) => {
      this.recalculateTeamUnread(r.teamId);
    }));

    this.subs.add(this.teamService.teamRoomPermUpdated$.subscribe((r: Chatroom) => {
      this.recalculateTeamUnread(r.teamId);
    }));

    this.subs.add(this.teamService.memberRoleChanged$.subscribe((r: Chatroom) => {
      this.recalculateTeamUnread(r.teamId);
    }));

    this.subs.add(this.roomService.teamRoomUpdated$.subscribe((r: Chatroom) => {
      this.recalculateTeamUnread(r.teamId);
    }));
  }

  sendFocusChange(data: any) {
    if (this.socketService.status$.value === SocketStatus.Connected) {
      this.socketService.focus(data);
    } else {
      this.eventsToEmit.push(data);
    }
  }

  setRoomReaded(chatroomId: string) {
    const room = this.teamService.getRoom(chatroomId);
    if (room !== null) {
      room.unread = 0;
      this.chatroomMessageCounterChanged$.next(room);
      this.recalculateTeamUnread(room.teamId);
      // this.log('channel unread message zeroed', room.unread);
      return;
    }
    const droom = this.roomService.directs.find(d => d.id === chatroomId);
    if (typeof (droom) !== 'undefined' && droom !== null) {
      droom.unread = 0;
      this.chatroomMessageCounterChanged$.next(droom);
    }
  }

  updateUnreadMessagesTimestamp(chatroomId: string, timestamp: Date) {
    this.chatroomLastTimestampChanged$.next({ chatroomId, timestamp });
  }

  recalculateTeamUnread(teamId: string) {
    const team = this.teamService.getTeam(teamId);
    if (team !== null) {
      const oldunread = team.unread;
      team.unread = team.chatrooms.map(c => c.unread).reduce((accumulator, currentValue) => accumulator + (currentValue || 0), 0);
      if (oldunread !== team.unread) {
        // this.log('teamMessageCounterChanged', oldunread, team.unread);
        this.teamMessageCounterChanged$.next(team);
      }
    }
  }

  checkToIncrease(message: Message) {
    // this.log('checkToIncrease', message);

    if (message.sender.id === this.profileService.me.id) {
      // if is the same user don't increase the counter
      return;
    }

    const panel = this.panelsService.panels.find(oi => oi.id === message.chatroom.id);

    if (typeof panel === 'undefined') {
      // this.log('Panel not open');
      // channel panel is not opened
      this.increaseMessageCount(message.chatroom.teamId, message.chatroom.id);
      return;
    }

    if (panel.id !== this.panelsService.focusedItem) {
      // this.log('Panel opened but not in focus');
      this.increaseMessageCount(message.chatroom.teamId, message.chatroom.id);
      return;
    }
  }

  getRoomFromId(roomId: string) {
    let room = this.getProfileRoom(roomId);
    if (room === null) {
      room = this.teamService.getRoom(roomId);
    }
    return room;
  }

  getRoom(roomId: string) {
    const room = this.getProfileRoom(roomId);

    if (room) return room;
    return this.teamService.getRoom(roomId);
  }

  increaseMessageCount(teamId: string, chatroomId: string) {
    // this.log('increaseMessageCount', chatroomId);
    const room = this.teamService.getRoom(chatroomId);
    if (room !== null) {
      room.unread += 1;
      this.chatroomMessageCounterChanged$.next(room);
    }
    if (this.roomService.directs) {
      const droom = this.roomService.directs.find(d => d.id === chatroomId);
      if (typeof (droom) !== 'undefined' && droom !== null) {
        droom.unread += 1;
        this.chatroomMessageCounterChanged$.next(droom);
      }

    }

    if (teamId !== null) {
      const team = this.teamService.getTeam(teamId);
      if (typeof (team) !== 'undefined' && team !== null) {
        if (isNaN(team.unread)) {
          team.unread = 0;
        }
        team.unread += 1;
        this.teamMessageCounterChanged$.next(team);
      }
    }

  }

  convertMessageCountForView(unreadCount: number): string {
    const unread: number = unreadCount || 0; // we accept null value as 0
    if (unread > 99) {
      return '99+';
    }

    if (unread === 0) {
      return null;
    }

    return unread.toString();

  }

  async setPinboardZero() {
    await this.teamApi.SetPinboardLastRead(this.teamService.activeTeam.id).toPromise();
    this.teamService.activeTeam.pinboardUnread = 0;
    this.pinboardMessageCounterChanged$.next(this.teamService.activeTeam);
  }

  private getProfileRoom(roomId: string) {
    return this.roomService.directs.find(d => d.id === roomId);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  log(...arg) {
    /* istanbul ignore if  */
    if (environment.config.debug) {
      console.log('%c[MESSAGE COUNT SERVICE]', 'color:#DF9800', ...arg);
    }
  }
}
