import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TeamService } from './team.service';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class TitleHandlerService extends BaseService {

  panelId: string;

  constructor(
    private titleService: Title,
    private teamService: TeamService
  ) {
    super();
    this.titleService.setTitle(this.teamService.activeTeam ? this.teamService.activeTeam.name : 'Ensemble');
    this.changeFavicon();
    this.teamService.activeTeamId$.subscribe(tid => {
      this.titleService.setTitle(this.teamService.activeTeam ? this.teamService.activeTeam.name : 'Ensemble');
      this.changeFavicon();
    });
  }

  setTitle(newTitle: string, panelId: string) {
    this.panelId = panelId;
    this.titleService.setTitle(newTitle);
    this.changeFavicon();
  }

  changeFavicon() {
    const link = document.querySelectorAll("link[rel~='icon']");

    if (!link) {
      let icon: HTMLLinkElement;
      icon = document.createElement('link');
      icon.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(icon);
    } else {
      let teamLogo = this.teamService.activeTeam.logo;
      if (!teamLogo || teamLogo === '') {
        teamLogo = 'https://dashboard.ensemble.chat/assets/icons/favicon-96x96.png';
      }
      // tslint:disable-next-line:ter-prefer-arrow-callback
      link.forEach(function (icon: HTMLLinkElement) {
        icon.href = teamLogo;
      });
    }
  }

  removeTitle(panelId: string) {
    if (this.panelId === panelId) {
      this.titleService.setTitle(this.teamService.activeTeam ? this.teamService.activeTeam.name : 'Ensemble');
    }
  }
}
