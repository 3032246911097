import { Participant } from './participant';

export enum ItemType {
  Divider,
  Channel,
  Frame
}

export class Channel {
  constructor(participants?: Participant[]) {
    if (participants && participants.length) {
      if (participants.length === 1) {
        this.recipientUserId = participants[0].user.id;
      }
      this.participants = [...participants];
    }
  }

  id?: string;
  itemType: string = ItemType[ItemType.Channel];
  name?: string;
  teamId?: string;
  loadingMessages?: boolean;
  sort?: number;
  unread?: number;
  color?: string;
  participants?: Participant[];
  type?: string;
  topic?: string;
  isReadOnly?: boolean;
  isLocked?: boolean;
  description?: string;

  lastActive?: Date;
  lastSeen?: Date;

  recipientUserId?: string;
  localOnly?: boolean;

  notifications?: boolean;

  canSend?: boolean;
  canRead?: boolean;

  static fromProfileChannelApi(profileChannelApi: ProfileChannelApi, itemType: string): Channel {
    const channel = new Channel();

    channel.color = profileChannelApi.color;
    channel.description = profileChannelApi.description;
    channel.id = profileChannelApi.id;
    channel.lastActive = new Date(profileChannelApi.lastActive);
    channel.lastSeen = new Date(profileChannelApi.lastSeen);
    // if (profileChannelApi.members) {
    //   channel.participants = profileChannelApi.members
    //     .map(memberApi => Member.fromMemberApi(memberApi));
    // }
    channel.name = profileChannelApi.name;
    channel.topic = profileChannelApi.topic;
    channel.type = profileChannelApi.type;
    channel.unread = +profileChannelApi.unread;
    channel.itemType = itemType;
    channel.canSend = profileChannelApi.canSend;
    channel.canRead = profileChannelApi.canRead;
    channel.participants = profileChannelApi.participants;

    return channel;
  }
}

export enum PanelType {
  Chatroom = 'Chatroom',
  Frame = 'Frame',
  Mentions = 'Mentions',
  Files = 'Files',
  Filter = 'Filter',
  PinboardMessages = 'PinboardMessages',
  BookmarkMessages = 'BookmarkMessages',
  Search = 'Search',
  Direct = 'Direct',
  ReplyMessage = 'ReplyMessage',
  PulseMessages = 'PulseMessages',
  SignalMessages = 'SignalMessages',
}

export interface PanelMessageOrder {
  id?: string;
  order: 'MessageDate' | 'CreatedDate';
}

export interface Panel {
  id?: string;
  // name?: string;
  type: PanelType;
  // icon?: string[];
  teamId?: string;
  // color?: string;
  sort?: number;
  visible?: boolean; // user action
  isHidden?: boolean; // this is calculated real time
  hasLoaded?: boolean; // if not loaded, on panel-chatroom panel visibility change load messages and make true else do nothing
}

export interface ChannelApi {
  id: string;
  itemType: string;
  teamId: string;
  name: string;
  type: string;
  description: string;
  topic: string;
  color: string;
  lastActive: Date;
  lastSeen: Date;
  unread?: number;
  isReadonly?: boolean;
  isLocked?: boolean;
}

export interface ProfileChannelApi {
  color: string;
  description: string;
  id: string;
  lastActive: string;
  lastSeen: string;
  // members: MemberApi[];
  name: string;
  topic: string;
  type: string;
  unread: string;
  canSend?: boolean;
  canRead?: boolean;
  participants?: Participant[];
}

export interface DividerApi {
  id: string;
  itemType: string;
  name: string;
}
