<div class="discover-modal">
    <div class="modal-header">
        <h4 class="modal-title">Giphy Search</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body modal-teams">
        <div class="form-group">
            <div class="input-group has-search">
                <input #search type="text" class="form-control" ngbAutofocus
                    [formControl]="searchField" placeholder="Enter a keyword" autofocus
                    (keydown.enter)="searchGiphy()">
                <button class="form-control-icon clickable" (click)="searchGiphy()">
                    <i class="fa fa-search"></i>
                </button>
            </div>
        </div>
        <div class="result-box overflow-auto mr-2">
            <div class="result-wrap">
                <ng-container *ngIf="gifs && gifs.length">
                    <div class="team-results">
                        <figure  *ngFor="let gif of gifs; let i=index; trackByFn" (click)="appendGif(gif.id)">
                            <img
                            src="{{gif.images.preview_gif.url}}" 
                            class="gif-preview"  /> 
                        </figure>
                    </div>
                    <button *ngIf="gifs && gifs.length>=15 && searchTerm && showLoadMore" class="btn btn-sm mt-2 btn-success btn-block" (click)="loadMore()">Load more</button>
                </ng-container>
                <div *ngIf="!gifs || !gifs.length"
                    class="d-flex align-items-center justify-content-center search-help z-0">
                    <p class="text-center">No results found. Try a different search to find an animated gif on Giphy!</p>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div id="giphy-powered"></div>
    </div>
</div>