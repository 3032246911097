<ng-container [ngSwitch]="mimeCategory">
  <ng-container *ngSwitchCase="mimeCategories.IMAGE">
    <div *ngIf="attachment['thumb']" class="file-preview-container url-preview">
      <a *ngIf="!hideFilename" target="_blank" [href]="attachment.url" class="d-block">
        <!-- <div class="">
          <small class="link_url">{{attachment.filename}}</small>
        </div> -->
      </a>
      <app-lightbox [src]="attachment.url" [thumb]="attachment['thumb']" [filename]="attachment.filename"
        [fullView]="fullView" [messageId]="messageId" [type]="'file'">
      </app-lightbox>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="mimeCategories.PDF">
    <app-lightbox [src]="attachment.url" [filename]="attachment.filename" [mime]="attachment.mime" [fullView]="fullView"
      [messageId]="messageId" [type]="'file'">
    </app-lightbox>
  </ng-container>
  <ng-container *ngSwitchCase="mimeCategories.VIDEO">
    <a class="file" [href]="attachment.url" target="_blank">
      <div class="file-icon">
        <video controls>
          <source [src]="attachment.url" />
        </video>
      </div>
      <div class="file-body text-truncate"><small>{{attachment.filename}}</small></div>
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="mimeCategories.AUDIO">
    <a class="file" [href]="attachment.url" target="_blank">
      <div class="file-icon">
        <audio controls>
          <source [src]="attachment.url" />
          <div class="file-body text-truncate"><small>{{attachment.filename}}
              <i class="fas fa-external-link-alt"></i></small></div>
        </audio>
      </div>
    </a>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <a class="file-block" [href]="attachment.url" target="_blank">
      <span class="file-icon">
        <i class="fad fa-2x" [ngClass]="icon"></i>
      </span>
      <span class="file-body text-truncate"><small>{{attachment.filename}}</small></span>
    </a>
  </ng-container>
</ng-container>