import { Component, Input,  ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GiphyFetch } from '@giphy/js-fetch-api';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BaseComponent } from '../base-component';
import { TrackingService } from '../../_core/services/tracking.service';

@Component({
  selector: 'app-giphy',
  templateUrl: './giphy.component.html',
  styleUrls: ['./giphy.component.scss'],
})
export class GiphyComponent extends BaseComponent implements OnInit, OnDestroy {

  giphy = new GiphyFetch('e7It5crfKVXi9QxaylcM0wSnpWGf0Teb');
  @ViewChild('search') searchInput: ElementRef;

  loading = false;
  searchedAlready = false;
  errorMessage = '';

  searchTerm: string = null;

  searchField: FormControl = new FormControl();
  gifs: any[];
  curPage: number = 0;
  showLoadMore: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private tracking: TrackingService
  ) {
    super();
  }

  async ngOnInit() {
    if (this.searchTerm) {
      this.searchField.patchValue(this.searchTerm);
    } else {
      const { data: gifs } = await this.giphy.trending({ limit: 15 });
      this.gifs = gifs;
      // gifs.forEach((el) => {
      //   console.log(el);
      // });
    }
    this.inputFocus();
  }

  inputFocus() {
    setTimeout(
      () => {
        if (this.searchInput && this.searchInput.nativeElement) {
          this.searchInput.nativeElement.focus();
        }
      },
      110);
  }

  async searchGiphy() {
    this.searchTerm = this.searchField.value;
    if (this.searchTerm.length > 1) {
      const { data: gifs } = await this.giphy.search(this.searchTerm, { sort: 'relevant', limit: 15, offset: (this.curPage * 15) });
      this.gifs = gifs;
      this.showLoadMore = true;
    }
  }

  loadMore() {
    this.curPage += 1;
    this.searchField.patchValue(this.searchTerm);
    this.searchGiphy();
    console.log(this.curPage);
  }

  appendGif(item) {
    this.gifs.forEach((el) => {
      if (el.id === item) {
        const text = `${el.images.fixed_height.url}`;
        // const file = [{
        //   filename: el.title,
        //   mime: 'image/gif',
        //   url: el.images.original.url,
        //   handle: el.id,
        // }];
        this.activeModal.close({ text, files: null });
      }
    });
  }

  trackByFn(index, item) {
    return item.id;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
