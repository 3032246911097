import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TeamTeamsServiceApi } from '@echofin/libraries';
import { NgbActiveModal, NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, skipWhile, take } from 'rxjs/operators';
import { FileUploadService } from '../../_core/services/file-upload.service';
import { TeamService } from '../../_core/services/team.service';
import { ProfileService } from '../../_core/services/profile.service';
import { Md5 } from 'md5-typescript';
@Component({
  selector: 'app-create-team',
  templateUrl: './create-team.component.html',
  styleUrls: ['./create-team.component.scss']
})
export class CreateTeamComponent implements OnInit {

  loading = false;
  pickerLoading = false;
  filestackUrlParameters: string = '';

  teamForm: FormGroup;

  newTeamName: string;
  failedName: string;
  status: string;
  err: string;

  invitationIsValid = true;
  invitation = new FormControl('', Validators.required);
  soloInvitation = '';


  possibleTags = [
    'sports', 'music', 'influencer', 'celeb', 'athlete', 'hobbies', 'business', 'corporate', 'clubs', 'academy', 'school', 'gym', 'band', 'singer',
    'actor', 'crypto', 'financial', 'dancer', 'photographer', 'youtuber', 'media', 'books', 'private', 'health', 'musician', 'family', 'fun', 'tik toker',
    'blog', 'writer', 'author', 'funny', 'personal', 'clan', 'gaming', 'history', 'science', 'basketball', 'football', 'tennis', 'soccer', 'rugby',
    'brand', 'university', 'sororities', 'fraternities',
    'pets', 'knitting', 'cats', 'dogs', 'swimming', 'scuba', 'coding', 'developers', 'IT', 'radio',
    'television', 'fantasy', 'art', 'nft', 'anime', 'comics'
  ].sort();
  // public requestAutocompleteItems = (text: string): Observable<string[]> => {
  //   return of(this.possibleTags.filter(tag => tag.indexOf(text) > -1));
  // };
  tagsValue: string[] = [];

  @ViewChild('typeahead') typeahead: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  constructor(
    private profileService: ProfileService,
    public activeModal: NgbActiveModal,
    private teamService: TeamService,
    private router: Router,
    private fileUploadService: FileUploadService,
    private teamApi: TeamTeamsServiceApi
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.teamForm = new FormGroup({
      Name: new FormControl(null, [
        Validators.required,
        Validators.maxLength(62),
        Validators.minLength(2),
        Validators.pattern(/^[a-z-_0-9]+$/)
      ]),
      Description: new FormControl(null, [
        Validators.maxLength(250)
      ]),
      Logo: new FormControl(),
      profileTags: new FormControl(),
      invitation: new FormControl('', Validators.required)
    });

    // Generate a user invitation. Currently 1 invitation per user
    this.soloInvitation = Md5.init(`${this.profileService.me.email}1`).substring(0, 6);
    this.teamForm.controls.invitation.setValue(this.soloInvitation);

    // this.teamForm.controls.invitation.disable({ onlySelf: true, emitEvent: false });
  }

  async verifyInvitation() {
    if (this.invitation.valid) {
      this.loading = true;

      const isValid = await this.teamApi.Verify({ code: this.invitation.value }).toPromise()
        .catch(err => Promise.reject(err));
      if (isValid) {
        this.teamForm.controls.invitation.patchValue(this.invitation.value);
      }
      this.invitationIsValid = isValid;

      this.loading = false;
    } else {
      this.invitation.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    }
  }

  async changeLogo() {
    this.pickerLoading = true;
    const file = await this.fileUploadService.pickLogo()
    if (file.length) {
      this.teamForm.controls.Logo.patchValue(file[0].url);
      const policy = this.fileUploadService.policy;
      const signature = this.fileUploadService.signature;
      this.filestackUrlParameters = `?policy=${policy}&signature=${signature}`;
    }
    this.pickerLoading = false;
  }

  async doCreateTeam() {
    if (this.teamForm.valid) {
      this.loading = true;

      const sub = this.teamService.teamAdded$
        .pipe(
          skipWhile(t => t.name !== this.teamForm.controls.Name.value),
          take(1))
        .subscribe(async t => {
          this.activeModal.dismiss();
          await this.teamService.switchTeam(t.id);
          this.router.navigateByUrl(`/${t.name}`);
        });

      await this.teamService.createTeam(
        {
          name: this.teamForm.controls.Name.value,
          profileTags: this.teamForm.controls.profileTags.value,
          logo: this.teamForm.controls.Logo.value,
          description: this.teamForm.controls.Description.value
        },
        this.teamForm.controls.invitation.value)
        .catch((err) => {
          if (err.error.code === 'TEAM_EXISTS') {
            this.failedName = this.teamForm.controls.Name.value;
            this.status = 'fail-in-use';
          } else if (err.error.code === 'TEAM_RESERVED') {
            this.failedName = this.teamForm.controls.Name.value;
            this.status = 'fail-reserved';
          } else if (err.error && err.error.name && err.error.name.length > 0) { // error in name field
            this.status = 'fail-generic';
            this.err = err.error.name[0];
          } else {
            this.status = 'fail-generic';
            this.err = err.error.message;
          }
          sub.unsubscribe();
          this.loading = false;
        });
    }
  }

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(50), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.typeahead.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.possibleTags.filter(v => this.tagsValue.indexOf(v.toLowerCase()) === -1).splice(0, 12)
        : this.possibleTags.filter(v => this.tagsValue.indexOf(v.toLowerCase()) === -1 && v.toLowerCase().indexOf(term.toLowerCase()) > -1).splice(0, 12)))
    );
  }

  onSelectItem($event: NgbTypeaheadSelectItemEvent) {
    this.typeahead.dismissPopup();
    this.tagsValue.push($event.item);
    this.teamForm.controls.profileTags.patchValue(this.tagsValue.join(','));
  }

  removeTag(tag) {
    this.tagsValue.splice(this.tagsValue.indexOf(tag), 1);
    this.teamForm.controls.profileTags.patchValue(this.tagsValue.join(','));
  }

  clearInput(item: any) {
    return '';
  }
}
