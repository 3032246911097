<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">
      Notification Settings
    </h4>
    <button class="close" type="button" data-dismiss="modal" aria-label="Close" (click)="dismiss()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div style="padding-left: 17px;color: #616161; white-space: nowrap; min-width: 260px; overflow: hidden; text-overflow: ellipsis;font-size: 0.9rem; font-weight: 500; letter-spacing: 0.2px;">
      <ng-container [ngSwitch]="room.type">
        <ng-container *ngSwitchCase="roomType.Team">
          #{{room.name}}
        </ng-container>
        <ng-container *ngSwitchCase="roomType.Group">
          {{room | directLabel:me.id}}
        </ng-container>
        <ng-container *ngSwitchCase="roomType.Direct">
          @{{room | directLabel:me.id}}
        </ng-container>
      </ng-container>
  </div>
  <div style="padding-left: 17px;color: #616161; white-space: nowrap; min-width: 260px; overflow: hidden; text-overflow: ellipsis;font-size: 0.8rem; padding-top:16px">
    Web and Desktop app
  </div>
  <div class="modal-body">
    <div class="form-group">
      <div>

        <div class="btn-group btn-block" *ngIf="notification">

          <button class="btn" (click)="toggle(1)"
            [ngClass]="{'btn-success':notification.all, 'btn-grey':!notification.all}">
            <span>All</span>
          </button>
          <button class="btn" (click)="toggle(2)"
            [ngClass]="{'btn-success':notification.mentions, 'btn-grey':!notification.mentions}" style="border-left: 1px solid #a5a5a5;">
            <span>Mentions Only</span>
          </button>
          <button class="btn" (click)="toggle(3)"
            [ngClass]="{'btn-success':notification.none, 'btn-grey':!notification.none}" style="border-left: 1px solid #a5a5a5;">
            <span>None</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="mobileNotification" style="padding-left: 17px;color: #616161; white-space: nowrap; min-width: 260px; overflow: hidden; text-overflow: ellipsis;font-size: 0.8rem; padding-top:5px">
    Mobile push notifications
  </div>
  <div class="modal-body">
    <div class="form-group">
      <div>

        <div class="btn-group btn-block" *ngIf="mobileNotification">

          <button class="btn" (click)="toggleMobile(1)"
            [ngClass]="{'btn-success':mobileNotification.all, 'btn-grey':!mobileNotification.all}">
            <span>All</span>
          </button>
          <button class="btn" (click)="toggleMobile(2)"
            [ngClass]="{'btn-success':mobileNotification.mentions, 'btn-grey':!mobileNotification.mentions}" style="border-left: 1px solid #a5a5a5;">
            <span>Mentions Only</span>
          </button>
          <button class="btn" (click)="toggleMobile(3)"
            [ngClass]="{'btn-success':mobileNotification.none, 'btn-grey':!mobileNotification.none}" style="border-left: 1px solid #a5a5a5;">
            <span>None</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>