<ng-container *ngIf="state==='DISCLAIMER'">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">Disclaimer</h4>
    </div>
    <div class="modal-body disclaimer-wrapper">
      <p style="white-space: pre-wrap; font-family: 'Poppins'; ">
        {{disclaimer}}</p>
    </div>

    <div class="modal-footer">
      <button class="btn btn-secondary" type="button" (click)="state = 'LEAVE'">Reject</button>
      <button class="btn btn-success" type="button" (click)="accept()">Accept</button>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="state==='LEAVE'">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">Leave team</h4>
    </div>
    <div class="modal-body text-danger text-center p-4 break-word">
      <strong>You are about to leave {{teamName}}. Are you sure?</strong>
    </div>
    <div class="modal-footer">
      <button class="btn btn-secondary" type="button" (click)="state = 'DISCLAIMER'">Back</button>
      <button class="btn btn-danger" type="button" (click)="confirmLeave()">Leave</button>
    </div>
  </div>
</ng-container>