<div class="personal-invite-modal">
    <div class="modal-header">
        <h4 class="modal-title">Personal Invitation Link</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body modal-teams">
        <div *ngIf="loading" class="text-center">
            <i class="fal fa-circle-notch fa-spin fa-5x text-primary"></i>
        </div>
        <div>
            <p>
                Invite your friends to WallStreetBets Pro and earn cash to buy more stonks.
            </p>
            <p>
                For every new user who signs up through your invite link you will earn $1. Once you reach 25 users (ie.
                $25) please contact <span class="admin-name">@ZenTrader</span> to request your payout though PayPal.
            </p>
            <ng-container *ngIf="!invitation">
                <div class="p-3 text-center">
                    <button class="btn btn-success btn-sm" (click)="createPesonalInviteLink()">Generate Link</button>
                </div>
            </ng-container>
            <ng-container *ngIf="invitation">
                <div class="form-group">
                    <div class="input-group">
                        <input #invUrl type="text" class="form-control bg-dark" [ngModel]="inviteUrl"
                            (keydown)="preventChange()" (click)="copyLink()">
                        <div class="input-group-append">
                            <button class="btn btn-primary" type="button" (click)="copyLink()">Copy Link</button>
                        </div>
                        <div class="borderfx"></div>
                    </div>
                </div>
                <div class="earnings">
                    Current earnings: ${{(invitation.users && invitation.users.length)?invitation.users.length:'0'}}
                </div>
            </ng-container>
        </div>
    </div>
</div>