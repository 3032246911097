import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ProfileProfilesServiceApi } from '@echofin/libraries';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Helpers } from 'app/_shared/helpers/helpers';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ModalsService } from '../../_core/services/modals.service';
import { TeamService } from '../../_core/services/team.service';
import { Chatroom } from '../../_shared/models/room/room';
import { DownloadClientsComponent } from '../download-clients/download-clients.component';
import { ProfileService } from './../../_core/services/profile.service';

@Component({
  selector: 'app-stream',
  templateUrl: './stream.component.html'
})
export class StreamComponent implements OnInit, OnDestroy {

  @Input() room: Chatroom;
  @Input() participants: string;

  @Output() public callType: string;
  @Output() public subtitle: string;

  streamsUpdated: Subscription;

  create = false;

  settings = {
    desktop: true,
    camera: true,
    microphone: true,
  };

  state:
    'LOADING' |
    'UPGRADE' |
    'NO_ALLOWED' |
    'MEETING_START' |
    'OTHER_MEETING_ACTIVE' |
    'MEETING_JOIN' |
    'NO_MEETING' = 'LOADING';

  get m() {
    return this.activeModal;
  }

  get activeMeeting() {
    return this.teamService.meetings[this.room.teamId].find(m => m.chatroomId === this.room.id);
  }

  constructor(
    private teamService: TeamService,
    private activeModal: NgbActiveModal,
    private profileService: ProfileService,
    private http: HttpClient,
    private modalsService: ModalsService,
    private profileApi: ProfileProfilesServiceApi,
    private modal: NgbModal,
    private toastr: ToastrService
  ) { }

  async ngOnInit() {
    if (this.room && this.room.type === 'Team') {
      this.subtitle = `Meeting / #${this.room.name}`;
      this.callType = 'meeting';
    } else {
      this.subtitle = 'Call';
      if (this.participants) { this.subtitle = this.subtitle.concat(' with ', this.participants); }
      this.callType = 'call';
    }

    this.loadState();
  }

  loadState() {
    this.streamsUpdated = this.teamService
      .meetingsUpdated$
      .subscribe(() => {
        if (this.teamService.meetings[this.room.teamId].indexOf(this.room.id) === -1) {
          this.activeModal.close();
        }
      });

    if (!this.room.permissions['user'].read_messages) {
      this.state = 'NO_ALLOWED';
      return;
    }

    if (this.modalsService.openedModals.find(x => x.id === 'STREAM') != null) {
      this.state = 'OTHER_MEETING_ACTIVE';
      return;
    }

    if (this.activeMeeting) {
      this.state = 'MEETING_JOIN';
      return;
    }

    if (Helpers.isElectron() && !Helpers.isElectronLatest('1.1.0')) {
      this.state = 'UPGRADE';
      return;
    }

    if (this.room.permissions?.user?.start_meeting) {
      this.state = 'MEETING_START';
      return;
    }

    this.state = 'NO_MEETING';

  }

  ngOnDestroy(): void {
    if (this.streamsUpdated) {
      this.streamsUpdated.unsubscribe();
    }
  }

  openDownloadClient() {
    this.modal.open(DownloadClientsComponent, {
      centered: true,
      windowClass: 'modal-dark',
    });
  }

  async startMeeting() {

    this.state = 'LOADING';

    const TOOLBAR_BUTTONS = Object
      .keys(this.settings)
      .reduce((l, k) => {
        if (this.settings[k]) l.push(k);
        return l;
      }, []);

    const streamServerLocatorResp = await this.http
      .get<any>(`https://${environment.config.streamServerLocator}/static/server.json`)
      .toPromise()
      .catch(() => {
        return {};
      });

    const streamServer = streamServerLocatorResp?.streamServer;

    if (!streamServer) {
      this.toastr.error('Could not find meetings server');
      this.loadState();
      return;
    }

    const resp = await this.http
      .post<any>(`${environment.config.endpoints['stream']}/stream/meetings`, {
        userId: this.profileService.me.id,
        chatroomId: this.room.id,
        server: streamServer,
        config: JSON.stringify({ TOOLBAR_BUTTONS }),
      })
      .toPromise()
      .catch(() => { return null; });

    const config = await this.getConfig();

    this.modalsService.closeFloatModal({ id: 'STREAM' });
    this.modalsService.openFloatModal({
      id: 'STREAM',
      config: {
        minWidth: 392,
        minHeight: 220,
        width: 880,
        height: 494,
      },
      type: 'STREAM',
      title: `#${this.room?.name} Meeting - @${this.profileService.me.username}`,
      label: 'Meeting',
      teamId: 'GLOBAL',
      resizable: true,
      room: this.room,
      creator: this.profileService.me,
      settings: {
        roomId: this.room.id,
        teamId: this.room.teamId,
        server: streamServer,
        jwt: resp.jwt,
        config: config.moderator
      },
      hasAccess: true
    });

    this.activeModal.close();
  }

  async joinMeeting() {

    this.state = 'LOADING';

    const resp = await this.http
      .get<any>(`${environment.config.endpoints['stream']}/stream/meetings/${this.room.id}`)
      .toPromise()
      .catch(() => { return null; });

    const config = await this.getConfig();

    let userConfig = null;
    if (resp.moderator) {
      userConfig = config.moderator;
    } else {
      userConfig = config.participant;

      const respConfig = JSON.parse(resp.config);
      if (respConfig) {
        userConfig.interfaceConfigOverwrite.TOOLBAR_BUTTONS =
          userConfig.interfaceConfigOverwrite.TOOLBAR_BUTTONS.concat(respConfig.TOOLBAR_BUTTONS);
      }
    }

    const creator = await this.profileApi
      .GetProfile(this.activeMeeting.creatorId)
      .toPromise()
      .catch(() => {
        return { username: 'unknown' };
      });

    this.modalsService.closeFloatModal({ id: 'STREAM' });

    console.log('join stream with userConfig', userConfig);

    this.modalsService.openFloatModal({
      creator,
      id: 'STREAM',
      config: {
        minWidth: 392,
        minHeight: 220,
        width: 880,
        height: 494,
      },
      type: 'STREAM',
      title: `#${this.room?.name} Meeting - @${creator.username}`,
      label: 'Meeting',
      teamId: 'GLOBAL',
      resizable: true,
      room: this.room,
      settings: {
        roomId: this.room.id,
        teamId: this.room.teamId,
        server: resp.server,
        jwt: resp.jwt,
        config: userConfig
      },
      hasAccess: true
    });

    this.activeModal.close();
  }

  private getConfig() {
    return this.http
      .get<{ moderator: any, participant: any }>(`https://assets.echofin.com/config/stream.json?t=${Math.random()}`)
      .toPromise();
  }

  // joinCall(): void {
  //   /* For future use on 1-on-1 calls */
  //   document.querySelector('#meeting-welcome').className = 'hide';
  //   document.querySelector('#meet').innerHTML = ' ';
  //   document.querySelector('#meet').className = 'show';

  //   try {
  //     const options = {
  //       roomName: `${this.room.id}`,
  //       width: '100%',
  //       height: 360,
  //       parentNode: document.querySelector('#meet'),
  //       jwt: this.genToken(true),
  //       configOverwrite: {
  //         startVideoMuted: 2,
  //         startWithAudioMuted: false,
  //         prejoinPageEnabled: false,
  //         enableWelcomePage: false,
  //         disable1On1Mode: false
  //       },
  //       interfaceConfigOverwrite: {
  //         filmStripOnly: false,
  //         CONNECTION_INDICATOR_DISABLED: true,
  //         DISABLE_FOCUS_INDICATOR: true,
  //         DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
  //         DISABLE_PRESENCE_STATUS: true,
  //         TILE_VIEW_MAX_COLUMNS: 1,
  //         DEFAULT_LOCAL_DISPLAY_NAME: 'me',
  //         TOOLBAR_BUTTONS: [
  //           'microphone', 'camera', 'desktop', 'fullscreen',
  //           'hangup', 'tileview'
  //         ]
  //       }
  //     };

  //     const api = new JitsiMeetExternalAPI('jitsi.echodev.io', options);

  //     api.executeCommand('subject', 'Ongoing call');

  //     api.addEventListener('readyToClose', (e) => {
  //       console.log('Stream Closed');
  //       console.log(e);
  //       document.querySelector('#meet').className = 'hide';
  //       document.querySelector('#meeting-welcome').className = 'd-flex p-3 justify-space-evenly';
  //     });

  //   } catch (error) {
  //     console.error('Failed to load Jitsi API', error);
  //   }
  // }
}
