import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileService } from '../../_core/services/profile.service';
import { TeamService } from '../../_core/services/team.service';

@Component({
  selector: 'app-walkthrough',
  templateUrl: './walkthrough.component.html',
  styleUrls: ['./walkthrough.component.scss']
})
export class WalkthroughComponent implements OnInit {

  slideIndex = 1;
  lastSlide = 1;
  slides: Slide[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private profile: ProfileService,
    private teamService: TeamService,
  ) { }

  ngOnInit(): void {

    /* Show only to users with no avatar */
    if (this.profile.me.avatar === null || this.profile.me.avatar === '') {
      this.slides.push({
        title: 'Upload your profile picture',
        description:
        `<p>Adding an <b>avatar</b> is always a great way to start!</p>
        <p>Providing a face to a name, helps establish legitimacy and <b>adds a human element</b> to your profile.</p>
        <p>To change your profile picture just go to <b>Preferences</b> and upload your avatar.</p>`,
        type: 'video',
        src: 'avatar' }
      );
    }

    /* Show only to community owners */
    if (this.teamService.activeTeam &&
      this.teamService.activeTeam.permissions &&
      this.teamService.activeTeam.permissions.dashboard_access) {
      this.slides.push({
        title: 'Manage your Community',
        description:
        `<p>You can set up and control your Community from your <a href="https://dashboard.ensemble.chat/login" target="_blank"><b>Admin Dashboard</b></a>.</p>
        <p>Through the dashboard you can <b>create chatrooms, invitation links, add widgets, set up branding, manage your community permissions and member roles</b>.</p>
        <p>Connect your <b>Stripe</b> account and set up <b>subscriptions</b> for your members.</p>`,
        type: 'video',
        src: 'dashboard' }
      );
    }

    /* Show to non-custom domain members */
    if (!this.teamService.activeTeam.customDomain) {
      this.slides.push({
        title: 'Find a Community to Join',
        description:
        `<p>What's the point in joining a community platform and being alone?</p>
        <p>Open up the <b>Community Discovery</b> button and search for a community to join.</p>
        <p>You can search for Communities by name, description or topics.</p>
        <p>Alternatively, create your own Community and invite your fellow peers to stay in touch.</p>`,
        type: 'video',
        src: 'join' }
      );
    }

    this.slides.push({
      title: 'You are all set!',
      description:
      `<p>If you ever want to see this walkthrough again, you may find it under your <b>Preferences</b> menu.</p>
      <p><b>Download</b> Ensemble Desktop App</p>
      <p><a href="https://downloads.ensemble.chat/Ensemble.dmg" rel="nofollow" target="_blank" class="btn btn-secondary text-left down-btn">
      <img
          alt="Download for Mac"
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAC/ElEQVRYR8WXT2gTQRTGvzebKPjnJChYvChV6qEJelBMjE0qRT14UA+C4ME/KFShUNGrB2+CYNGK4k2wYAsiVQR1N9FsoqA1m70oKMaCeFAvglQh2fdkq8GQps3Ebuhe55v5fvPmzby3hAC/6LbUXQZ9dvNmv+6ypCtspovEkvuIaIyFdrg5M9tMXx0PBKC7r2+p8aP8Bko9LtjmUV1zXxcIQDSeugBGv7GIOycymW8tA0TjySsAaZ9bKwZzaC87tjUwHYFIrHeMSPb/78IMTBpEZ3Tns2BQEU8WsumDgQAQcLhgW7d0AfwNK8WVQACY8W5Dx4qu0dFRry0AJEaHwBsBIdHIQIROFnPm9dqx7m29V5WSsn/Gjea0FAHHtmi2HGHh7+Ffy1dPTIxP1RrVGrQVAEw3nLx5ot4kUAAO8RpVoRGA4vVGpLCj8Mx6NhMgNaRIygU7PTjvCMyWWMz45uYTq4DzrJt8VV1LOTArAHDbta1DrZpX3535X0PCcSdr3VwwAEUUeZ013QUDmGnsMTi01cmbL5tBBZIDjUyUx52vn2feLwgAAz9dO7FM51Y0j4AnJRCXoYz1zXZTHRfgbdG2uhrpNyV2dkmFpwp5a1LzFtApxzaHo9uTe+DRMYB3Q6nFc8GQwCrkrN6qZrpLmqrs9USOKKKdELro5MyzmgAeA3QPYlxzctvNjT2ZJeGK0aMgcQF3A2otGCuhvKUAQoChAE6DjCGIRFgkTkK+NqwUHgmM4aJtPgAgmgD/9srwPhtQ4yD1xDO8F24m86nR2/+3oRGwV2JlvCCIKRy+7+YffZmrVrTckPgVUJFREnhfCfjhL86QLQpUNH4uP1BfGdtWC2YUprocmCtfmt8C3dSv1bH3wck/XacztT0A8BgSOkwKTVszqcgAheRjTU+YGiLCaR36oDQEueT3C4H8mPhQ0ViqBJI7jp0+1wpkYACRWGpIBLvcvKX9evqggQFsiic3M+iVCKWKOTOtG4XAAP68cMmHpGA52fRFXYDfZbbvMGepGnYAAAAASUVORK5CYII=" 
         />
      <small>Download App for</small><br/><b>Mac OS</b></a>
      <a href="https://downloads.ensemble.chat/EnsembleSetup.exe" rel="nofollow" target="_blank" class="btn btn-secondary text-left down-btn">
      <img
          alt="Download for Windows"
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAABxklEQVRYR+2Xz0ocQRDGv68a/yRGCQGJiPY0ixAQdNfj/sPNjhdvQjDx4IsIXnLJ2YvvoLAvkMOisLmv4F18AkGWXKLTFWaze1hNmBmyQSHT5+rqX30NVfURT3z4r98vl8svvsu0FYglYL0goNdlCr92O+2TvwYoNRqveYcVCAMPWEItFIFCrHpYEcz/rkhST7uds70kABbLzUVOIIBXB4pT9QUP+XL5rX0VJy5Vmj0IXmVVcgRgvdFYkjuzCaqDwnmoo9Ax8ssQmXqYnORut9Nu9QFqTc36eBw/ArBRf3+iyk9pE40doFgNW6R+yAFyBXIF/l8FNurhHuB30iigwD2MHl6cn18POuERqQtp7o7GSCvupkmzIHvejDeeB0CxHu7DI9UXkBpF4MFwGq5XwmMSbzMWDhHEX/BrH8hnQa5ArkCuQKnWPAXwMW0zGftSulYLCwK/RYpDpM4b7zQyzkh/yDxq12MH+FPlK9vbUzO9HwEVAURd35yAi5jwn4fTcK22eWtg5tKqN4xL64wS876rVmdfcrIQ2zEPWoIW1EBBq7i3AiwARh6bm3TWLBEgKWB1dXfSvLlZMn24aOAbaQfm9On3gZ/T2GgwgZZwkgAAAABJRU5ErkJggg==" 
         />
      <small>Download App for</small><br/><b>Windows</b></a>`,
      type: 'image',
      src: 'finish' }
    );

    this.lastSlide = this.slides.length;
  }

  ngAfterContentInit(): void {
    this.showSlides(this.slideIndex);
  }

  closeWalkthrough() {
    this.profile.walkthrough.acknowledged = true;
    this.profile.walkthrough.save();
    this.activeModal.dismiss();
  }

  plusSlides(n) {
    this.showSlides(this.slideIndex += n);
  }

  currentSlide(n) {
    this.showSlides(this.slideIndex = n);
  }

  showSlides(n) {
    let i;
    const slides: any = document.getElementsByClassName('blogSlides');
    const dots = document.getElementsByClassName('blogSlider__dots--dot');
    if (n > slides.length) {
      this.slideIndex = 1;
    }
    if (n < 1) {
      this.slideIndex = slides.length;
    }
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';
    }
    for (i = 0; i < slides.length; i++) {
      dots[i].className = dots[i].className.replace(' active', '');
    }
    slides[this.slideIndex - 1].style.display = 'block';
    dots[this.slideIndex - 1].className += ' active';
  }

}

interface Slide{
  title: string;
  description?: string;
  type: string;
  src: string;
}
