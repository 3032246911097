<ng-container *ngIf="team; else loader">
  <div class="cover"
    [ngStyle]="{'background-image': team.config.branding.backgroundUrl? 'url(' + team.config.branding.backgroundUrl + ')' : defaultBackgroundImg }">
    <div class="intro">
      <div class="d-flex flex-column justify-content-center align-items-center h-100">
        <img *ngIf="team.config.branding.showLogo && team.config.branding.logoUrl" class="logo"
          [src]="team.config.branding.logoUrl">
        <div class="header">
          <div class="title" *ngIf="!team.config.branding.showLogo">{{(team.label && team.label.length) ?
            team.label:team.name}}</div>
          <div class="chatrooms-subtitle" *ngIf="!team.config.branding.showLogo">community</div>
          <div class="subtitle" *ngIf="team.config.branding.tagline">{{team.config.branding.tagline}}</div>
        </div>
      </div>
    </div>
    <div class="sidebar" [ngClass]="sidebarClasses">
      <div class="content">
        <img *ngIf="team.config.branding.showLogo && team.config.branding.logoUrl" class="logo"
          [src]="team.config.branding.logoUrl">
        <router-outlet></router-outlet>
      </div>
      <app-copyright class="footer"></app-copyright>
    </div>
  </div>
</ng-container>

<ng-template #loader>loading...</ng-template>

<iframe #sso [src]="ssoUrl | safe" height="0" frameborder="0"></iframe>