<div class="modal-header">
  <h4 class="modal-title">Join Community</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body modal-teams">
  <div *ngIf="initTeamLoading" class="text-center">
    <i class="fal fa-circle-notch fa-spin fa-5x text-primary"></i>
  </div>
  <ng-container *ngIf="!initTeamLoading">
    <ng-container *ngIf="!teamToJoin && !searchedAlready">
      <div class="form-group">
        <input #search type="text" class="form-control bg-dark " ngbAutofocus [formControl]="searchField"
          placeholder="Enter the exact team name" autofocus (keydown.enter)="searchTeams()">
        <div class="borderfx"></div>
      </div>
      <div class="d-flex flex-row-reverse pt-3 pb-3 pr-3">
        <button [disabled]="!searchField.value" type="button" (click)="searchTeams()"
          class="btn btn-outline-primary btn-sm">Next</button>
      </div>
    </ng-container>
    <div *ngIf="!teamToJoin && searchedAlready" class="pt-3 pb-3 pr-3 pl-3">
      <div *ngIf="!alreadyJoined" class="mb-3 alert alert-primary">
        <small>Sorry! No community with name <b>"{{searchField.value}}"</b> has been found.</small>
      </div>
      <div *ngIf="alreadyJoined" class="mb-3 alert alert-primary break-word">
        <small>You have already joined the community with name <b>"{{searchField.value}}"</b>!</small>
      </div>
      <!-- <div class="d-flex flex-row-reverse pt-3 pb-3 pr-3">
        <button type="button" (click)="backToSearch()" class="btn btn-outline-primary btn-sm">Back</button>
      </div> -->
    </div>
    <div *ngIf="teamToJoin && searchedAlready">
      <app-team-info [team]="teamToJoin"></app-team-info>
      <div class="action mt-3 pt-3 pb-3">
        <ng-container *ngIf="teamToJoin.config.join.state === 'NONE'">
          <div class="alert alert-danger text-center mb-0">This is a private community.<br />
            Contact the community's owner to get an invite.</div>
        </ng-container>
        <ng-container *ngIf="teamToJoin.config.join.state !== 'NONE'">

          <div>
            <label *ngIf="!isBanned && errorMessage!=='INVALID_EMAIL'" class="checkbox-wrap">I acknowledge that
              <span class="break-all">{{teamToJoinLabel}}</span> admins will have access to my user profile information.
              <input type="checkbox" [formControl]="acceptedTermsField">
              <span class="checkmark"></span>
            </label>
          </div>
          <button *ngIf="!isBanned && errorMessage!=='INVALID_EMAIL'" [disabled]="loading || acceptedTermsField.invalid"
            type="button" (click)="join()" class="btn btn-success btn-md btn-block">
            <span class="btn-span-one-liner">
              <i *ngIf="loading" class="far fa-sync-alt fa-fw fa-spin"></i>
              <i *ngIf="!loading" class="fas fa-user-check fa-fw"></i>
              <span class="ml-2">Join {{teamToJoinLabel}}</span>
            </span>
          </button>
          <button *ngIf="isBanned && errorMessage!=='INVALID_EMAIL'" type="button"
            class="btn btn-danger btn-md btn-block" [disabled]="true">
            <span class="btn-span-one-liner">
              <i class="fas fa-user-times fa-fw"></i>
              <span class="ml-2">You've been banned from {{teamToJoinLabel}}</span>
            </span>
          </button>
          <div class="alert alert-danger text-center"
            *ngIf="teamToJoin.config.join.state === 'CUSTOM' && errorMessage==='INVALID_EMAIL'">
            This community limits members to specific email addresses.<br>
            Contact the community's owner if you experience any problem.
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>


<div *ngIf="false" class="modal-footer text-center">
  Would you like to create your own community? <a href="javascript:" (click)="startTeam()">Start here!</a>
</div>