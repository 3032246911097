import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, HostListener, OnInit, SecurityContext, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DiscoverService } from '../../_core/services/discover.service';
import { PanelsService } from '../../_core/services/panels.service';
import { ProfileService } from '../../_core/services/profile.service';
import { RoomService } from '../../_core/services/room.service';
import { SocketService } from '../../_core/services/socket.service/socket.service';
import { TeamService } from '../../_core/services/team.service';
import { RoomType } from '../../_shared/models/enums/room-type';
import { LocalUserStatusChanged, UserStatus } from '../../_shared/models/enums/user-status';
import { ItemType } from '../../_shared/models/room/channel';
import { DirectRoom } from '../../_shared/models/room/room';
import { BannedTeamUserComponent } from '../banned-team-user/banned-team-user.component';
import { BaseComponent } from '../base-component';
import { ConfirmComponent } from '../confirm/confirm.component';
import { LeaveTeamComponent } from '../leave-team/leave-team.component';
import { NotificationsSettingsComponent } from '../notifications/notifications-settings/notifications-settings.component';
import { LockComponent } from '../panel-service/lock/lock.component';
import { PersonalInviteComponent } from '../personal-invite/personal-invite.component';
import { StartConversationComponent } from '../start-conversation/start-conversation.component';
import { StreamComponent } from '../stream/stream.component';
import { RULE } from './../../_shared/models/commons/permissions.constants';

export const DEFAULT_ACTIVE_PANEL_COLOR = '#5071ab';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent extends BaseComponent implements OnInit {

  itemType = ItemType;
  rules = RULE;

  grpHover = false;

  haveDashboardAccess = false;
  isOwner = false;
  showMenu = false;
  adspace = { show: false, url: null, size: 100 };

  teamInfoModalInstanceRef: any;
  @ViewChild('teamInfoModalTemplate', { static: true })
  teamInfoModalTemplate: TemplateRef<any>;

  focusedItem: string;

  meetingIds = {};
  meetings: any;

  @HostListener('click', ['$event'])
  sidebarClickHandler(event) {
    if (event.target.id === 'team-info' || event.target.parentElement.id === 'team-info') {
      this.showTeamInfo();
    } else if (event.target.id === 'team-dashboard' || event.target.parentElement.id === 'team-dashboard') {
      this.openDashboard();
    } else if (event.target.id === 'personal-invite') {
      this.openPersonalInviteModal();
    } else if (event.target.id === 'dashboard-rooms') {
      this.openDashboardRooms();
    } else if (event.target.id === 'sidebar-meeting') {
      // this must be before chatroom open handlers, to override handling
      if (event.target.parentElement.parentElement.classList.contains('sidebar-room')) {
        const roomId = (event.target.parentElement.parentElement.id as string).substr(8);
        this.openMeeting(roomId);
      }
    } else if (event.target.classList.contains('sidebar-room')) {
      const roomId = (event.target.id as string).substr(8);
      this.openRoom(roomId);
    } else if (event.target.parentElement.classList.contains('sidebar-room')) {
      const roomId = (event.target.parentElement.id as string).substr(8);
      this.openRoom(roomId);
    } else if (event.target.parentElement.parentElement.classList.contains('sidebar-room')) {
      const roomId = (event.target.parentElement.parentElement.id as string).substr(8);
      this.openRoom(roomId);
    } else if (event.target.parentElement.parentElement.parentElement.classList.contains('sidebar-room')) {
      const roomId = (event.target.parentElement.parentElement.parentElement.id as string).substr(8);
      this.openRoom(roomId);
    } else if (event.target.classList.contains('sidebar-frame')) {
      const frameId = (event.target.id as string).substr(8);
      const newWindow = event.target.getAttribute('data-frame-new');
      this.openFrame(frameId, newWindow === 'true');
    } else if (event.target.parentElement.classList.contains('sidebar-frame')) {
      const frameId = (event.target.parentElement.id as string).substr(8);
      const newWindow = event.target.parentElement.getAttribute('data-frame-new');
      this.openFrame(frameId, newWindow === 'true');
    } else if (event.target.parentElement.parentElement.classList.contains('sidebar-frame')) {
      const frameId = (event.target.parentElement.parentElement.id as string).substr(8);
      const newWindow = event.target.parentElement.parentElement.getAttribute('data-frame-new');
      this.openFrame(frameId, newWindow === 'true');
    } else if (event.target.id === 'hide-direct') {
      // this must be before direct open handlers, to override handling
      const panelId = event.target.getAttribute('data-directid');
      this.hide(panelId);
    } else if (event.target.classList.contains('sidebar-direct')) {
      const directId = (event.target.id as string).substr(8);
      this.openDirect(directId);
    } else if (event.target.parentElement.classList.contains('sidebar-direct')) {
      const directId = (event.target.parentElement.id as string).substr(8);
      this.openDirect(directId);
    } else if (event.target.parentElement.parentElement.classList.contains('sidebar-direct')) {
      const directId = (event.target.parentElement.parentElement.id as string).substr(8);
      this.openDirect(directId);
    } else if (event.target.parentElement.parentElement.parentElement.classList.contains('sidebar-direct')) {
      const directId = (event.target.parentElement.parentElement.parentElement.id as string).substr(8);
      this.openDirect(directId);
    } else if (event.target.id === 'start-conversation-btn' || event.target.parentElement.id === 'start-conversation-btn') {
      this.startConversation();
    } else if (event.target.id === 'add-team-btn') {
      this.addTeam();
    }
  }

  constructor(
    private teamService: TeamService,
    private profileService: ProfileService,
    private panelsService: PanelsService,
    private roomService: RoomService,
    private modal: NgbModal,
    private cd: ChangeDetectorRef,
    private socketService: SocketService,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private discover: DiscoverService
  ) {
    super();
  }

  get me() {
    return this.profileService.me;
  }

  get team() {
    return this.teamService.activeTeam;
  }

  get teamStyle() {
    if (!this.teamService.checkFeature(this.teamService.activeTeamId, "CustomBranding")) {
      return {}
    }
    return this.teamService.activeTeam.config.branding || {};
  }

  get directs() {
    return this.roomService.directs.sort((a, b) => b.lastActiveDate - a.lastActiveDate);
  }

  addTeam() {
    this.discover.addTeam();
  }

  getOtherUser(room: DirectRoom) {
    const otherUser = room.participants.find(c => c.userId !== this.profileService.me.id);
    return otherUser;
  }

  getOtherUserStatus(room: DirectRoom) {
    const otherUser = this.getOtherUser(room);
    if (otherUser && otherUser.user && otherUser.user.status) {
      return otherUser.user.status;
    }
    return null;
  }

  colorClass(room: DirectRoom) {
    const otherUserStatus = this.getOtherUserStatus(room);
    if (otherUserStatus) {
      if (otherUserStatus.toString() === 'UNKNOWN' || 'AWAY' || 'BUSY' || 'AVAILABLE' || 'OFFLINE') {
        return otherUserStatus.toString();
      }
    }
    return 'text-muted';
  }

  // get focusedItem() {
  //   return this.panelsService.focusedItem;
  // }

  async ngOnInit() {
    this.subscribe(
      this.teamService.activeTeamId$,
      async (id) => {
        this.checkAndOpenDefaultChatrooms(id);
        this.calculateItemVisibility();
      });

    if (this.teamService.activeTeam) {
      this.calculateItemVisibility();
    }

    this.subscribe(this.teamService.mustUpdateSidebar$, async () => {
      this.checkAndOpenDefaultChatrooms(this.teamService.activeTeamId);
      this.calculateItemVisibility();
    });

    this.subscribe(
      this.roomService.teamRoomUpdated$,
      (chatroom) => {
        this.checkAndOpenDefaultChatrooms(chatroom.teamId);
        this.calculateItemVisibility();
      });

    this.subscribe(
      this.teamService.meetingsUpdated$,
      (meetings) => this.calculateMeetings(meetings[this.teamService.activeTeamId]));

    this.subscribe(
      this.panelsService.panelClosed$,
      (id) => {
        if (this.team && this.team.sidebarResolved) {
          const roomSidebarItem = this.team.sidebarResolved.find(c => c.refId === id);
          if (roomSidebarItem) {
            roomSidebarItem.domClassList = this.getItemClasses(false);
            return;
          }
        }
        if (this.directs && this.directs.length > 0) {
          const directRoom = this.directs.find(c => c.id === id);
          if (directRoom) {
            directRoom.domClassList = this.getItemClasses(false);
            return;
          }
        }
      });

    this.subscribe(this.panelsService.clickedMenuItem$, (id) => {
      this.focusedItem = id;
      this.cd.detectChanges();
    });

    if (this.team && this.team.sidebarResolved) {
      this.checkAndOpenDefaultChatrooms(this.teamService.activeTeamId);
    }

    this.subscribe(
      this.profileService.userStatusChanged$,
      async (data: LocalUserStatusChanged) => {
        const room = this.directs.find(c => c.type === RoomType.Direct && c.participants.map(p => p.userId).indexOf(data.userId) >= 0);
        if (!room) { return; }
        const usrs = room.participants.filter(c => c.userId !== this.profileService.me.id);
        if (!usrs || usrs.length !== 1) { return; }
        if (data.userId === usrs[0].userId) {
          usrs[0].user.status = UserStatus[data.currentStatus];
          this.cd.markForCheck();
        }
      });
  }

  openMeeting(chatroomId) {
    const room = this.roomService.getRoomById(chatroomId);
    const modal = this.modal.open(StreamComponent, {
      centered: true,
      windowClass: 'modal-dark'
    });
    modal.componentInstance.room = room;
  }

  hideSpace() {
    this.adspace.show = false;
  }

  checkAds(id) {

    fetch(`https://www.ensemble.chat/assets/ads.json?t=${Date.now()}`)
    .then(async (response) => {
      const res = await response.json().then(data => {
        Object.entries(data).forEach(([key, value]) => {
          if (id === key
            && value['url']) {
            this.adspace.show = true;
            this.adspace.url = value['url'];
            this.adspace.size = value['height'];
          }
        });
      });
    })
    .catch((err) => {
      // console.log(err)
    });

    this.adspace.show = false;
  }


  calculateMeetings(meetings) {
    this.meetingIds = meetings.reduce(
      (l, i) => {
        l[i.chatroomId] = true;
        return l;
      },
      {});
    this.cd.markForCheck();
  }

  disclaimerShow() {
    if (!this.teamService.activeTeam || !this.teamService.activeTeam.config || !this.teamService.activeTeam.config.branding.disclaimer) {
      return false;
    }

    if (this.teamService.disclaimerAccepted.teamIds.filter(t => t === this.teamService.activeTeam.id).length > 0) {
      return false;
    }

    return true;
  }

  calculateItemVisibility() {
    if (!this.teamService.activeTeam) {
      return;
    }
    this.isOwner = this.teamService.activeTeam.member && this.teamService.activeTeam.member.isOwner;
    this.haveDashboardAccess = this.teamService.activeTeam.permissions && this.teamService.activeTeam.permissions.dashboard_access;
    this.showMenu = !this.isOwner || this.haveDashboardAccess;
  }

  openLeaveTeamModal() {
    const modal = this.modal.open(ConfirmComponent, {
      centered: true,
      windowClass: 'modal-dark'
    });
    modal.componentInstance.confirmTitle = 'Leave team';
    modal.componentInstance.confirmMessage = `You are about to leave ${this.teamService.activeTeam.name}. Are you sure?`;
    modal.result.then((result) => {
      if (result) {
        this.teamService.leaveActiveTeam();
        this.teamService.removeTeam(this.teamService.activeTeam.id);
      }
    });
  }


  checkAndOpenDefaultChatrooms(teamId: string) {
    if (this.team && !this.team.member.isBanned) {
      const teamPanels = this.team.sidebarResolved ? this.team.sidebarResolved.filter(s => s.itemType === ItemType[ItemType.Channel] || s.itemType === ItemType[ItemType.Frame]) : [];
      const openedTeamPanelIds = (this.panelsService.panels || []).filter(p => p.visible && p.teamId === this.team.id).map(p => p.id);
      const openedDirectPanelIds = (this.panelsService.panels || []).filter(p => p.visible && !p.teamId).map(p => p.id);
      for (const teamPanel of teamPanels) {
        
        if (teamPanel.itemType === 'Frame') {
          this.getServiceIcon(teamPanel);
        }

        if (openedTeamPanelIds.indexOf(teamPanel.refId) > -1) {
          teamPanel.domClassList = this.getItemClasses(true);
        }
      }
      for (const directPanel of this.directs) {
        if (openedDirectPanelIds.indexOf(directPanel.id) > -1) {
          directPanel.domClassList = this.getItemClasses(true);
        }
      }

      if (this.team.sidebarResolved && (!openedTeamPanelIds || !openedTeamPanelIds.length) && !this.panelsService.defaultChatrooms[teamId]) {
        const panels = this.team.sidebarResolved.filter(s => (s.itemType !== ItemType[ItemType.Divider]) && !s.newWindow && !s.isHidden);

        if (panels && panels[0]) {
          if (panels[0].refId.indexOf('chr_') === 0) {
            this.openRoom(panels[0].refId);
          } else {
            this.openFrame(panels[0].refId, panels[0].newWindow);
          }

          const panel = this.panelsService.panels.find(p => p.id === panels[0].refId);
          this.panelsService.maximize(panel);

          if (panels[1]) {
            if (panels[1].refId.indexOf('chr_') === 0) {
              this.openRoom(panels[1].refId);
            } else {
              this.openFrame(panels[1].refId, panels[1].newWindow);
            }
          }
        }

        this.panelsService.defaultChatrooms[this.teamService.activeTeamId] = true;
        this.panelsService.defaultChatrooms.save();
      }
    }
  }

  getItemClasses(open: boolean) {
    if (open) {
      return 'opened active';
    }
    return '';
  }


  getServiceIcon(teamPanel) {
    const servFrame = this.team.frames.find(fr => fr.id === teamPanel.refId);
    let servIcon = 'generic';

    if (teamPanel.label.match(/twitter/i) || teamPanel.label.match(/tweet/i)) {
      servIcon = 'twitter';
    } else if (teamPanel.label.match(/instagram/i)) {
      servIcon = 'instagram';
    } else if (teamPanel.label.match(/facebook/i)) {
      servIcon = 'facebook';
    } else if (teamPanel.label.match(/linkedin/i)) {
      servIcon = 'linkedin';
    } else if (teamPanel.label.match(/youtube/i) || teamPanel.label.match(/youtu.be/i)) {
      servIcon = 'youtube';
    }

    teamPanel.serviceIcon = servIcon;
  }

  startConversation() {
    this.modal.open(StartConversationComponent, { backdrop: 'static', centered: true, windowClass: 'modal-dark' });
  }

  async openRoom(roomId: string) {
    if (this.teamService.activeTeam.member.isBanned) {
      this.modal.dismissAll();
      this.modal.open(BannedTeamUserComponent, {
        backdrop: 'static',
        backdropClass: 'backdrop-shifted',
        keyboard: false,
        centered: true,
        windowClass: 'modal-dark shifted',
      });
      return;
    }

    if (this.team && this.team.sidebarResolved) {
      const roomSidebarItem = this.team.sidebarResolved.find(c => c.refId === roomId);
      if (roomSidebarItem) {
        roomSidebarItem.domClassList = this.getItemClasses(true);
        this.cd.markForCheck();
      }
    }

    this.focusedItem = roomId;

    await this.panelsService.openRoom(roomId);

    this.scrollToPanel(roomId);
  }

  scrollToPanel(id: string) {
    setTimeout(
      () => {
        this.panelsService.slideToPanel(id);
      },
      50);
  }

  openExternalFrame(frameId) {
    const frame = this.team.frames.find(fr => fr.id === frameId);
    if (typeof (frame) !== 'undefined') {
      const safeUrl = this.sanitizer.sanitize(SecurityContext.URL, frame.url);
      window.open(safeUrl, '_blank');
    }
  }

  async openFrame(frameId: string, newWindow: boolean) {
    if (newWindow) {

      const frame = this.team.frames.find(fr => fr.id === frameId);
      const roomPermissions = this.teamService.activeTeam.chatrooms
        .filter(c => frame['billingChatrooms'].indexOf(c.id) > -1)
        .map(c => c.permissions.user.read_messages);

      const hasAccess = roomPermissions.some(x => x) || roomPermissions.length === 0;

      if (hasAccess) {
        this.openExternalFrame(frameId);
      } else {
        const m = this.modal.open(LockComponent, {
          size: 'sm',
          centered: true,
          windowClass: 'modal-dark'
        });
        m.componentInstance.frame = frame;
        await m.result.catch(() => { });
      }
      return;
    }

    if (this.team && this.team.sidebarResolved) {
      const roomSidebarItem = this.team.sidebarResolved.find(c => c.refId === frameId);
      if (roomSidebarItem) {
        roomSidebarItem.domClassList = this.getItemClasses(true);
      }
    }

    this.focusedItem = frameId;

    await this.panelsService.openFrame(frameId);

    this.scrollToPanel(frameId);
  }

  async openDirect(directId: string) {
    if (this.team && this.team.sidebarResolved) {
      const directRoom = this.directs.find(c => c.id === directId);
      if (directRoom) {
        directRoom.domClassList = this.getItemClasses(true);
      }
    }

    this.focusedItem = directId;

    await this.panelsService.openDirect(directId);

    this.scrollToPanel(directId);
  }

  isOpened(itemId: string) {
    return this.panelsService.isOpened(itemId);
  }

  trackByFn(index, item) {
    return item.id;
  }

  openTeamNotificationModal() {
    const modal = this.modal.open(NotificationsSettingsComponent, {
      centered: true,
      windowClass: 'modal-dark',
      size: 'lg'
    });
    modal.componentInstance.me = this.profileService.me;
  }

  openDashboard() {
    window.open('https://dashboard.ensemble.chat', '_blank');
  }

  openDashboardRooms() {
    window.open('https://dashboard.ensemble.chat/rooms', '_blank');
  }

  showTeamInfo() {
    this.teamInfoModalInstanceRef = this.modal.open(this.teamInfoModalTemplate, {
      centered: true,
      windowClass: 'modal-dark'
    });
  }

  leaveTeam() {
    const modal = this.modal.open(LeaveTeamComponent, {
      centered: true,
      windowClass: 'modal-dark'
    });
    modal.result.then((result) => {
      if (result) {
        this.teamService.leaveActiveTeam();
        this.teamService.removeTeam(this.teamService.activeTeam.id);
      }
    });
  }

  dismissTeamInfo() {
    this.teamInfoModalInstanceRef.dismiss();
  }

  abandonTeam() {
    this.teamInfoModalInstanceRef.dismiss();
    this.leaveTeam();
  }

  async hide(panelId: string) {
    this.socketService.focus({ chatrooms: [{ id: panelId, focused: false }] });
    const panel = this.panelsService.panels.find(c => c.id === panelId);
    if (panel) {
      this.panelsService.close(panel);
    }
    this.roomService.directs = this.roomService.directs.filter(cr => cr.id !== panelId);
    await this.profileService.hideDirect(panelId);
  }

  openPersonalInviteModal() {
    const m = this.modal.open(PersonalInviteComponent, {
      centered: true,
      windowClass: 'modal-dark',
      backdrop: 'static',
      container: 'body'
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
