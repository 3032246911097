import { Injectable } from '@angular/core';
import { FilestackPolicyServiceApi } from '@echofin/libraries';
import { FilestackService } from '@filestack/angular';
import { PickerFileMetadata, PickerInstance } from 'filestack-js';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { FileUploaded } from '../../_shared/models/commons/filestack.models';

import { Uppload, en, xhrUploader, fetchUploader, Local, Instagram, Camera, URL, Facebook, Crop } from "uppload";

const defaultOptions: any = {
  maxSize: 100 * 1024 * 1024,
  maxFiles: 1,
  storeTo: {
    // THESE ARE NOT NEEDED! THEY WERE COPIED FROM THE FILESTACK ADMIN DASHBOARD EXAMPLES AND ARE THE REASON OF ERROR ON VIDEO/AUDIO CAPTURE
    // IF WE USE A CLOUD PROVIDER IN THE DASHBOARD, IT WILL USE IT BY DEFAULT IF WE LEAVE THIS EMPTY
    // (EVEN WHEN WE PASSED location: S3 AND HAD SET AZURE PROVIDER, IT UPLOADED TO AZURE AND NOT THE DEFAULT FILESTACK SPACE ON S3)
    // container: 'devportal-customers-assets',
    // path: 'user-uploads/',
    // region: 'us-east-1'
  },
  uploadInBackground: false,
};

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {

  //view only policies
  policy: string;
  signature: string;

  uploadPolicy: string;
  uploadSignature: string;

  pickerFiles: PickerInstance;
  pickerAvatar: PickerInstance;
  pickerLogo: PickerInstance;
  pickerPaste: PickerInstance;

  upploadInstance: Uppload;

  files$: Subject<FileUploaded[]> = new Subject<FileUploaded[]>()

  constructor(
    private toastr: ToastrService,
    private fsApi: FilestackPolicyServiceApi,
    private filestackService: FilestackService,
  ) {
  }

  async setup() {

    const viewOnlySecurity = await this.fsApi.GetDefaultPolicy().toPromise();
    this.policy = viewOnlySecurity.policy
    this.signature = viewOnlySecurity.signature

    const pickerPolicy = await this.fsApi.GetPickerPolicy().toPromise();
    this.filestackService.init(environment.config.filestackKey, {
      security: {
        policy: pickerPolicy.policy,
        signature: pickerPolicy.signature
      },
      cname: 'fs.echofin.com'
    });

    this.pickerFiles = await this.filestackService.picker({
      ...defaultOptions,
      // accept: ['.pdf', '.doc', '.docx', '.xls', '.xlsx', '.rtf', '.csv', '.zip', '.rar', '.tar', 'image/jpeg', 'image/*', 'video/*', 'video/mp4', 'audio/*', 'application/*', 'text/*',],
      fromSources: ['local_file_system', 'webcam', 'video', 'audio', 'url', 'facebook', 'googledrive', 'dropbox'],
      onUploadDone: (res) => this.onUploadDone(res),
      onClose: () => this.onClose(),
    });

    this.upploadInstance = new Uppload({
      lang: en,
      uploader: this.filestackUploader,
      defaultService: 'local'
    });

    this.upploadInstance.use([new Local(), new Camera(), new URL(), new Facebook()]);
    this.upploadInstance.use(new Crop({
      aspectRatio: 1
    }));
    // this.upploadInstance.on('before-upload', c => { console.log('UPPLOAD before', c); this.onBeforeUppload(c); });
    this.upploadInstance.on('upload', c => { console.log('UPPLOAD upload', c); this.onUpploadDone(c); });
    this.upploadInstance.on('error', () => { console.log('UPPLOAD error'); this.onUpploadError(); });
    this.upploadInstance.on('close', () => { console.log('UPPLOAD close'); this.onClose(); });
  }

  async pickFiles(): Promise<FileUploaded[]> {

    if (!this.pickerFiles) {
      this.pickerFiles = await this.filestackService.picker({
        ...defaultOptions,
        // accept: ['.pdf', '.doc', '.docx', '.xls', '.xlsx', '.rtf', '.csv', '.zip', '.rar', '.tar', 'image/jpeg', 'image/*', 'video/*', 'video/mp4', 'audio/*', 'application/*', 'text/*',],
        fromSources: ['local_file_system', 'webcam', 'video', 'audio', 'url', 'facebook', 'googledrive', 'dropbox'],
        onUploadDone: (res) => this.onUploadDone(res),
        onClose: () => this.onClose(),
      })
    }

    await this.pickerFiles.open();

    return new Promise<FileUploaded[]>((resolve, reject) => {
      this.files$.pipe(first()).subscribe((files) => {
        resolve(files)
      })
    })
  }

  async pickLogo(): Promise<FileUploaded[]> {

    if (!this.pickerLogo) {
      this.pickerLogo = await this.filestackService.picker({
        ...defaultOptions,
        maxFiles: 1,
        accept: ['image/*'],
        fromSources: ['local_file_system', 'webcam', 'url', 'facebook', 'googledrive', 'dropbox'],
        imageMax: [512, 512],
        transformations: {
          crop: {
            aspectRatio: 1
          },
        },
        onUploadDone: (res) => this.onUploadDone(res),
        onClose: () => this.onClose(),
      })
    }

    await this.pickerLogo.open();

    return new Promise<FileUploaded[]>((resolve, reject) => {
      this.files$.pipe(first()).subscribe((files) => {
        resolve(files)
      })
    })
  }

  filestackUploader = (
    file: Blob,
    updateProgress: (progress: number) => void
  ): Promise<string> => {
    // Here, we take the file and send it to the server
    return new Promise((resolve, reject) => {
      // First, we create form data with the file
      // const formData = new FormData();
      // formData.append("file", file);

      // Then, we upload the file
      const xmlHttp = new XMLHttpRequest();
      xmlHttp.open("POST", `https://www.filestackapi.com/api/store/s3?key=${environment.config.filestackKey}&policy=${this.uploadPolicy}&signature=${this.uploadSignature}`, true);

      // We also report the progress back
      xmlHttp.addEventListener("progress", event => { updateProgress(event.loaded / event.total); });

      // Finally, we report the uploaded file URL
      xmlHttp.addEventListener("load", () => { resolve(JSON.parse(xmlHttp.responseText)); });

      // Handle errors
      xmlHttp.addEventListener("error", () => reject("errors.response_not_ok"));
      xmlHttp.addEventListener("abort", () => reject("errors.upload_aborted"));

      // Send the request
      xmlHttp.send(file);
    });
  }

  async pickAvatar(): Promise<any> {

    const uploadSecurity = await this.fsApi.GetPickerPolicy().toPromise();
    this.uploadPolicy = uploadSecurity.policy
    this.uploadSignature = uploadSecurity.signature

    this.upploadInstance.updateSettings({
      lang: en,
      uploader: this.filestackUploader,
      defaultService: 'local'
    });
    this.upploadInstance.open();
    // this.upploadInstance.navigate('local');
    this.upploadInstance.activeService = 'local';

    return new Promise<FileUploaded[]>((resolve, reject) => {
      this.files$.pipe(first()).subscribe((files) => {
        resolve(files)
      })
    })
  }

  async pickImagePaste(blob: any): Promise<FileUploaded[]> {

    if (!this.pickerPaste) {
      this.pickerPaste = await this.filestackService.picker({
        ...defaultOptions,
        accept: ['image/*'],
        fromSources: ['local_file_system', 'webcam', 'url', 'facebook', 'googledrive', 'dropbox'],
        transformations: {
          crop: {
            force: true
          },
          circle: true,
          rotate: true
        },
        onUploadDone: (res) => this.onUploadDone(res),
        onClose: () => this.onClose(),
      })
    }

    await this.pickerPaste.crop(blob);

    return new Promise<FileUploaded[]>((resolve, reject) => {
      this.files$.pipe(first()).subscribe((files) => {
        resolve(files)
      })
    })
  }

  private onUploadDone(res) {
    if (res.filesFailed.length) {
      const failed: PickerFileMetadata[] = res.filesFailed;
      const fNames = failed.reduce((prev, curr) => `${prev} ${curr.filename}`, '');
      this.toastr.error(`${fNames} failed to be uploaded`);
      this.files$.next([])
    }
    if (res.filesUploaded.length) {
      const files = res.filesUploaded.map(f => new FileUploaded(f));
      this.files$.next(files)
    }
  }

  private onClose() {
    setTimeout(() => {
      this.files$.next([])
    }, 500);
  }

  uploadFilesImmediately(files: File | Blob, cancelToken: any, onProgress: (evt) => void) {
    return this.filestackService.upload(files, { onProgress, progressInterval: 100 }, {}, cancelToken);
  }

  // private async onBeforeUppload(res) {

  //   const f = {
  //     filename: res.name,
  //     mimetype: res.type,
  //     size: res.size
  //   };

  //   return new Promise<void>((resolve, reject) => {
  //     resolve();
  //   })
  // }

  private onUpploadDone(res) {
    const urlArray = res.url?.split('/');
    const handle = urlArray && urlArray.length ? urlArray[urlArray.length - 1] : null;

    const f = {
      filename: res.filename,
      mimetype: res.type,
      size: res.size,
      handle: handle,
      url: `https://cdn.fs.echofin.com/${handle}`
    };

    const file = new FileUploaded(f);
    this.files$.next([file]);
  }

  private onUpploadError() {
    this.toastr.error('File failed to be uploaded');
    this.files$.next([]);
  }
}
