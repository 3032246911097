import { CdkDragDrop, CdkDragStart, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as Sentry from '@sentry/angular';
import { Angulartics2Amplitude } from 'angulartics2/amplitude';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { BrandingService } from '../../_core/services/branding.service';
import { DiscoverService } from '../../_core/services/discover.service';
import { PanelsService } from '../../_core/services/panels.service';
import { ProfileService } from '../../_core/services/profile.service';
import { SocketService } from '../../_core/services/socket.service/socket.service';
import { TeamService } from '../../_core/services/team.service';
import { TrackingService } from '../../_core/services/tracking.service';
import { Helpers } from '../../_shared/helpers/helpers';
import { Me } from '../../_shared/models/commons/me';
import { Message } from '../../_shared/models/commons/message';
import { BaseComponent } from '../base-component';
import { CreateTeamComponent } from '../create-team/create-team.component';
import { DisclaimerComponent } from '../disclaimer/disclaimer.component';
import { DiscoverTeamComponent } from '../discover-team/discover-team.component';
import { InvitePreviewComponent } from '../invite-preview/invite-preview.component';
import { JoinTeamComponent } from '../join-team/join-team.component';
import { QuickHelpComponent } from '../quick-help/quick-help.component';
import { RoomService } from './../../_core/services/room.service';
import { Panel, PanelType } from './../../_shared/models/room/channel';
import { ScriptService } from './../../_core/services/script.service';
import { WalkthroughComponent } from '../walkthrough/walkthrough.component';

@Component({
  selector: 'app-chat-shell',
  templateUrl: './chat-shell.component.html',
  styleUrls: ['./chat-shell.component.scss'],
})
export class ChatShellComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

  newChannel: string;
  messages$: { [key: string]: BehaviorSubject<Message[]> } = {};
  usersOpen: boolean = true;
  debugOpen: boolean = false;
  process: string;
  loading = true;
  teamName: string;
  isCustom: boolean;
  menuShow: boolean = true;
  invModal: NgbModalRef;
  addModal: NgbModalRef;
  joinModal: NgbModalRef;
  createModal: NgbModalRef;
  walkModal: NgbModalRef;
  hasDisclaimer: boolean = false;
  firstTimeUser: boolean = false;

  @ViewChild('addTeamTemplate', { static: true })
  addTeamTemplateRef: TemplateRef<any>;
  me: Me = null;

  @ViewChild('testerContainer', { static: true })
  testerContainer: ElementRef;
  @ViewChild('testerBottom', { static: true })
  testerBottom: ElementRef;

  get branding() {
    if (!this.teamService.activeTeam) {
      return {};
    }
    if (!this.teamService.checkFeature(this.teamService.activeTeamId, "CustomBranding")) {
      return {}
    }
    return this.teamService.activeTeam.config.branding || {};
  }

  get maximizedItem() {
    return this.panelsService.maximizedPanels[this.teamService.activeTeamId] || this.panelsService.maximizedPanels['direct'];
  }

  get isAdmin() {
    if (this.teamService.activeTeam &&
      this.teamService.activeTeam.permissions &&
      this.teamService.activeTeam.permissions.dashboard_access) {
      return true;
    }
    return false;
  }

  analyticsInterv: any;

  panelType = PanelType;

  @ViewChild('panelsWrapper', { static: true }) panelsWrapper: ElementRef;

  @ViewChild('.cdk-drag-placeholder') dragPlaceholder: ElementRef;

  get panels() {
    return this.panelsService.panels || [];
  }

  initialized = false;

  whatsNewSub: Subscription;
  whatsNewTimeout: any;
  whatsNewTriggered = new Subject<void>();
  brokeNormalFlow = false;
  uiTheme = null;

  get sidebarClosed() {
    return this.teamService ? this.teamService.sidebarClosed : false;
  }

  constructor(
    private profileService: ProfileService,
    private modal: NgbModal,
    private teamService: TeamService,
    private route: ActivatedRoute,
    private router: Router,
    private panelsService: PanelsService,
    private profile: ProfileService,
    private elRef: ElementRef,
    private roomService: RoomService,
    private discover: DiscoverService,
    private tracking: TrackingService,
    private brandingService: BrandingService,
    private renderer: Renderer2,
    private angulartics2Amplitude: Angulartics2Amplitude,
    private socketService: SocketService,
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private scriptService: ScriptService
  ) {
    super();
  }

  offlineTimeout = null;

  async ngOnInit() {

    if (this.profile.workspace.theme
      && this.profile.workspace.theme === 'dark') {
      document.documentElement.setAttribute('data-theme', 'dark');
    }

    if (environment.config.name === 'ensemble') {
      this.setupAnalytics();

      // this opens what's new if brokeNormalFlow with create/join/discover modals
     //  this.watchModals();
    }

    // this checks and toggles flexbox column-reverse compatibility
    const topBefore = this.testerContainer.nativeElement.scrollTop;
    (this.testerBottom.nativeElement as HTMLDivElement).scrollIntoView();
    const topAfter = this.testerContainer.nativeElement.scrollTop;
    if (topBefore > 0 && topAfter < topBefore) {
      this.panelsService.reverseScrollTopNegative = false;
    }
    (this.testerContainer.nativeElement as HTMLDivElement).remove();

    this.route.params.subscribe(async (params) => {
      // if I have to minimize direct room, then do it
      if (this.panelsService.minimizeDirect && this.panelsService.minimizeDirect.val) {
        this.panelsService.minimizeMaximizedDirect();
      }

      // if in create team
      if (params['team'] === 'create') {
        this.brokeNormalFlow = true;
        this.createTeam();
        return;
      }
      // if in join team
      if (params['team'] === 'join') {
        this.brokeNormalFlow = true;
        this.joinTeam(this.route.snapshot.queryParams['team']);
        return;
      }
      // if have invite parameter
      if (params['team'] === 'invite') {
        this.showInvite(this.route.snapshot.queryParams['team'], this.route.snapshot.queryParams['inv']);
        return;
      }
      // if in discover team
      if (params['team'] === 'discover') {
        this.brokeNormalFlow = true;
        this.showDiscoverTeamModal();
        return;
      }

      this.teamName = params['team'];
      this.menuShow = true;
      // this.log("Switch to team", this.teamName)

      if (this.profile.me) {
        // this.log("Active team id", this.teamService.activeTeamId);
        // GET CORRECT TEAM TO LOAD
        const { team, correctTeamName } = this.teamService.getTeamToLoad(this.teamName);
        this.teamName = correctTeamName;

        // IF TEAM SWITCH TO IT
        // if you have joined the team and you are in correct Url, switch to it
        if (team && this.teamName) {
          await this.teamService.switchTeam(team.id);
          this.tracking.setUserProperties({
            team: this.teamService.teams.map(t => t.name),
            teamowner: this.teamService.teams.filter(t => t.member?.isOwner).map(t => t.name)
          });
          this.tracking.trackEvent('switch team', {
            team: team.name
          });
          // this.normalFlowWhatsNew();
        } else if (!team && this.teamName) {
          // make sure to make active team null
          this.teamService.activeTeamId = null;
          this.teamService.activeTeamId$.next(null);
          // IF NO TEAM BUT TEAMNAME, REDIRECT TO JOIN MODAL FOR TEAMNAME
          this.router.navigateByUrl(`/join?team=${this.teamName}`);
        } else if (team && !this.teamName) {
          // IF NO TEAMNAME BUT TEAM, REDIRECT TO CORRECT URL
          this.router.navigate(['/', team.name]);
        } else {
          this.firstTimeUser = true;
          // make sure to make active team null
          this.teamService.activeTeamId = null;
          this.teamService.activeTeamId$.next(null);
          // IF NO TEAM AND NO TEAMNAME, OPEN ADD MODAL
          this.brokeNormalFlow = true;
          this.addTeam();
        }
        // this.setupGetSiteControl()
      }

      this.showDisclaimerModal();

      if (
        this.firstTimeUser === false
        && !this.profile.walkthrough.acknowledged
        && this.hasDisclaimer === false
        ) {
        this.openWalkthrough();
      }

    });

    this.setupPanelService();

    this.cleanDeadPanels();

    this.initialized = true;
  }

  private setupPanelService() {
    this.panelsService.panelArea = this.panelsWrapper;
  }

  private cleanDeadPanels() {
    // filter out dead panels
    this.panelsService.panels = this.panels.filter(p =>
      // other team panels (dont have info on other team chatrooms yet)
      (p.teamId && p.teamId !== this.teamService.activeTeamId) ||
      // panels that are not chatroom types or service frame
      (p.type !== PanelType.Chatroom && p.type !== PanelType.Direct && p.type !== PanelType.Frame) ||
      // chatroom panels that dont align with team's chatrooms
      ((p.type === PanelType.Chatroom || p.type === PanelType.Frame) && this.teamService.activeTeam && this.teamService.activeTeam.chatrooms.map(c => c.id).indexOf(p.id) > -1) ||
      // group panel the user left
      (p.type === PanelType.Direct && this.roomService.directs.map(c => c.id).indexOf(p.id) > -1)
    );
    this.panelsService.panels.save();
  }

  private watchModals() {
    if (this.whatsNewSub) {
      return;
    }
    this.whatsNewSub = this.modal.activeInstances.pipe(
      takeUntil(this.whatsNewTriggered),
      filter((modals, i) => {
        return this.brokeNormalFlow;
      })
    ).subscribe(async (modals: NgbModalRef[]) => {
      if (!modals || !modals.length) {
        this.timeOutWhatsNew(10000);
      }
    });
  }

  private normalFlowWhatsNew() {
    if (!this.brokeNormalFlow) {
      this.timeOutWhatsNew(10000);
    }
  }

  private timeOutWhatsNew(mseconds: number) {
    if (this.whatsNewTimeout) {
      clearTimeout(this.whatsNewTimeout);
    }
    this.whatsNewTimeout = setTimeout(
      async () => {
        await this.checkWhatsNew();
      },
      mseconds);
  }

  private async checkWhatsNew() {
    this.whatsNewTriggered.next();
    if (environment.config.name !== 'ensemble') {
      return;
    }

    // tslint:disable-next-line: no-boolean-literal-compare
    if (this.profile.whatsNew.firstLoad === true) {
      this.profile.whatsNew.firstLoad = false;
      this.profile.whatsNew.save();
      return;
    }

    // this is required for backwards compatibility
    if (this.profile.whatsNew.firstLoad !== false) {
      this.profile.whatsNew.firstLoad = false;
      this.profile.whatsNew.save();
    }

    const whatsNew = await fetch(`https://www.ensemble.chat/assets/whatsnew.json?t=${Date.now()}`)
      .then((response) => {
        return response.json();
      })
      .catch(() => {
        return;
      });
    if (!whatsNew) {
      return;
    }
    const dateObjects = Object.keys(whatsNew).sort((a, b) => { return (new Date(b)).getTime() - (new Date(a)).getTime(); });

    let i = 0;

    while (dateObjects[i]) {
      const finalList = whatsNew[dateObjects[i]].filter(d => d.visible && (d.tags.indexOf('dashboard') < 0 || this.isAdmin)); // leave out not visible or dashboard features unless you have dashboard access;

      if (finalList && finalList.length && (!this.profile.whatsNew.lastSeen || this.profile.whatsNew.lastSeen < (new Date(dateObjects[i])).getTime())) {
        this.openWhatsNew(finalList);
        break;
      }

      i++;
    }
  }

  ngOnDestroy() {
    // if (this.offlineTimeout) {
    //   clearTimeout(this.offlineTimeout);
    // }
    super.ngOnDestroy();
    clearInterval(this.analyticsInterv);
    this.modal.dismissAll();
  }

  ngAfterViewInit() {
    if (this.profile.me) {
      this.me = this.profile.me;
      this.hideLoader();
    }
  }


  openWalkthrough() {
    this.walkModal = this.modal.open(WalkthroughComponent, {
      centered: true,
      keyboard: false,
      windowClass: 'modal-dark walkthrough',
      backdrop: 'static',
      size: 'lg',
      container: 'body',
    });

  }

  toggleMenu() {
    if (this.menuShow) { this.menuShow = false; }
    else { this.menuShow = true; }
  }

  addTeam() {
    this.discover.addTeam();
  }

  joinTeam(teamName?: string) {
    if (this.discover.addModal) {
      this.discover.addModal.dismiss();
    }
    this.joinModal = this.modal.open(JoinTeamComponent, {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      windowClass: 'modal-dark',
    });

    // Go back to home page after the modal is closed, unless in discovery
    this.joinModal.result.catch(() => {
      if (this.discover.discoveringNow) {
        this.router.navigateByUrl('/discover');
      } else {
        this.router.navigateByUrl(`/${this.teamService.activeTeam ? this.teamService.activeTeam.name : ''}`);
      }
    });
  }

  showInvite(teamName?: string, invName?: string) {
    this.invModal = this.modal.open(InvitePreviewComponent, {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      windowClass: 'modal-dark',
      size: 'lg'
    });

    // Go back to home page after the modal is closed
    this.invModal.result.catch((reason) => {
      if (reason === 'manual') {
        // this.log('INVITATION - close window');
        this.router.navigateByUrl(`/${this.teamService.activeTeam ? this.teamService.activeTeam.name : ''}`);
      }
    });
  }

  private showDiscoverTeamModal() {
    // proceed only when NOT CURRENTLY IN DISCOVERY 
    // otherwise when returning from join we get double modals
    if (this.discover.discoveringNow) {
      return;
    }
    if (this.discover.addModal) {
      this.discover.addModal.dismiss();
    }
    this.joinModal = this.modal.open(DiscoverTeamComponent, {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      windowClass: 'modal-dark',
      size: 'lg'
    });

    // Go back to home page after the modal is closed
    this.joinModal.result.catch(() => {
      this.router.navigateByUrl(`/${this.teamService.activeTeam ? this.teamService.activeTeam.name : ''}`);
    });
  }

  createTeam() {
    if (this.discover.addModal) {
      this.discover.addModal.dismiss();
    }
    this.createModal = this.modal.open(CreateTeamComponent, {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      windowClass: 'modal-dark',
      size: 'lg'
    });

    // Go back to home page after the modal is closed
    this.createModal.result.catch(() => {
      this.router.navigateByUrl(`/${this.teamService.activeTeam ? this.teamService.activeTeam.name : ''}`);
    });
  }

  findPanel(id: string) {
    return this.panels.find(c => c.id === id);
  }

  openWhatsNew(whatsNewData: any) {
    const modal = this.modal.open(QuickHelpComponent, {
      centered: true,
      backdrop: 'static',
      windowClass: 'modal-dark'
    });

    modal.componentInstance.whatsNewSlides = whatsNewData;
    modal.result.catch((reason) => {
      if (reason === 'manual') {
        this.profile.whatsNew.lastSeen = Date.now();
        this.profile.whatsNew.save();
      }
    });
  }

  movePanel(event: CdkDragDrop<string[]>) {
    const realPreviousIndex = this.panels.findIndex(p => p.id === event.item.data.id);
    const shift = event.currentIndex - event.previousIndex;
    let shiftIndicator = Math.abs(shift);
    const shiftSign = Math.sign(shift);

    let realCurrentIndex = realPreviousIndex;

    for (let cursor = realCurrentIndex + shiftSign; cursor >= 0 && cursor < this.panels.length && shiftIndicator > 0; cursor = cursor + shiftSign) {
      if (this.isHidden(this.panels[cursor])) {
        continue;
      } else {
        realCurrentIndex = cursor;
        shiftIndicator--;
      }
    }

    if (realPreviousIndex === realCurrentIndex) {
      this.panelsService.resetScrollToSaved$.next(event.item.data.id);
      return;
    }

    moveItemInArray(this.panelsService.panels, realPreviousIndex, realCurrentIndex);
    this.panelsService.panels.save();
    this.panelsService.resetScrollToSaved$.next(event.item.data.id);
  }

  dragStart(event: CdkDragStart<Panel>) {
    this.panelsService.resetScrollToSaved$.next(event.source.data.id);
  }

  isHidden(p: Panel) {
    const isDirectMax = (this.panelsService.maximizedPanels['direct'] && this.panelsService.maximizedPanels['direct'] === p.id);
    const isTeamMax = this.panelsService.maximizedPanels[p.teamId] && this.teamService.activeTeamId === p.teamId && this.panelsService.maximizedPanels[p.teamId] === p.id;
    const noMaximized = !this.panelsService.maximizedPanels['direct'] && !this.panelsService.maximizedPanels[this.teamService.activeTeamId];
    const isInTeamOrDirect = ((p.teamId && this.teamService.activeTeamId === p.teamId) || p.type === this.panelType.Direct);
    const hidden = !(p.visible &&
      (isDirectMax ||
        (isTeamMax ||
          (noMaximized && isInTeamOrDirect))));
    // console.log(`Panel ${p.id} is hidden? ${hidden}. It's visible ${p.visible} and also isDirectMax ${isDirectMax} or isTeamMax ${isTeamMax} or noMaximized ${noMaximized} AND isInTeamOrDirect ${isInTeamOrDirect}`);
    return hidden;
  }

  teamIsExtended(p: Panel) {
    const team = this.teamService.teams.find(t => t.id === p.teamId);
    return team ? (!!team.permissions) : false;
  }

  isMaximized() {
    return !!(this.panelsService.maximizedPanels[this.teamService.activeTeamId] || this.panelsService.maximizedPanels['direct']);
  }

  trackByFn(index, item) {
    return item.id;
  }

  isSM(panel: Panel) {
    if (!this.panelsService.panelsConfig[panel.id]) {
      // return true only if NOT frame/service
      return panel.type !== this.panelType.Frame;
    }
    return this.panelsService.panelsConfig[panel.id].size === 'sm';
  }

  isMD(panel: Panel) {
    if (!this.panelsService.panelsConfig[panel.id]) {
      // return true only for frames/services
      return panel.type === this.panelType.Frame;
    }
    return this.panelsService.panelsConfig[panel.id].size === 'md';
  }

  isLG(panel: Panel) {
    if (!this.panelsService.panelsConfig[panel.id]) return false;
    return this.panelsService.panelsConfig[panel.id].size === 'lg';
  }

  isMax(panel: Panel) {
    if (!this.panelsService.panelsConfig[panel.id]) return false;
    return this.panelsService.isMaximizedPanel(panel);
  }

  async joinDemo() {
    this.router.navigate(['/', 'join'], { queryParams: { team: 'demoteam' } });
  }

  private hideLoader() {
    const loader = this.elRef.nativeElement.ownerDocument.querySelector('.root-loader');
    if (loader) {
      loader.style.display = 'none'; // hide loader
    }
  }


  private showDisclaimerModal() {

    if (!this.teamService.activeTeam) {
      // this.log("Will not show disclaimer. There is no active team")
      return
    }

    if (this.teamService.activeTeam['features'].indexOf('CustomDisclaimers') === -1) {
      // this.log("Will not show disclaimer. Feature is disabled")
      return;
    }

    if (!this.teamService.activeTeam.config ||
      !this.teamService.activeTeam.config['disclaimerEnabled']) {
      // this.log("Will not show disclaimer. Disclaimer is disabled by admin")
      return;
    }

    if (this.teamService.disclaimerAccepted.teamIds.filter(t => t === this.teamService.activeTeam.id).length > 0) {
      // this.log("Will not show disclaimer. User has already accept it")
      return;
    }

    // this.log("Show disclaimer")
    this.hasDisclaimer = true;
    this.modal.open(DisclaimerComponent, {
      centered: true,
      windowClass: 'modal-dark disclaimer',
      backdrop: 'static',
      container: 'body',
    });
  }

  private setupAnalytics() {
    // this.log("Setup Analytics")
    this.angulartics2Amplitude.setUsername(this.profile.me.username);
    this.angulartics2GoogleTagManager.setUsername(this.profile.me.username);
    Sentry.configureScope(scope => {
      scope.setUser({
        id: this.profile.me.id,
        username: this.profile.me.username,
        email: this.profile.me.email
      });
    });
    // amplitude initiates and tracks only in chat
    this.angulartics2Amplitude.startTracking();
    this.tracking.trackEvent('user active', {
      'Desktop Version': Helpers.getElectronVersion()
    });
    this.analyticsInterv = setInterval(() => this.tracking.trackEvent('user online', {
      'Desktop Version': Helpers.getElectronVersion()
    }), 1000 * 60 * 60);
  }

  // private setupUsetiful() {
  //   // this.log("Setup Usetiful")
  //   const s = this.renderer.createElement('script');
  //   s.type = 'text/javascript';
  //   s.text = `
  //     (function (w, d, s) {
  //       var a = d.getElementsByTagName('head')[0];
  //       var r = d.createElement('script');
  //       r.async = 1;
  //       r.src = s;
  //       r.setAttribute('id', 'usetifulScript');
  //       r.dataset.token = "9ee52264a8ba28f4c9b37e16beeb864c";
  //       a.appendChild(r);
  //     })(window, document, "https://www.usetiful.com/dist/usetiful.js");`;
  //   this.renderer.appendChild(document.body, s);
  // }

  private async setupGetSiteControl() {
    // this.log("Setup GetSiteControl");
    const ret = await this.scriptService.loadScript('getsitecontrol');
    // this.log('GETSITECONTROL', ret);
    if (!window['gsc']) {
      return;
    }
    window['gsc']('params', {
      username: this.profileService.me.username,
      email: this.profileService.me.email,
      team: this.teamService.activeTeam ? this.teamService.activeTeam.name : null,
    });
  }

  log(...arg) {
    /* istanbul ignore if  */
    if (environment.config.debug) {
      console.log('%c[COMPONENT: CHAT-SHELL]', 'color:#BF3500', ...arg);
    }
  }
}
