<div class="members-title">Community Directory</div>
<div class="form-group">
    <input id="search" type="search" class="form-control form-control-sm bg-dark border-secondary"
        placeholder="Search member by username" [formControl]="searchField">
    <i *ngIf="searching" class="fal fa-spinner-third fa-spin"></i>
    <div class="borderfx"></div>
</div>
<ng-container *ngIf="teamService.activeTeam && members">
    <ng-container *ngIf="members.length && (!term || term.length!==1); else empty">
        <div #membersList class="members-list" (scroll)="onScroll()">
            <ng-container *ngFor="let role of roles">
                <ng-container *ngIf="membersCounter[role.id] > 0">
                    <div class="members-group">
                        <div class="members-role">
                            <i *ngIf="role.id" [ngStyle]="{color: role.color || defaults.role.color}" class="fa-fw"
                                [ngClass]="resolveFaIcon(role.icon || defaults.role.icon)"></i>
                            <i *ngIf="role.id===null" [ngStyle]="{color:defaults.role.color}"
                                class="fas fa-square fa-fw"></i>
                            <span>
                                {{role.name}}
                            </span>
                            <div class="members-toolbox">
                                <button *ngIf="roleToggle[role.id]" (click)="roleToggle[role.id] = false"
                                    class="btn animated-scaled">
                                    <i class="fas fa-angle-down"></i>
                                </button>
                                <button *ngIf="!roleToggle[role.id]" (click)="roleToggle[role.id] = true"
                                    class="btn animated-scaled">
                                    <i class="fas fa-angle-up"></i>
                                </button>
                            </div>
                        </div>
                        <div class="members-items" [ngClass]="{'closed':!roleToggle[role.id]}">
                            <ng-container *ngFor="let member of members">
                                <ng-container *ngIf="member.roleId == role.id">
                                    <button class="member-item" popoverClass="popover-usermenu"
                                        [placement]="['left', 'left-top', 'left-bottom']" container="body"
                                        [ngbPopover]="popContent" [popoverTitle]="member.user.username">
                                        <div class="member-avatar">
                                            <app-user-avatar [username]="member.user.username"
                                                [avatarUrl]="member.user.avatar">
                                            </app-user-avatar>
                                            <span class="indicator"
                                                [ngClass]="CurrentStatus[member.user.status]"></span>
                                        </div>
                                        <span>{{member.user.username}}</span>
                                    </button>
                                    <ng-template #popContent>
                                        <app-profile-view [member]="member" [userId]="member.userId"
                                            [username]="member.user.username" [teamId]="teamId"
                                            [avatar]="member.user.avatar"></app-profile-view>
                                    </ng-template>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </ng-container>
</ng-container>

<ng-template #empty>
    <span class="error-message">
        <i class="fal fa-exclamation-triangle"></i> <small>No members</small>
    </span>
</ng-template>