import { DragDropModule } from '@angular/cdk/drag-drop';
import { HttpClientModule } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { PinboardMessagesServiceApi } from '@echofin/libraries';
import { WebStorageModule } from '@efaps/ngx-store';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularDraggableModule } from 'angular2-draggable';
import { EmbedVideo } from 'ngx-embed-video';
import { NgxMaskModule } from 'ngx-mask';
import { NgPipesModule } from 'ngx-pipes';
import { ToastrModule } from 'ngx-toastr';
import { DirectLabelPipe } from '../_shared/pipes/direct-channel-members.pipe';
import { SharedModule } from '../_shared/shared.module';
import { AddTeamComponent } from './add-team/add-team.component';
import { BannedTeamUserComponent } from './banned-team-user/banned-team-user.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { CreateTeamComponent } from './create-team/create-team.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { DisconnectedComponent } from './disconnected/disconnected.component';
import { DiscoverTeamComponent } from './discover-team/discover-team.component';
import { DownloadClientsComponent } from './download-clients/download-clients.component';
import { IsTypingComponent } from './is-typing/is-typing.component';
import { TypingService } from './is-typing/services/typing.service';
import { JoinTeamComponent } from './join-team/join-team.component';
import { LeaveTeamComponent } from './leave-team/leave-team.component';
import { LightboxComponent } from './lightbox/lightbox.component';
import { MarkdownComponent } from './markdown/markdown.component';
import { MarketOverviewComponent } from './market-overview/market-overview.component';
import { MarketplaceComponent } from './marketplace/marketplace.component';
import { MemberDirectoryComponent } from './member-directory/member-directory.component';
import { PinboardMessageCountComponent } from './message-count/pinboard-message-count/pinboard-message-count.component';
import { RoomMessageCountComponent } from './message-count/room-message-count/room-message-count.component';
import { MessageCountService } from './message-count/services/message-count.service';
import { TeamMessageCountComponent } from './message-count/team-message-count/team-message-count.component';
import { MessageShareComponent } from './message-share/message-share.component';
import { MultilineModalComponent } from './multiline-modal/multiline-modal.component';
import { ChatroomNotificationSettingsComponent } from './notifications/chatroom-notification-settings/chatroom-notification-settings.component';
import { NotificationsSettingsComponent } from './notifications/notifications-settings/notifications-settings.component';
import { NotificationsService } from './notifications/notifications-settings/services/notifications.service';
import { NotificationsComponent } from './notifications/notifications.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { PanelBookmarkMessagesComponent } from './panel-bookmark-messages/panel-bookmark-messages.component';
import { BillingCardFormComponent } from './panel-chatroom/billing-card-form/billing-card-form.component';
import { BillingConfirmComponent } from './panel-chatroom/billing-confirm/billing-confirm.component';
import { ChatboxComponent } from './panel-chatroom/chatbox/chatbox.component';
import { LockPanelComponent } from './panel-chatroom/lock-panel/lock-panel.component';
import { PanelChatroomComponent } from './panel-chatroom/panel-chatroom.component';
import { PanelErrorComponent } from './panel-error/panel-error.component';
import { PanelFilesComponent } from './panel-files/panel-files.component';
// import { PanelGeneralComponent } from './panel-general/panel-general.component';
import { PanelHeaderComponent } from './panel-header/panel-header.component';
import { PanelMentionedMessagesComponent } from './panel-mentioned-messages/panel-mentioned-messages.component';
import { PanelPinboardMessagesComponent } from './panel-pinboard-messages/panel-pinboard-messages.component';
import { PanelReplyMessageComponent } from './panel-reply-message/panel-reply-message.component';
import { PanelSearchMessagesComponent } from './panel-search-messages/panel-search-messages.component';
import { SearchFilterComponent } from './panel-search-messages/search-filter/search-filter.component';
import { LockComponent } from './panel-service/lock/lock.component';
import { PanelServiceComponent } from './panel-service/panel-service.component';
import { ProfileBarComponent } from './profile-bar/profile-bar.component';
import { ProfileViewComponent } from './profile-view/profile-view.component';
import { QuickHelpComponent } from './quick-help/quick-help.component';
import { ReloadComponent } from './reload/reload.component';
import { RightbarComponent } from './rightbar/rightbar.component';
import { SecretFormComponent } from './secret-form/secret-form.component';
import { ShareLinkComponent } from './share-link/share-link.component';
import { SidebarComponent } from './sidebar/sidebar.component';
// import { TermsAndConditionsComponent } from './sidebar/terms-and-conditions/terms-and-conditions.component';
import { SignalComponent } from './signal/signal.component';
import { StartConversationComponent } from './start-conversation/start-conversation.component';
import { StreamJitsiComponent } from './stream-jitsi/stream-jitsi.component';
import { StreamComponent } from './stream/stream.component';
import { TeamInfoComponent } from './team-info/team-info.component';
// import { TeamDisclaimerComponent } from './team-disclaimer/team-disclaimer.component';
import { TeambarComponent } from './teambar/teambar.component';
import { TermsComponent } from './terms/terms.component';
import { DailyDate } from './timeline/daily-date/daily-date.component';
import { MessageFooterComponent } from './timeline/message-footer/message-footer.component';
import { MessageHeaderComponent } from './timeline/message-header/message-header.component';
import { MessageHoverMenuComponent } from './timeline/message-hover-menu/message-hover-menu.component';
import { ContentCashtagComponent } from './timeline/message/content-cashtag/content-cashtag.component';
import { ContentMarketAnalysisComponent } from './timeline/message/content-market-analysis/content-market-analysis.component';
import { ContentMentionComponent } from './timeline/message/content-mention/content-mention.component';
import { ContentQuoteComponent } from './timeline/message/content-quote/content-quote.component';
import { ContentSecretComponent } from './timeline/message/content-secret/content-secret.component';
import { ContentShoutComponent } from './timeline/message/content-shout/content-shout.component';
import { ContentSignalComponent } from './timeline/message/content-signal/content-signal.component';
import { FileAttachmentComponent } from './timeline/message/file-attachment/file-attachment.component';
import { MessageComponent } from './timeline/message/message.component';
import { TradingviewLinkComponent } from './timeline/message/tradingview-link/tradingview-link.component';
import { TtsAttachmentComponent } from './timeline/message/tts-attachment/tts-attachment.component';
import { UrlAttachmentComponent } from './timeline/message/url-attachment/url-attachment.component';
import { TimelineComponent } from './timeline/timeline.component';
import { UserAvatarComponent } from './user-avatar/user-avatar.component';
import { PreferencesAdvancedComponent } from './user-preferences/preferences-advanced/preferences-advanced.component';
import { PreferencesBillingComponent } from './user-preferences/preferences-billing/preferences-billing.component';
import { PreferencesMuteComponent } from './user-preferences/preferences-mute/preferences-mute.component';
import { PreferencesNotificationsComponent } from './user-preferences/preferences-notifications/preferences-notifications.component';
import { PreferencesProfileComponent } from './user-preferences/preferences-profile/preferences-profile.component';
import { UserPreferencesComponent } from './user-preferences/user-preferences.component';
import { ChatResolver } from './_commons/chat.resolver';
import { SpinnerComponent } from './_commons/components/spinner/spinner.component';
import { ChatShellComponent } from './_shell/chat-shell.component';
import { InvitePreviewComponent } from './invite-preview/invite-preview.component';
import { PersonalInviteComponent } from './personal-invite/personal-invite.component';
import { AutocompleteListComponent } from './panel-chatroom/chatbox/autocomplete-list/autocomplete-list.component';
import { PanelPulseComponent } from './panel-pulse/panel-pulse.component';
import { PanelSignalsComponent } from './panel-signals/panel-signals.component';
// import { EditMessageComponent } from './edit-message/edit-message.component';
import { WalkthroughComponent } from './walkthrough/walkthrough.component';
import { GiphyComponent } from './giphy/giphy.component';

@NgModule({
  imports: [
    SharedModule,
    BrowserAnimationsModule,
    ToastrModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    WebStorageModule,
    NgPipesModule,
    AngularDraggableModule,
    DragDropModule,
    NgxMaskModule.forRoot(),
    EmbedVideo.forRoot(),
    RouterModule.forChild([]),
  ],
  declarations: [
    // TeamDisclaimerComponent,
    // TermsAndConditionsComponent,
    AddTeamComponent,
    BannedTeamUserComponent,
    BillingCardFormComponent,
    BillingConfirmComponent,
    ChatboxComponent,
    ChatroomNotificationSettingsComponent,
    ChatShellComponent,
    ConfirmComponent,
    ContentCashtagComponent,
    ContentMarketAnalysisComponent,
    ContentMentionComponent,
    ContentQuoteComponent,
    ContentSecretComponent,
    ContentShoutComponent,
    ContentSignalComponent,
    CreateTeamComponent,
    DailyDate,
    DisclaimerComponent,
    DisconnectedComponent,
    DiscoverTeamComponent,
    DownloadClientsComponent,
    FileAttachmentComponent,
    IsTypingComponent,
    JoinTeamComponent,
    LeaveTeamComponent,
    LightboxComponent,
    LockComponent,
    LockPanelComponent,
    MarkdownComponent,
    MarketOverviewComponent,
    MarketplaceComponent,
    MemberDirectoryComponent,
    MessageComponent,
    MessageFooterComponent,
    MessageHeaderComponent,
    MessageHoverMenuComponent,
    MessageShareComponent,
    MultilineModalComponent,
    NotificationsComponent,
    NotificationsSettingsComponent,
    OnboardingComponent,
    PanelBookmarkMessagesComponent,
    PanelChatroomComponent,
    PanelErrorComponent,
    PanelFilesComponent,
    // PanelGeneralComponent,
    PanelHeaderComponent,
    PanelMentionedMessagesComponent,
    PanelPinboardMessagesComponent,
    PanelReplyMessageComponent,
    PanelSearchMessagesComponent,
    PanelServiceComponent,
    PinboardMessageCountComponent,
    PreferencesAdvancedComponent,
    PreferencesBillingComponent,
    PreferencesMuteComponent,
    PreferencesNotificationsComponent,
    PreferencesProfileComponent,
    ProfileBarComponent,
    InvitePreviewComponent,
    ProfileViewComponent,
    QuickHelpComponent,
    ReloadComponent,
    RightbarComponent,
    RoomMessageCountComponent,
    SearchFilterComponent,
    SecretFormComponent,
    ShareLinkComponent,
    SidebarComponent,
    SignalComponent,
    SpinnerComponent,
    StartConversationComponent,
    StreamComponent,
    StreamJitsiComponent,
    TeambarComponent,
    TeamInfoComponent,
    TeamMessageCountComponent,
    TermsComponent,
    TimelineComponent,
    TradingviewLinkComponent,
    TtsAttachmentComponent,
    UrlAttachmentComponent,
    UserAvatarComponent,
    UserPreferencesComponent,
    PersonalInviteComponent,
    AutocompleteListComponent,
    PanelPulseComponent,
    PanelSignalsComponent,
    WalkthroughComponent,
    GiphyComponent,
  ],
  providers: [
    ChatResolver,
    NotificationsService,
    PinboardMessagesServiceApi,
    MessageCountService,
    TypingService,
    DirectLabelPipe
  ]
})
export class ChatModule {
  constructor(injector: Injector) {
    const cashtag = createCustomElement(ContentCashtagComponent, { injector });
    customElements.define('app-content-cashtag', cashtag);
    const mention = createCustomElement(ContentMentionComponent, { injector });
    customElements.define('app-content-mention', mention);
    const quote = createCustomElement(ContentQuoteComponent, { injector });
    customElements.define('app-content-quote', quote);
  }
}
