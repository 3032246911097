import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Events } from '@echofin/libraries';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ProfileService } from '../../_core/services/profile.service';
import { TeamService } from '../../_core/services/team.service';
import { UserStatus, LocalUserStatusChanged } from '../../_shared/models/enums/user-status';
import { BaseComponent } from '../base-component';
import { UserPreferencesComponent } from '../user-preferences/user-preferences.component';
import { QuickHelpComponent } from '../quick-help/quick-help.component';
import { CurrentStatus } from './../../_shared/models/enums/current-status';
import { WalkthroughComponent } from '../walkthrough/walkthrough.component';

@Component({
  selector: 'app-profile-bar',
  templateUrl: './profile-bar.component.html',
  styleUrls: ['./profile-bar.component.scss'],
})
export class ProfileBarComponent extends BaseComponent implements OnInit {
  status: string = null;
  walkModal: NgbModalRef;
  currentTheme: string = 'dark';

  constructor(
    private teamService: TeamService,
    private profileService: ProfileService,
    private router: Router,
    private modal: NgbModal,
  ) {
    super();
  }

  ngOnInit() {

    this.subscribe(
      this.profileService.userStatusChanged$,
      (data: LocalUserStatusChanged) => {
        if (this.profileService.me.id === data.userId) {
          this.status = CurrentStatus[data.currentStatus];
        }
      });

    this.subscribe(
      this.profileService.avatarChanged$,
      (data: Events.AvatarUpdated) => {
        if (this.me.id === data.userId) {
          this.me.avatar = data.avatar;
        }
      });
  }

  async setUserStatus(status: UserStatus) {
    this.status = UserStatus[status];
    await this.profileService.setUserStatus(this.profileService.me.id, status);
  }

  get me() {
    return this.profileService.me;
  }

  get activeTeam() {
    return this.teamService.activeTeam;
  }

  async logout() {
    const options = this.teamService.activeTeam ? {
      queryParams: {
        team: this.teamService.activeTeam.name
      }
    } : {};
    await this.router.navigate(['/logout'], options);
  }

  openDashboard() {
    window.open('https://dashboard.ensemble.chat/login', '_blank');
  }

  openWalkthrough() {
    this.walkModal = this.modal.open(WalkthroughComponent, {
      centered: true,
      keyboard: false,
      windowClass: 'modal-dark walkthrough',
      backdrop: 'static',
      size: 'lg',
      container: 'body',
    });
  }

  openUserPreferencesModal() {
    const modal = this.modal.open(UserPreferencesComponent, {
      centered: true,
      windowClass: 'modal-dark',
      size: 'lg',
    });
    modal.componentInstance.me = this.profileService.me;
  }

  openQuickHelpModal() {
    const modal = this.modal.open(QuickHelpComponent, {
      centered: true,
      backdrop: 'static',
      windowClass: 'modal-dark'
    });
  }

  switchTheme() {
    if (!this.profileService.workspace.theme
      || this.profileService.workspace.theme === 'light') {
      document.documentElement.setAttribute('data-theme', 'dark');
      this.profileService.workspace.theme = 'dark';
      this.profileService.workspace.save();

    } else {
      document.documentElement.setAttribute('data-theme', 'light');
      this.profileService.workspace.theme = 'light';
      this.profileService.workspace.save();
    }

    this.currentTheme = this.profileService.workspace.theme;
  }

  // showTaC() {
  //   this.profileService.resetTermsAndConditionsAcceptance();
  // }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
