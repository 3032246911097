<div *ngIf="message" class="message-wrapper" [ngClass]="{'isCollapsed':message?.isCollapsed}">
  <app-message-header *ngIf="!message.isCollapsed" [sender]="message.sender" [status]="message.status"
    [collapsed]="message.isCollapsed" [timestamp]="message.timestamp" [timelineType]="timelineType"
    [showAvatarPopover]="showAvatarPopover" [showNamePopover]="showNamePopover" [messageId]="message.id"
    [msgVersion]="message.localVersion">
  </app-message-header>
  <div class="message-wrapper-body" [title]="message.status === status.FAILED? 'Message failed!' : ''"
    [attr.data-messageId]="message.id">
    <div id="message-{{message.id}}" [attr.data-user]="message.sender.id" [attr.data-chatroom]="message.chatroom.id"
      class="message" [ngClass]="{'edited-indicator':message.edited}"
      [attr.data-message-delivered]="status[message.status]==='DELIVERED'">
      <div class="msg-box">
        <div class="time_pv">{{message.timestamp | date:"HH:mm"}}</div>
        <div class="msg-content" [ngClass]="{'failed-message': message.status === status.FAILED}">
          <div *ngIf="!message.isCollapsed && message.chatroom.id && showRoomInfo" class="message-channel">
            <i *ngIf="message.chatroom.type === 'Team'" class="fas fa-hashtag"></i>
            <i *ngIf="message.chatroom.type === 'Direct' || message.chatroom.type === 'Group'"
              class="fas fa-at"></i>{{message.chatroom.name}}
            <i class="fal fa-external-link hand-cursor open-other-panel" [attr.data-chatroomId]="message.chatroom.id"
              [attr.data-timestamp]="message.timestamp.toISOString()"></i>
          </div>
          <div app-message-hover-menu *ngIf="messageHovering || menuHovering" [messageId]="message.id"
            [bookmarked]="message.bookmarked" [pinned]="message.pinned" [timelineType]="timelineType"
            [channelId]="message.chatroom.id" [canEdit]="allowEdit" [canDelete]="canDelete" [canPin]="canPin"
            [sender]="message.sender" [messageType]="message.type" [messageText]="message.text" [canPost]="canPost"
            [panelId]="panelId" [chatroomType]="message.chatroom.type" [isReplyPanel]="isReplyPanel"
            (menuHoverChange)="onMenuHoverChange($event)">
          </div>

          <ng-container [ngSwitch]="message.type">
            <p *ngSwitchCase="type.TEXT" [innerHTML]="parsedHtml"></p>
            <app-content-market-analysis *ngSwitchCase="type.MARKET_ANALYSIS" [files]="message.files"
              [messageStatus]="message.status" [urlData]="message.urlData" [parsedHtml]="parsedHtml"
              [fullView]="fullView" [fullViewType]="fullViewType" [messageId]="message.id">
            </app-content-market-analysis>
            <app-content-shout *ngSwitchCase="type.SHOUT" [parsedHtml]="parsedHtml"></app-content-shout>
            <ng-container *ngSwitchCase="type.SECRET">
              <p [innerHTML]="parsedHtml"></p>
              <app-content-secret [text]="message.secret"></app-content-secret>
            </ng-container>
            <!-- <app-content-signal *ngSwitchCase="type.SIGNAL" [hasText]="!!(message.text && message.text.trim().length)"
              [parsedHtml]="parsedHtml" [files]="message.files" [messageStatus]="message.status"
              [signal]="message.signal" [searchText]="searchText" [timelineType]="timelineType" [fullView]="fullView"
              [fullViewType]="fullViewType" [messageId]="message.id">
            </app-content-signal> -->
          </ng-container>

          <ng-container
            *ngIf="message.type !== type.MARKET_ANALYSIS && message.files && message.files.length">
            <div *ngIf="message.status === status.SEND" class="text-center p-4 text-secondary">
              <i class="fas fa-cog fa-3x fa-fw fa-spin"></i>
              <br>
              <small>Uploading...</small>
            </div>
            <app-file-attachment *ngIf="message.status === status.DELIVERED" [attachments]="message.files"
              [fullView]="fullView && fullViewType==='file'" [messageId]="message.id">
            </app-file-attachment>
          </ng-container>

          <ng-container *ngIf="message.urlData && message.type !== type.MARKET_ANALYSIS">
            <app-tradingview-link *ngIf="message.urlData.type==='TRADINGVIEW'" [urlData]="message.urlData"
              [messageId]="message.id" [showOpenButton]="tradingViewMouseOver">
            </app-tradingview-link>
            <app-url-attachment
              *ngIf="message.urlData.type==='YOUTUBE'||message.urlData.type==='GENERAL'||message.urlData.type==='IMAGE'"
              [urlData]="message.urlData" [fullView]="fullView && fullViewType==='url'" [messageId]="message.id">
            </app-url-attachment>
          </ng-container>

          <!-- <div class="" class="secret-wrapper" *ngIf="messageStatuses[message.status]==='FAILED'">
              <span class="title text-danger" style="margin-top: 0px;">Message failed!</span>
            </div> -->
          <!-- <div class="bg-danger p2 text-monospace">{{message.id}}</div> -->
        </div>
      </div>
    </div>
    <div *ngIf="message.status === status.FAILED" class="message-fail-label text-right text-danger">Message failed!
    </div>
    <app-message-footer *ngIf="(message.reactions && message.reactions.length)||(!!message.reply && !isReplyPanel)"
      [messageId]="message.id" [panelId]="panelId" [reactions]="message.reactions" [reply]="message.reply"
      [timelineType]="timelineType" [msgVersion]="message.localVersion" [mouseoverReaction]="overReaction">
    </app-message-footer>
  </div>
</div>