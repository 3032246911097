<div class="header" [ngClass]="{'isMaximized':maximized, 'draggable':!maximized}">

    <div class="header-wrapper" [ngStyle]="{'borderTopColor':color}">
        <span *ngIf="panelIcon" class="header-icons flex-grow-0">
            <ng-container
                *ngIf="(panel.type!==panelType.Chatroom && panel.type!==panelType.Direct && panel.type!==panelType.SignalMessages) || chatroomType==='Team' || chatroomType==='Direct'">
                <i [ngClass]="panelIcon"></i>
            </ng-container>
            <ng-container *ngIf="panel.type===panelType.Direct && chatroomType==='Group'">
                <i class="fa-swap-opacity" [ngClass]="panelIcon"></i>
            </ng-container>
            <ng-container *ngIf="panel.type===panelType.SignalMessages">
                <svg version="1.1" id="signal-stream" focusable="false" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 576 576"
                    style="enable-background:new 0 0 576 576;" xml:space="preserve">
                    <path
                        d="M339.4,279.3l-80.4-23c-10.9-3.1-19.8-11.9-21.5-23.2c-2.7-17.6,10.9-32.8,28-32.8h51c11,0,21.8,3.5,30.7,9.8
                c4.4,3.1,10.4,2.6,14.2-1.2l15.8-15.8c4.7-4.7,4.2-12.6-1-16.7c-17.1-13.2-37.8-20.3-59.7-20.3h-1.9v-33.2c0-6.1-5-11.1-11.1-11.1
                h-22.1c-6.1,0-11.1,5-11.1,11.1v33.2h-1.7c-34,0-65.4,22-73.6,55.1c-9.6,38.9,14,76.9,50.5,87.4l80.4,23
                c10.9,3.1,19.8,11.9,21.5,23.2c2.7,17.6-10.9,32.8-28,32.8h-51c-11,0-21.8-3.5-30.7-9.8c-4.4-3.1-10.4-2.6-14.2,1.2l-15.8,15.8
                c-4.7,4.7-4.2,12.6,1,16.7c17,13.2,37.8,20.3,59.7,20.3h1.9v33.2c0,6.1,5,11.1,11.1,11.1h22.1c6.1,0,11.1-5,11.1-11.1v-33.2h1.7
                c34,0,65.4-22,73.6-55.1C399.5,327.8,375.9,289.8,339.4,279.3L339.4,279.3z M100.8,108.7L89.6,97.4c-3-3.1-8-3.2-11.1-0.1
                c-0.1,0.1-0.2,0.2-0.2,0.2C31.3,148.2,5.5,214.1,5.5,284s25.8,135.8,72.8,186.5c3,3.2,8,3.3,11.1,0.3c0.1-0.1,0.2-0.2,0.2-0.2
                l11.2-11.3c3-3.1,3-8,0.1-11.1C59.6,403.6,37,345.5,37,284s22.6-119.5,63.9-164.2C103.8,116.7,103.8,111.8,100.8,108.7z
                 M163.4,176.6l-11.1-11.3c-3-3.1-8-3.2-11.1-0.2c-0.1,0.1-0.2,0.2-0.3,0.3c-60.1,67.6-60.1,169.4,0,237c2.9,3.2,7.9,3.4,11.1,0.5
                c0.1-0.1,0.2-0.2,0.3-0.3l11.1-11.3c2.9-3.1,3-7.9,0.1-11c-48.4-55.1-48.4-137.5,0-192.6C166.4,184.5,166.3,179.8,163.4,176.6
                L163.4,176.6z M499.7,97.5c-3-3.2-8-3.3-11.1-0.3c-0.1,0.1-0.2,0.2-0.2,0.2l-11.2,11.3c-3,3.1-3,8-0.1,11.1
                C518.3,164.5,541,222.5,541,284s-22.6,119.5-63.9,164.2c-2.9,3.1-2.9,8,0.1,11.1l11.2,11.3c3,3.1,8,3.2,11.1,0.2
                c0.1-0.1,0.2-0.2,0.2-0.2c47-50.7,72.8-116.6,72.8-186.5S546.7,148.2,499.7,97.5z M425.7,165.4l-11.1,11.3c-2.9,3.1-3,7.9-0.1,11
                c48.4,55.1,48.4,137.5,0,192.6c-2.9,3.1-2.8,8,0.1,11l11.1,11.3c3,3.1,8,3.2,11.1,0.2c0.1-0.1,0.2-0.2,0.3-0.3
                c60.1-67.6,60.1-169.4,0-237c-2.9-3.2-7.9-3.4-11.1-0.5C425.9,165.1,425.8,165.2,425.7,165.4L425.7,165.4z" />
                </svg>
            </ng-container>
        </span>
        <span class="header-title flex-grow-1">
            {{title}}<span *ngIf="panel.type===panelType.Direct && chatroomType==='Direct'"
                class="ml-2 dot {{colorClassByStatus()}}"></span>
        </span>
        <div class="header-toolbox flex-grow-0 d-flex flex-nowrap">

            <button *ngIf="panel.type===panelType.Frame" (click)="refresh()" class="syncIcon btn">
                <i class="far fa-sync-alt" [ngClass]="{'fa-spin': refreshing}"></i>
            </button>
            <div *ngIf="panel.type===panelType.Frame" ngbDropdown placement="bottom-right" container="body"
                class="d-inline-block">
                <button ngbDropdownToggle class="btn">
                    <i class="fas fa-cog"></i>
                </button>
                <div ngbDropdownMenu>
                    <h6 class="dropdown-header p-1">Panel Size</h6>
                    <div class="dropdown-divider"></div>
                    <button ngbDropdownItem (click)="configure('sm')"
                        class="btn btn-block btn-sm">Compact</button>
                    <button ngbDropdownItem (click)="configure('md')"
                        class=" btn btn-block btn-sm">Large</button>
                    <button ngbDropdownItem (click)="configure('lg')"
                        class=" btn btn-block btn-sm">Wide</button>
                </div>
            </div>

            <button (click)="openChatroomNotificationModal()"
                *ngIf="(panel.type===panelType.Chatroom||panel.type===panelType.Direct) && canReadChatroom"
                class="btn btn-notifications-panel">
                <i class="far fa-bell"></i>
            </button>

            <button (click)="headerAction('maximize')" *ngIf="!maximized" class="btn btn-maximize-panel">
                <i class="far fa-window-maximize"></i>
            </button>
            <button (click)="headerAction('minimize')" *ngIf="maximized" class="btn btn-minimize-panel">
                <i class="fal fa-columns"></i>
            </button>
            <button (click)="headerAction('close')" class="btn btn-close-panel">
                <i class="fa fa-times"></i>
            </button>
        </div>
    </div>
    <div class="progress"
        *ngIf="(panel.type===panelType.ReplyMessage||panel.type===panelType.Chatroom||panel.type===panelType.Direct) && loading">
        <div class="progress-bar progress-bar-striped progress-bar-animated"
            [ngStyle]="{'background-color':colorize()}">
        </div>
    </div>
</div>