import { Injectable, NgZone } from '@angular/core';
import { Angulartics2Amplitude } from 'angulartics2/amplitude';
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { fromEvent, merge, Observable, Subscription } from 'rxjs';
import { auditTime, filter } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Helpers } from '../../_shared/helpers/helpers';
import { SocketService } from './socket.service/socket.service';

@Injectable({
  providedIn: 'root',
})
export class AliveSessionService {

  // keeping a local version of lastAlive to decide when to send alive event through socket
  lastAlive = new Date(0);

  private defaultEvents: string[] = [
    'mousemove',
    'keydown',
    'mousedown'
  ];
  private obs: Observable<Event>[] = [];
  private timeout: number = 1 * 60 * 1000;

  mergedEvents: Observable<any>;

  sub: Subscription;

  constructor(
    private socketService: SocketService,
    private ngZone: NgZone,
    private angulartics2Amplitude: Angulartics2Amplitude,
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager
  ) { }

  // IMPORTANT: THIS IDLE TIMEOUT SERVICE, CONTROLS THE IDLE CONNECTION STATUS ONLY FOR THE DEVICE IT RUNS ON!
  // IT SHOULD NOT SET THE PROFILE USER/CURRENT STATUS UNDER ANY CIRCUMSTANCES, AS IT CONCERNS THE USER IN ALL DEVICES.
  // CURRENT STATUS INDICATORS ON CLIENT SHOULD CHANGE ONLY BY SOCKET VALUES AND NOT IMMEDIATELY ON THE CLIENT

  setup() {
    // this.log('setup');
    this.ngZone.runOutsideAngular(() => {
      this.obs = this.defaultEvents.map(eventName => fromEvent(document, eventName));

      // passing as array in merge does not work!
      this.mergedEvents = merge(this.obs[0], this.obs[1], this.obs[2]);

      this.mergedEvents.pipe(
        filter((x) => {
          if (this.isIdle()) {
            this.lastAlive = new Date();
            this.socketService.alive();
            this.trackEvent('user active', {
              'Desktop Version': Helpers.getElectronVersion()
            });
            // this.log('Set user active immediately', x);
            return false;
          }
          return true;
        }),
        // buffer events for one minute before emitting last event
        auditTime(this.timeout)
      ).subscribe(x => {
        this.lastAlive = new Date();
        this.socketService.alive();
        this.trackEvent('user active', {
          'Desktop Version': Helpers.getElectronVersion()
        });
        // this.log('Set user active', x);
      });
    });

    // // this starts the timer to set idle to true if no other action has happened after socket connect
    // this.initialEvent.next();
  }

  private isIdle() {
    const diff = (new Date).getTime() - this.lastAlive.getTime();
    return diff > this.timeout;
  }

  private trackEvent(name, params = null) {
    if (environment.config.name !== 'echofin') return;
    this.angulartics2Amplitude.eventTrack(name, params || {});
    this.angulartics2GoogleTagManager.eventTrack(name, params || {});
  }

  log(...arg) {
    /* istanbul ignore if  */
    if (environment.config.debug) {
      console.log('%c[IDLE]', 'color:#0088AA', ...arg);
    }
  }
}
