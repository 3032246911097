import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ChangeDetectorRef, OnDestroy, ChangeDetectionStrategy, ViewChildren, QueryList, Renderer2, HostListener, NgZone } from '@angular/core';
import { environment } from '../../../environments/environment';
import { RequestOlder, RequestOlderType, RequestNewer, RequestNewerType } from '../../_shared/models/message/request-older';
import { Message } from './../../_shared/models/commons/message';
import { TimelineType } from './models/timeline-type.enum';
import { ProfileService } from '../../_core/services/profile.service';
import { BaseComponent } from '../base-component';
import { PanelsService } from '../../_core/services/panels.service';
import { TeamService } from '../../_core/services/team.service';
import { MessageService, MESSAGES_PER_PAGE } from '../../_core/services/message.service';
import { MessageCountService } from '../message-count/services/message-count.service';
import { Subject } from 'rxjs';
import { debounceTime, skipWhile, throttleTime } from 'rxjs/operators';
import { NgbDate, NgbInputDatepicker, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StreamComponent } from '../stream/stream.component';
import { RoomService } from '../../_core/services/room.service';
import { PanelFilesService } from '../panel-files/services/panel-files.service';
import { MentionedMessagesService } from '../panel-mentioned-messages/services/mentioned-messages.service';
import { PinboardMessagesService } from '../panel-pinboard-messages/services/pinboard-messages.service';
import { SearchMessagesService } from '../panel-search-messages/services/search-messages.service';
import { BookmarkMessagesService } from '../panel-bookmark-messages/services/bookmark-messages.service';
import { IntervalUpdateTimerService } from '../../_core/services/interval-update-timer.service';
import { PanelType } from '../../_shared/models/room/channel';
import { ModalsService } from '../../_core/services/modals.service';
import { EmojiService } from '../../_core/services/emoji.service';
import { TrackingService } from '../../_core/services/tracking.service';
import { PulseMessagesService } from '../../_core/services/pulse-messages.service';
import { SignalsMessagesService } from '../../_core/services/signals-messages.service';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimelineComponent extends BaseComponent implements OnInit, OnDestroy {
  timelineTypeEnum = TimelineType;
  public static SCROLL_OLDER_HEIGHT: number = 20;
  public static SCROLL_BOTTOM_EXTRA: number = 100;
  @ViewChild('chatbox', { static: true }) chatbox: ElementRef;
  @ViewChild('newMessagesLine') newMessagesLine: ElementRef;

  @ViewChildren('msg', { read: ElementRef }) messageElements: QueryList<ElementRef>;

  ishovering: boolean;
  selectedDate: Date;
  maxDate = new Date();
  minDate: Date;

  @Output() public requestTimeframe = new EventEmitter<Date>();

  @Input() topicHeight: number;

  @Input() isLocked: boolean;
  @Input() searchText: string;

  @Input() canDelete: boolean;
  @Input() canPin: boolean;
  @Input() canPost: boolean;

  @Input() initialTimelineMessage: string;
  @Input() timelineType: TimelineType;
  @Input() showRoomInfo: boolean = false;

  @Input() noResultsMessage: string;

  @Input() panelId: string;
  @Input() teamId: string;

  _loadingBetween: Date;
  @Input()
  set loadingBetween(val: Date) {
    this._loadingBetween = val;
    // needs detect changes to scroll down properly
    this.cd.detectChanges();
  }
  get loadingBetween() {
    return this._loadingBetween;
  }

  @Input()
  unreadCount: number;
  @Input()
  unreadTimestamp: string;
  @Input()
  roomLastActive: string;

  initialMessageToScroll: Message;

  that = this;

  loadingMore = false;

  disableScrollEventsAfterDateLoad = false;

  currentYear = (new Date()).getFullYear();


  messageHoveringId = null;
  // tradingViewMouseOver = false;
  @HostListener('mouseleave')
  centralMouseleave() {
    this.panelService.messageMouseoverTradingView$.next([this.panelId, null, false, null]);
  }

  messageClickedId = null;
  popoverElement = null;
  showFullLightboxId = null;
  showFullLightboxType = null;
  tradingViewModal: any = null;
  @HostListener('click', ['$event'])
  async centralClick(event) {
    this.messageClickedId = null;
    this.popoverElement = null;
    if (event.target.className === 'profile-username') {
      const msgId = event.target.parentElement.parentElement.parentElement?.getAttribute('data-messageId');
      this.popoverElement = 'name';
      this.messageClickedId = msgId;
      // we must reset the variables to work again immediately
      // but we need cd before we do to trigger the input change and show popover
      this.cd.detectChanges();
      this.messageClickedId = null;
      this.popoverElement = null;
    } else if (event.target.parentElement.parentElement?.className === 'member-avatar no-indicator clickable') {
      const msgId = event.target.parentElement.parentElement.parentElement?.getAttribute('data-messageId');
      this.popoverElement = 'avatar';
      this.messageClickedId = msgId;
      // we must reset the variables to work again immediately
      // but we need cd before we do to trigger the input change and show popover
      this.cd.detectChanges();
      this.messageClickedId = null;
      this.popoverElement = null;
    } else if (event.target.classList.contains('reaction-pill')) {
      const msgId = event.target.getAttribute('data-messageId');
      const emoji = event.target.getAttribute('data-reaction');
      this.tracking.trackEvent('reaction', {
        messageid: msgId,
        teamname: this.teamService.activeTeam?.name
      });
      this.messageService.toggleReaction(msgId, emoji);
    } else if (event.target.parentElement.classList.contains('reaction-pill')) {
      const msgId = event.target.parentElement.getAttribute('data-messageId');
      const emoji = event.target.parentElement.getAttribute('data-reaction');
      this.tracking.trackEvent('reaction', {
        messageid: msgId,
        teamname: this.teamService.activeTeam?.name
      });
      this.messageService.toggleReaction(msgId, emoji);
    } else if (event.target.classList.contains('reply-pill')) {
      const panelId = event.target?.getAttribute('data-replyPanelId');
      this.loadReplies(panelId);
    } else if (event.target.parentElement.classList.contains('reply-pill')) {
      const panelId = event.target.parentElement.getAttribute('data-replyPanelId');
      this.loadReplies(panelId);
    } else if (event.target.classList.contains('reactions-icon')) {
      const msgId = event.target?.getAttribute('data-messageId');
      this.emojiService.openMessagePicker(event.target, msgId);
    } else if (event.target.parentElement.classList.contains('lightbox-thumb')) {
      this.showFullLightboxId = event.target.parentElement.getAttribute('data-messageId');
      this.showFullLightboxType = event.target.parentElement.getAttribute('data-type');
    } else if (event.target.parentElement.parentElement?.classList.contains('lightbox-thumb')) {
      this.showFullLightboxId = event.target.parentElement.parentElement.getAttribute('data-messageId');
      this.showFullLightboxType = event.target.parentElement.parentElement.getAttribute('data-type');
    } else if (event.target.parentElement.parentElement?.parentElement?.classList.contains('lightbox-thumb')) {
      this.showFullLightboxId = event.target.parentElement.parentElement.parentElement.getAttribute('data-messageId');
      this.showFullLightboxType = event.target.parentElement.parentElement.parentElement.getAttribute('data-type');
    } else if (event.target.classList.contains('lightbox-full') || (event.target.parentElement as HTMLElement).classList.contains('lightbox-full')) {
      this.showFullLightboxId = null;
      this.showFullLightboxType = null;
    } else if (event.target.parentElement.classList.contains('time-format')) {
      const showRelativeTimeFormat = event.target.parentElement.getAttribute('data-timeFormat') === 'true';
      this.updateTimeService.showRelativeTimeFormat.next(!showRelativeTimeFormat);
    } else if (event.target.classList.contains('open-other-panel')) {
      const chatroomId = event.target?.getAttribute('data-chatroomId');
      const timestamp = event.target?.getAttribute('data-timestamp');
      const date = new Date(timestamp);
      this.openChat(chatroomId, date);
    } else if (event.target.className === 'cashtag-link') {
      const cashtag = event.target?.getAttribute('data-cashtag');
      this.openCashTag(cashtag);
    } else if (event.target.classList.contains('tradingview-link-modal')) {
      const urlDataTitle = event.target.getAttribute('data-tradingview-title');
      const extractedUrl = event.target.getAttribute('data-tradingview-url');
      this.openTradingViewModal(urlDataTitle, extractedUrl);
    } else if (event.target.parentElement.classList.contains('tradingview-link-modal')) {
      const urlDataTitle = event.target.parentElement.getAttribute('data-tradingview-title');
      const extractedUrl = event.target.parentElement.getAttribute('data-tradingview-url');
      this.openTradingViewModal(urlDataTitle, extractedUrl);
    } else if (event.target.parentElement.parentElement?.classList.contains('tradingview-link-modal')) {
      const urlDataTitle = event.target.parentElement.parentElement.getAttribute('data-tradingview-title');
      const extractedUrl = event.target.parentElement.parentElement.getAttribute('data-tradingview-url');
      this.openTradingViewModal(urlDataTitle, extractedUrl);
    } else {
      this.messageClickedId = null;
    }
  }

  get mutedUsers() {
    return this.profileService.me.mutedUsers;
  }

  get hasMoreMessages() {
    return this.messageService.hasMoreMessages[this.panelId];
  }

  get hasMoreMessagesBelow() {
    return this.messageService.hasMoreMessagesBelow[this.panelId];
  }

  get inFocus() {
    return this.panelService && this.panelId && this.panelService.focusedItem === this.panelId;
  }

  @Input()
  parentReplyMessage: Message;

  private _messages: Message[];
  @Input()
  set messages(val: Message[]) {
    // this.log('LOADMESSAGES', val);
    if (typeof (val) === 'undefined') { // this is for panel-chatroom that sometimes sends 'undefined' value
      return;
    }

    this._messages = val;

    const cursor = this.panelService.timestampCursors[this.panelId];
    if (cursor) {
      // this.log('SCROLL CURSOR EXISTS', val);
      this.initialMessageToScroll = val.find((msg, i, msgs) =>
        // the initial message is the one with the same timestamp
        (msg.timestamp.getTime() === cursor.getTime()) ||
        // or the immediate one after the timestamp (timestamp must be between messages) when it's not first
        (i > 0 &&
          msg.timestamp.getTime() > cursor.getTime() &&
          msgs[i - 1].timestamp.getTime() < cursor.getTime()) ||
        // or is the initial message
        (i === 0 && msg.timestamp.getTime() > cursor.getTime()) ||
        // or is the last message
        (i === msgs.length - 1 && msg.timestamp.getTime() < cursor.getTime()));
    }

    if (this.initialMessageToScroll) {
      // this.log('SCROLL INITIAL MESSAGE FOUND');
      this.cd.detectChanges();
      const el = this.messageElements.find((el, i, a) =>
        (el.nativeElement as HTMLElement).id === `msgcomp-${this.initialMessageToScroll.id}`
      );
      if (el) {
        this.scrollToElement(el);
        this.initialMessageToScroll = null;
        delete this.panelService.timestampCursors[this.panelId];
      }
    }

    // update before next code block to be able to calculate position based on the line
    this.markFirstNewMessageScheduler$.next();

    this.hideScrollToBottom = !this.isLookingPastMessages();
  }
  get messages() {
    return this._messages;
  }

  get showGoToUnread() {
    // console.log('LINETEST-', !!this.enableGoToUnread, !!this.unreadTimestamp, !!this.messages, !!this.messages.length, !this.isNewMessagesLineRendered, this.unreadTimestamp);
    // console.log('LINETEST-', this.messages[0].timestamp, (new Date(this.unreadTimestamp) < this.messages[0].timestamp), this.hasMoreMessages);
    return this.enableGoToUnread && this.unreadTimestamp && this.messages && this.messages.length &&
      // line way above
      ((!this.isNewMessagesLineRendered && (new Date(this.unreadTimestamp) < this.messages[0].timestamp) && this.hasMoreMessages) ||
        // line in page but not in view
        (this.isNewMessagesLineRendered && !this.isNewMessagesLineInView) ||
        // line way below
        (this.hasMoreMessagesBelow && new Date(this.roomLastActive) > new Date(this.unreadTimestamp)));
  }

  hideScrollToBottom: boolean = false;
  // initialScroll: boolean = true;
  savedScroll = 0;

  chatboxPositionLimiter = false;

  isNewMessagesLineRendered: boolean;
  isNewMessagesLineInView: boolean;
  isNewMessagesLineAbove: boolean;
  isNewMessagesLineBelow: boolean;
  enableGoToUnread = false;
  scrollEventSubject$ = new Subject<any>();

  markFirstNewMessageScheduler$ = new Subject();
  hasMeeting = false;

  unlisten: Function[] = [];

  constructor(
    private cd: ChangeDetectorRef,
    private hostElement: ElementRef,
    private profileService: ProfileService,
    private messageService: MessageService,
    private teamService: TeamService,
    private messageCountService: MessageCountService,
    private modal: NgbModal,
    private roomService: RoomService,
    private panelService: PanelsService,
    private renderer: Renderer2,
    private panelFilesService: PanelFilesService,
    private panelBookmarksService: BookmarkMessagesService,
    private panelPinboardService: PinboardMessagesService,
    private panelSearchService: SearchMessagesService,
    private panelMentionsService: MentionedMessagesService,
    private panelPulseService: PulseMessagesService,
    private panelSignalsService: SignalsMessagesService,
    private updateTimeService: IntervalUpdateTimerService,
    private modalsService: ModalsService,
    private zone: NgZone,
    private emojiService: EmojiService,
    private tracking: TrackingService
  ) {
    super();
  }

  ngOnInit() {
    this.selectedDate = new Date();
    this.maxDate = new Date();
    const room = this.roomService.getRoomById(this.panelId);
    // this.minDate = room.CreatedAt;

    this.hideScrollToBottom = !this.isLookingPastMessages();
    this.subscribe(this.profileService.muteAdded$, () => {
      this.cd.detectChanges();
    });
    this.subscribe(this.profileService.muteRemoved$, () => {
      this.cd.detectChanges();
    });

    this.subscribe(this.panelService.messagePDFResetFullView$, () => {
      this.showFullLightboxId = null;
      this.showFullLightboxType = null;
      this.cd.detectChanges();
    });

    this.subscribe(this.panelService.resetScrollToSaved$, panelId => {
      if (this.panelId === panelId) {
        console.log('resetScrollToSaved', panelId);
        setTimeout(() => {
          this.resetScroll();
        });
      }
    });

    this.subscribe(this.messageService.messageDeleted$, (e) => {
      if (this.timelineType === this.timelineTypeEnum.RepliedMessages && e.messageId === this.panelId) {
        this.parentReplyMessage = null;
      }
    });

    // only allow to run after 300ms have passed since last execution, allow previous run to have completed
    this.subscribe(this.markFirstNewMessageScheduler$.pipe(throttleTime(300)), () => {
      this.markFirstNewMessage();
      // this.cd.detectChanges();
      this.calculateIsNewMessagesLineInView();
      this.cd.detectChanges();
    });

    // throttle scroll event calculation
    this.subscribe(
      this.scrollEventSubject$.pipe(
        debounceTime(100),
        skipWhile(c => this.loadingMore)),
      async (event) => {
        if (this.panelService.reverseScrollTopNegative) {
          await this.handleScrollEventsNegative();
        } else {
          await this.handleScrollEventsPositive();
        }

        this.calculateIsNewMessagesLineInView();
        this.cd.detectChanges();
      });

    // on message receive, check if loaded before end and show float button if needed
    // this happens when messages are not changed
    this.subscribe(this.messageService.messageReceived$, msg => {
      // if loaded high up, and the chatroom is right, and the line is neither visible not above
      if (this.hasMoreMessagesBelow && !this.isNewMessagesLineAbove && !this.isNewMessagesLineInView && msg.chatroom.id === this.panelId) {
        // then the line below must be calculated between last activity on room and the incoming message timestamp
        this.isNewMessagesLineBelow = !this.roomLastActive || msg.timestamp > new Date(this.roomLastActive);
      }
    });

    this.subscribe(this.messageService.messageReplacementReceived$, r => {
      if (r.replace.chatroom.id === this.panelId) {
        this.messages = this.messages.map(msg => {
          if (msg.id === r.oldId) {
            return { ...r.replace, isCollapsed: msg.isCollapsed, firstMessageOfDay: msg.firstMessageOfDay };
          }
          return msg;
        })
      }
    });

    this.subscribe(this.panelService.refreshLiveStreamPosition$, (panelId) => {
      if (!panelId || panelId === this.panelId) {
        setTimeout(() => {
          this.cd.markForCheck();
        });
      }
    });

    this.subscribe(
      this.teamService.meetingsUpdated$,
      (meetings) => this.calculateMeeting(meetings[this.teamService.activeTeamId]));
    this.calculateMeeting(this.teamService.meetings[this.teamService.activeTeamId]);

    this.zone.runOutsideAngular(() => {
      this.unlisten.push(this.renderer.listen(
        this.hostElement.nativeElement,
        'mouseover',
        (e) => { this.centralMouseover(e); }
      ));
    });
  }

  centralMouseover(event) {
    const { body, isInTradingView, isInReaction } = this.findMessageMouseOverArea(event.target);
    const messageHoveringId = body?.getAttribute('data-messageId');
    this.panelService.messageMouseoverTradingView$.next([this.panelId, messageHoveringId, isInTradingView, isInReaction]);
  }

  async toggleReaction(messageId, reaction) {
    await this.messageService.toggleReaction(messageId, reaction);
  }

  scrollToElement(element: ElementRef) {
    this.disableScrollEventsAfterDateLoad = true;
    if (this.panelService.reverseScrollTopNegative) {
      this.scrollToMessageNegativeReverse(element);
    } else {
      this.scrollToMessagePositiveReverse(element);
    }
  }

  private scrollToMessageNegativeReverse(element: ElementRef) {
    this.chatbox.nativeElement.scrollTop = (element.nativeElement as HTMLElement).offsetTop - (this.chatbox.nativeElement.clientHeight / 4); // load at 1/4th of the timeline height to account for possible topic, line height, etc
  }

  private scrollToMessagePositiveReverse(messageEl: ElementRef) {
    this.chatbox.nativeElement.scrollTop = this.chatbox.nativeElement.scrollHeight + (messageEl.nativeElement as HTMLElement).offsetTop - 5 * (this.chatbox.nativeElement.clientHeight / 4); // load at 1/4th of the timeline height to account for possible topic, line height, etc
  }

  calculateMeeting(meetings) {
    if (!meetings) {
      this.hasMeeting = false;
      this.cd.markForCheck();
      return;
    }
    const meeting = meetings.find(x => x.chatroomId === this.panelId);
    this.hasMeeting = !!meeting;
    this.cd.markForCheck();
  }

  openMeeting() {
    const room = this.roomService.getRoomById(this.panelId);
    const modal = this.modal.open(StreamComponent, {
      centered: true,
      windowClass: 'modal-dark'
    });
    modal.componentInstance.room = room;
  }

  scrollToBottom(): void {
    if (this.chatbox && this.chatbox.nativeElement) {
      const shouldRequestLatest = this.hasMoreMessagesBelow;
      this.pureScrollBottom();
      if (shouldRequestLatest) {
        this.messageService.hasMoreMessagesBelow[this.panelId] = false; // make false - if removed, due to scroll events delay, it triggers GET_MESSAGES_NEXT_PAGE AFTER we get last page and calculate this as false - then it recalculates as true
        this.pureScrollBottom();
        this.hideScrollToBottom = true;
        this.requestTimeframe.emit(null);
      }
    }
  }

  pureScrollBottom() {
    if (this.panelService.reverseScrollTopNegative) {
      this.pureScrollBottomNegative();
    } else {
      this.pureScrollBottomPositive();
    }
  }

  private pureScrollBottomPositive() {
    (this.chatbox.nativeElement as HTMLDivElement).scrollTo(0, this.chatbox.nativeElement.scrollHeight + TimelineComponent.SCROLL_BOTTOM_EXTRA);
  }

  private pureScrollBottomNegative() {
    (this.chatbox.nativeElement as HTMLDivElement).scrollTo(0, 0);
  }

  async onScrollDown() {
    this.hideScrollToBottom = !this.isLookingPastMessages();
    const bottomMessage = this.messages[this.messages.length - 1];
    if (!this.loadingBetween && !this.loadingMore) {
      // dont fetch, if about to load latest messages
      await this.requestNewer({ from: bottomMessage.timestamp, type: RequestNewerType.SCROLLING });
    }
    // this.log('[ONSCROLL-DOWN]', bottomMessage);
  }

  onScroll(event: Event) {
    this.savedScroll = this.chatbox.nativeElement.scrollTop;
    this.hideScrollToBottom = !this.isLookingPastMessages();
    this.scrollEventSubject$.next(event);
  }

  resetScroll() {
    this.chatbox.nativeElement.scrollTop = this.savedScroll;
  }

  async onScrolledUp() {
    this.hideScrollToBottom = !this.isLookingPastMessages();
    let topMessage: Message;
    if (this.messages && this.messages.length && !this.loadingMore) {
      topMessage = this.messages[0];
      await this.requestOlder({ from: topMessage.timestamp, type: RequestOlderType.SCROLLING });
    }
    // this.log('[ONSCROLL-UP]', topMessage);
  }

  trackByFn(index, item: Message) {
    return item.id + item.status + item.localVersion;
  }

  isLookingPastMessages() {
    if (this.panelService.reverseScrollTopNegative) {
      return this.lookingPastMessagesNegativeScrollTop();
    } else {
      return this.lookingPastMessagesPositiveScrollTop();
    }
  }

  private lookingPastMessagesNegativeScrollTop() {
    return this.hasMoreMessagesBelow || this.chatbox.nativeElement.scrollTop < 0;
  }

  private lookingPastMessagesPositiveScrollTop() {
    const value = this.chatbox.nativeElement.scrollHeight - (this.chatbox.nativeElement.offsetHeight + this.chatbox.nativeElement.scrollTop);
    return this.hasMoreMessagesBelow || value > TimelineComponent.SCROLL_OLDER_HEIGHT;
  }

  async loadMore() {
    const topMessage = this.messages[0];
    await this.requestOlder({ from: topMessage.timestamp, type: RequestOlderType.BUTTON });
    // this.log('[LOADMORE]', topMessage.timestamp);
  }

  async loadMoreBelow() {
    const bottomMessage = this.messages[this.messages.length - 1];
    await this.requestNewer({ from: bottomMessage.timestamp, type: RequestNewerType.BUTTON });
    // this.log('[LOADMOREBELOW]', bottomMessage.timestamp);
  }

  getDateAsNumber(date: Date) {
    return date.getFullYear() * 10000 +
      date.getUTCMonth() * 100 +
      date.getUTCDate();
  }

  // this needs to be called through a debouncer, because it should finish not run concurrently when we have multiple calls one after the other (e.g. multiple successive messages)
  markFirstNewMessage() {
    if (!this.messages || !this.messages.length) {
      return;
    }
    if (this.panelService.focusedItem === this.panelId) {
      this.unreadTimestamp = (new Date()).toISOString();
      return;
    }

    this.isNewMessagesLineRendered = false;
    let foundFirstMessage = false;
    for (let i = 0; i < this.messages.length; i++) {
      const msg = this.messages[i];
      // check for the new messages line when unreadTimestamp between two messages
      // or unreadTimestamp before first message and there are no more messages to load
      if ((i > 0 && ((foundFirstMessage) || (msg.timestamp > new Date(this.unreadTimestamp) && this.messages[i - 1].timestamp <= new Date(this.unreadTimestamp)))) ||
        (i === 0 && !this.hasMoreMessages && ((foundFirstMessage) || (msg.timestamp > new Date(this.unreadTimestamp))))) {
        if (!foundFirstMessage) {
          // found first message by timestamp
          foundFirstMessage = true;
        }
        if (msg.sender.id !== this.profileService.me.id) {
          // found real first message
          msg.isFirstNewMessage = true;
          this.isNewMessagesLineRendered = true;
          break;
        }
        // if sender is the user, skip this message
      } else {
        msg.isFirstNewMessage = false;
      }
    }
  }

  calculateIsNewMessagesLineInView() {
    // if line is drawn
    if (this.newMessagesLine) {
      // enable floating button
      this.enableGoToUnread = true;
      // and calculate if in line is in view
      const line = (this.newMessagesLine.nativeElement as HTMLDivElement).getBoundingClientRect();
      const box = (this.chatbox.nativeElement as HTMLDivElement).getBoundingClientRect();
      // if line is inside box
      if ((box.top < line.top + line.height) && (box.top + box.height > line.top + line.height)) {
        this.isNewMessagesLineAbove = false;
        this.isNewMessagesLineBelow = false;
        this.isNewMessagesLineInView = true;
        return;
      }

      // otherwise
      // find if above or below
      if (box.top > line.top + line.height) {
        this.isNewMessagesLineAbove = true;
        this.isNewMessagesLineBelow = false;
      } else if (box.top + box.height < line.top + line.height) {
        this.isNewMessagesLineAbove = false;
        this.isNewMessagesLineBelow = true;
      }
    } else if (this.messages && this.messages.length && (new Date(this.unreadTimestamp) < this.messages[0].timestamp && this.hasMoreMessages)) {
      // if line not drawn, check if maybe the line should be in a previous messages page, so we must enable the floating button
      this.isNewMessagesLineAbove = true;
      this.isNewMessagesLineBelow = false;
      this.enableGoToUnread = true;
    } else if (this.hasMoreMessagesBelow && new Date(this.roomLastActive) > new Date(this.unreadTimestamp)) {
      // or in next page
      this.isNewMessagesLineAbove = false;
      this.isNewMessagesLineBelow = true;
      this.enableGoToUnread = true;
    }

    // line is not drawn, so it is not in view
    this.isNewMessagesLineInView = false;
  }

  markRead() {
    this.messageCountService.sendFocusChange({ chatrooms: [{ id: this.panelId, focused: true }] });
    setTimeout(
      () => { this.messageCountService.sendFocusChange({ chatrooms: [{ id: this.panelId, focused: false }] }); },
      1000);
  }

  focusAndGoToUnread() {
    this.markRead();
    if (this.isNewMessagesLineRendered && this.chatbox && this.chatbox.nativeElement && this.newMessagesLine && this.newMessagesLine.nativeElement) {
      this.scrollToElement(this.newMessagesLine);
    } else {
      this.requestTimeframe.emit(new Date(this.unreadTimestamp));
    }
  }

  async requestOlder(args: RequestOlder) {
    if (this.hasMoreMessages && !this.loadingMore) {
      this.loadingMore = true;
      await this.getMessages(this.panelId, args.from);
      if (this.chatbox.nativeElement.scrollTop === 0) {
        this.renderer.setProperty(this.chatbox.nativeElement, 'scrollTop', 1);
      }
      if (this.chatbox.nativeElement.scrollHeight - this.chatbox.nativeElement.clientHeight + this.chatbox.nativeElement.scrollTop === 0) {
        this.renderer.setProperty(this.chatbox.nativeElement, 'scrollTop', this.chatbox.nativeElement.scrollTop + 1);
      }
      this.loadingMore = false;
      this.cd.detectChanges();
      this.messages = [...this.messageService.messages[this.panelId]];
      this.cd.detectChanges();
      if (this.messageService.messages[this.panelId].length >= 4 * MESSAGES_PER_PAGE) {
        this.messageService.messages[this.panelId].splice(this.messageService.messages[this.panelId].length - MESSAGES_PER_PAGE);
        this.messageService.hasMoreMessagesBelow[this.panelId] = true;
        this.messages = [...this.messageService.messages[this.panelId]];
        this.cd.detectChanges();
      }
    }
  }

  async getMessages(identifier, timestamp) {
    switch (this.timelineType) {
      case this.timelineTypeEnum.TeamChatroomMessages:
      case this.timelineTypeEnum.DirectOrGroupMessages:
        await this.messageService.getMessages(identifier, timestamp);
        break;
      case this.timelineTypeEnum.PinboardMessages:
        const pinOrder = this.panelService.getMessageOrder(identifier).order;
        await this.panelPinboardService.loadPinboardMessages(identifier, pinOrder, this.teamId, timestamp);
        break;
      case this.timelineTypeEnum.BookmarkMessages:
        const bookOrder = this.panelService.getMessageOrder(identifier).order;
        await this.panelBookmarksService.loadBookmarkMessages(identifier, bookOrder, this.teamId, timestamp);
        break;
      case this.timelineTypeEnum.MentionedMessages:
        await this.panelMentionsService.loadMentionedMessages(identifier, this.teamId, timestamp);
        break;
      case this.timelineTypeEnum.SearchMessages:
        await this.panelSearchService.loadSearchMessages(identifier, this.teamId, timestamp);
        break;
      case this.timelineTypeEnum.FilesMessages:
        await this.panelFilesService.loadFileMessages(identifier, this.teamId, timestamp);
        break;
      case this.timelineTypeEnum.RepliedMessages:
        await this.messageService.getMessages(identifier, timestamp);
        break;
      case this.timelineTypeEnum.PulseMessages:
        await this.panelPulseService.loadPulseMessages(identifier, this.teamId, timestamp);
        break;
      case this.timelineTypeEnum.SignalMessages:
        await this.panelSignalsService.loadSignalMessages(identifier, this.teamId, timestamp);
        break;
    }
  }

  async requestNewer(args: RequestNewer) {
    if (this.hasMoreMessagesBelow && !this.loadingMore) {
      this.loadingMore = true;
      await this.getMessagesNext(this.panelId, args.from);
      if (this.messageService.messages[this.panelId].length >= 4 * MESSAGES_PER_PAGE) {
        this.messageService.messages[this.panelId].splice(0, MESSAGES_PER_PAGE);
        this.messageService.hasMoreMessages[this.panelId] = true;
      }
      this.messages = [...this.messageService.messages[this.panelId]];
      this.loadingMore = false;
    }
  }

  async getMessagesNext(identifier, timestamp) {
    switch (this.timelineType) {
      case this.timelineTypeEnum.TeamChatroomMessages:
      case this.timelineTypeEnum.DirectOrGroupMessages:
        await this.messageService.getMessagesNext(identifier, timestamp, false);
        break;
      case this.timelineTypeEnum.PinboardMessages:
        const pinOrder = this.panelService.getMessageOrder(identifier).order;
        await this.panelPinboardService.loadPinboardMessagesAfter(identifier, pinOrder, this.teamId, timestamp, false);
        break;
      case this.timelineTypeEnum.BookmarkMessages:
        const bookOrder = this.panelService.getMessageOrder(identifier).order;
        await this.panelBookmarksService.loadBookmarkMessagesAfter(identifier, bookOrder, this.teamId, timestamp, false);
        break;
      case this.timelineTypeEnum.MentionedMessages:
        await this.panelMentionsService.loadMentionedMessagesAfter(identifier, this.teamId, timestamp, false);
        break;
      case this.timelineTypeEnum.SearchMessages:
        await this.panelSearchService.loadSearchMessagesAfter(identifier, this.teamId, timestamp, false);
        break;
      case this.timelineTypeEnum.FilesMessages:
        await this.panelFilesService.loadFileMessagesAfter(identifier, this.teamId, timestamp, false);
        break;
      case this.timelineTypeEnum.RepliedMessages:
        await this.messageService.getMessagesNext(identifier, timestamp, false);
        break;
      case this.timelineTypeEnum.PulseMessages:
        await this.panelPulseService.loadPulseMessagesAfter(identifier, this.teamId, timestamp, false);
        break;
      case this.timelineTypeEnum.SignalMessages:
        await this.panelSignalsService.loadSignalMessagesAfter(identifier, this.teamId, timestamp);
        break;
    }
  }

  focusAndClearLine() {
    this.markRead();
    this.isNewMessagesLineRendered = false;
  }

  goToDate(d: NgbInputDatepicker) {
    d.close();
    let timestamp: Date;
    let simpleSelectedDate = new Date();
    if (!(this.selectedDate instanceof Date)) {
      const simpleSelectedDateTime = this.selectedDate as unknown as NgbDate;
      simpleSelectedDate = new Date(simpleSelectedDateTime.year, simpleSelectedDateTime.month - 1, simpleSelectedDateTime.day);
    }
    timestamp = new Date(simpleSelectedDate.setHours(0, 0, 0, 0));
    if (this.timelineType === TimelineType.TeamChatroomMessages) {
      this.panelService.openRoom(this.panelId, timestamp);
    } else if (this.timelineType === TimelineType.DirectOrGroupMessages) {
      this.panelService.openDirect(this.panelId, timestamp);
    }
  }

  async handleScrollEventsNegative() {
    if (this.hasMoreMessagesBelow && this.chatbox.nativeElement.scrollTop === 0) {
      this.chatbox.nativeElement.scrollTop = -1;
    }
    if (this.hasMoreMessages && this.chatbox.nativeElement.scrollHeight === this.chatbox.nativeElement.clientHeight - this.chatbox.nativeElement.scrollTop) {
      this.chatbox.nativeElement.scrollTop = this.chatbox.nativeElement.scrollTop + 1;
    }

    if (!this.disableScrollEventsAfterDateLoad) {
      if (this.hasMoreMessages &&
        ((this.chatbox.nativeElement as HTMLDivElement).scrollHeight - this.chatbox.nativeElement.clientHeight + this.chatbox.nativeElement.scrollTop) < (this.chatbox.nativeElement as HTMLDivElement).clientHeight) {
        await this.onScrolledUp();
      } else if (this.hasMoreMessagesBelow &&
        this.chatbox.nativeElement.scrollTop > -(this.chatbox.nativeElement as HTMLDivElement).clientHeight
      ) {
        await this.onScrollDown();
      }
    } else {
      this.disableScrollEventsAfterDateLoad = false;
    }

    if (!this.hasMoreMessagesBelow && this.chatbox.nativeElement.scrollTop > 0) {
      this.chatbox.nativeElement.scrollTop = 0;
    }
  }

  async handleScrollEventsPositive() {
    if (this.hasMoreMessages && this.chatbox.nativeElement.scrollTop === 0) {
      this.chatbox.nativeElement.scrollTop = 1;
    }
    if (this.hasMoreMessagesBelow && this.chatbox.nativeElement.scrollHeight === this.chatbox.nativeElement.scrollTop + this.chatbox.nativeElement.clientHeight) {
      this.chatbox.nativeElement.scrollTop = this.chatbox.nativeElement.scrollTop - 1;
    }
    if (this.hasMoreMessages &&
      this.chatbox.nativeElement.scrollTop < (this.chatbox.nativeElement as HTMLDivElement).clientHeight) {
      await this.onScrolledUp();
    } else if (this.hasMoreMessagesBelow &&
      ((this.chatbox.nativeElement as HTMLDivElement).scrollHeight - this.chatbox.nativeElement.clientHeight - this.chatbox.nativeElement.scrollTop) < (this.chatbox.nativeElement as HTMLDivElement).clientHeight
    ) {
      await this.onScrollDown();
    }
    if (!this.hasMoreMessagesBelow && this.chatbox.nativeElement.scrollTop < 0) {
      this.chatbox.nativeElement.scrollTop = 0;
    }
  }

  loadReplies(replyPanelId: string) {
    this.panelService.open({
      id: replyPanelId,
      teamId: this.teamService.activeTeam.id,
      type: PanelType[PanelType.ReplyMessage],
    }, this.panelId);

    this.scrollToPanel(replyPanelId);
  }

  scrollToPanel(id: string) {
    setTimeout(
      () => {
        this.panelService.slideToPanel(id);
      },
      50);
  }

  openChat(chatroomId, timestamp) {
    this.panelService.openRoom(chatroomId, timestamp);
  }

  openCashTag(symbol: string) {
    this.modalsService.openFloatModal({
      config: {
        width: 420,
        height: 600
      },
      type: 'WIDGET',
      label: `$${symbol}`,
      url: `https://tradetools.tk/s/cashtags/?symbol=${symbol}`,
      teamId: 'GLOBAL',
      resizable: false,
      hideMinimize: true,
      centered: true,
      hasAccess: true
    });
  }

  openTradingViewModal(urlDataTitle: string, extractedUrl: string) {
    this.tradingViewModal = {
      config: {
        width: 1024,
        height: 640
      },
      type: 'WIDGET',
      label: `TradingView${urlDataTitle ? ` - ${urlDataTitle}` : ''}`,
      url: extractedUrl,
      teamId: 'GLOBAL',
      resizable: true,
      hasAccess: true,
    };
    this.modalsService.openFloatModal(this.tradingViewModal);
  }

  findMessageMouseOverArea(element: HTMLElement) {
    let body = null;
    let isInTradingView = false;
    let isInReaction: string = null;
    let elToCheck = element;
    while (elToCheck && elToCheck.tagName !== 'APP-MESSAGE') {
      if (!isInTradingView && elToCheck.classList.contains('tradingview-mousearea')) {
        isInTradingView = true;
      }
      if (!isInReaction?.length && elToCheck.classList.contains('reaction-pill')) {
        isInReaction = elToCheck.getAttribute('data-reaction');
      }
      if (elToCheck.classList.contains('message-wrapper-body')) {
        body = elToCheck;
        break;
      }
      elToCheck = elToCheck.parentElement;
    }
    return { body, isInTradingView, isInReaction };
  }

  log(...arg) {
    // below comment ignores the code inside the if brackets for the unit test coverage report
    /* istanbul ignore if  */
    if (environment.config.debug) {
      console.log('%c[TIMELINE COMPONENT]', 'color:#BA1150', ...arg);
    }
  }

  ngOnDestroy() {
    if (this.hostElement && this.hostElement.nativeElement) {
      (this.hostElement.nativeElement as HTMLElement).innerHTML = '';
    }
    super.ngOnDestroy();
    for (let i = 0; i < this.unlisten.length; i++) {
      this.unlisten[i]();
    }
  }
}
