<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title">Ensemble desktop client</h4>
    <button aria-label="Close" class="close" data-dismiss="modal" type="button" (click)="m.dismiss()"><span
        aria-hidden="true">×</span></button>
  </div>
  <div class="modal-body">
    <div class="row px-4 pb-4">
      <div class="col-4 text-center">
        <i class="fab fa-windows icon"></i>
        <a class="btn btn-sm btn-outline-primary btn-block mt-1"
          href="https://downloads.ensemble.chat/EnsembleSetup.exe" target="_blank">
          <i class="fal fa-arrow-alt-to-bottom"></i>
          WINDOWS
        </a>
      </div>
      <div class="col-4 text-center">
        <i class="fab fa-apple icon"></i>
        <a class="btn btn-sm btn-outline-primary btn-block mt-1"
          href="https://downloads.ensemble.chat/Ensemble.dmg" target="_blank">
          <i class="fal fa-arrow-alt-to-bottom"></i>
          MAC
        </a>
      </div>
    </div>
  </div>
</div>