import { Injectable } from '@angular/core';
import { Events, NotifUsersApi } from '@echofin/libraries';
import { NotificationModel } from '@echofin/libraries/api/notification/models/notification-model';
import { Subject } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { FocusService } from '../../../../_core/services/focus.service';
import { MessageTextFormatterService } from '../../../../_core/services/message-text-formatter.service';
import { ProfileService } from '../../../../_core/services/profile.service';
import { EvType } from '../../../../_core/services/socket.service/models/all.models';
import { SocketService } from '../../../../_core/services/socket.service/socket.service';
import { TeamService } from '../../../../_core/services/team.service';
import { Helpers } from '../../../../_shared/helpers/helpers';
import { LocalMessageProcessed, Message } from '../../../../_shared/models/commons/message';
import { Team } from '../../../../_shared/models/teams/team';
import { AppNotification } from '../models/app-notification';

declare var window;

const DEFAULT_ICON = '/assets/icons/favicon-32x32.png';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  notifications: AppNotification[] = [];
  notificationsChanged$: Subject<AppNotification[]> = new Subject<AppNotification[]>();
  listeningToNotifications = false;
  procEvents = Events.ProcessedTypesEnum;

  constructor(
    private notificationApi: NotifUsersApi,
    private profileService: ProfileService,
    private focusService: FocusService,
    private messageTextFormatterService: MessageTextFormatterService,
    private teamService: TeamService,
    private socketService: SocketService
  ) { }

  async loadNotificationSettings() {
    if (this.profileService.me) {
      console.log('load notifications');
      this.notifications = (await this.notificationApi
        .UserNotifications(this.profileService.me.id)
        .toPromise()).filter(n => n.type === 'Desktop');

      setTimeout(
        async () => {
          if (this.isBrowserSupported() && !this.listeningToNotifications) {
            this.listeningToNotifications = true;
            await this.requestPermission();
            this.enableNotifications();
          }
        },
        5000);
    }
  }

  async getMobileNotificationsForChatroom(chatroomId, teamId) {
    const notifications = await this.notificationApi
      .UserNotifications(this.profileService.me.id)
      .toPromise();

    if (notifications) {
      return notifications.find(s => s.type === 'Mobile' && s.chatroomId === chatroomId && s.teamId === teamId);
    }
  }

  public getTeamNotifications(teamId: string): AppNotification[] {
    return this.notifications.filter(n => n.teamId === teamId);
  }

  public async saveCurrentTeamNotifications(teamId: string, notifications: AppNotification[]) {
    return await this.notificationApi.SaveTeamNotifications({
      teamId,
      userId: this.profileService.me.id,
      model: notifications
    }).toPromise();
  }

  public async saveCurrentChatroomNotifications(userId: string, teamId: string, chatroomId: string, notification: NotificationModel) {
    return await this.notificationApi.SaveChatroomNotifications({
      teamId,
      userId,
      chatroomId,
      model: notification,
    }).toPromise();
  }

  saveLocal(userId: string, teamId: string, chatroomId: string, notification: AppNotification) {
    const savedNotification = this.notifications.find(n =>
      n.teamId === teamId
      && n.chatroomId === chatroomId
    );

    if (typeof (savedNotification) === 'undefined') {
      this.notifications.push(notification);
    } else {
      savedNotification.all = notification.all;
      savedNotification.none = notification.none;
      savedNotification.mentions = notification.mentions;
    }
  }

  isBrowserSupported() {
    if (!('Notification' in window)) {
      return false;
    }
    return true;
  }

  async requestPermission() {
    // this.log('Requesting permission', this.isBrowserSupported());
    if (this.isBrowserSupported()) {
      await Notification.requestPermission();
    }
  }

  enableNotifications() {
    // this.log('listenToEvents', this.isBrowserSupported(), this.isUserAllowed());
    if (this.isBrowserSupported() && this.isUserAllowed()) {
      this.listenToEvents();
    }
  }

  isUserAllowed(): boolean {
    return Notification.permission === 'granted';
  }

  playSound() {
    try {
      const audio = new Audio();
      audio.src = 'https://assets.echofin.com/sounds/notification.mp3';
      audio.load();
      audio.play();
    } catch (e) {
      // this.log(e);
    }
  }

  sendNotification(title: string, iconPath: string, messageText: string, tag: string) {
    const notification = new Notification(title, {
      tag,
      icon: iconPath,
      body: messageText,
      renotify: true,
      silent: true
    });
    notification.onclick = e => {
      if (Helpers.isElectron() && Helpers.isElectronLatest('1.1.1')) {
        window.ipcRenderer.send('window-event', 'focus');
      }
      window.focus();
      console.log('notification onclick');
    };
    notification.onshow = e => {
      console.log('notification onshow');
    };

    this.playSound();

  }

  listenToEvents() {
    // this.log('listenToEvents');
    this.socketService
      .getStream(EvType.MessageProcessed)
      .subscribe(async (m: LocalMessageProcessed) => {
        // this.log('EvType.MessageProcessed', m);
        if (m.type.toString() === this.procEvents[this.procEvents.NEW]) { // only if it's a new message
          await this.checkMessage(m.message);
        }
      });
  }

  parseSignal(message: Message): string {
    let text = '';

    if (message.signal.orderType) {

      if (message.signal.orderType === 'NONE') {
        text += '⚪';
      } else if (message.signal.orderType.substring(0, 3) === 'BUY') {
        text += '🔵';
      } else if (message.signal.orderType.substring(0, 4) === 'SELL') {
        text += '🔴';
      }

      text += `${message.signal.orderType} : ${message.signal.symbol}`;

    }

    if (message.signal.entry && message.signal.entry !== '') {
      text += ` | ENTRY : ${message.signal.entry}`;
    }
    message.signal.fields.forEach(element => {
      if (element.value && element.value !== '') {
        text += ` | ${element.label} : ${element.value}`;
      }
    });

    if (message.text && message.text !== '') {
      text += ` | ${message.text}`;
    }

    return text;
  }
  getTeamForMessage(message: Message): Team {
    // message.chatroom.teamId
    if (!message.chatroom || !message.chatroom.teamId) {
      return null;
    }

    return this.teamService.getTeam(message.chatroom.teamId);
  }
  clearAndDecodeHtml(messageText) {
    let text = this.messageTextFormatterService.clearMarkdown(messageText);
    text = this.messageTextFormatterService.decodeHTMLEntities(text);
    return text;
  }
  async checkMessage(message: Message) {
    const shouldSend = this.shouldSendMessage(message);
    const team = this.getTeamForMessage(message);
    const icon = message.sender.avatar !== null ? message.sender.avatar : DEFAULT_ICON;
    // this.log('shouldSend', shouldSend, message, team);

    const teamName = (team?.label && team?.label.length > 0) ? team?.label : team?.name
    if (shouldSend) {
      switch (message.type) {
        case 'SIGNAL':
          this.sendNotification(`#${message.chatroom.name} (${team.label || team.name})`, icon, `${message.sender.username}: ${this.parseSignal(message)}`, message.sender.username);
          break;
        case 'SECRET':
          this.sendNotification('Secret:', icon, `${message.sender.username}: Secret Message ${message.text}`, message.sender.username);
          break;
        case 'SHOUT':
          this.sendNotification(`${message.sender.username} 📢`, icon, this.clearAndDecodeHtml(message.text), message.sender.username);
          break;
        case 'TTS':
          break;
        case 'MARKET_ANALYSIS':
          this.sendNotification('Story:', icon, `${message.sender.username}: 🗠 ${this.clearAndDecodeHtml(message.text)}`, message.sender.username);
          break;
        default: // TEXT or unknown
          if (message.files && message.files.length > 0) { // attachment
            this.sendNotification(`#${message.chatroom.name}${team ? ` (${teamName})` : ''}`, icon, `${message.sender.username}: uploaded a file ${message.files[0].filename}`, message.sender.username);
            break;
          }

          if (message.chatroom.type === 'Team') {
            this.sendNotification(`#${message.chatroom.name} (${teamName})`, icon, `${message.sender.username}: ${this.clearAndDecodeHtml(message.text)}`, message.sender.username);
          } else if (message.chatroom.type === 'Direct') {
            this.sendNotification('Direct Message', icon, `${message.sender.username}: ${this.clearAndDecodeHtml(message.text)}`, message.sender.username);
          } else if (message.chatroom.type === 'Group') {
            this.sendNotification('Group Message', icon, `${message.sender.username}: ${this.clearAndDecodeHtml(message.text)}`, message.sender.username);
          }
          break;
      }
    }
  }

  shouldSendMessage(message: Message): boolean {
    if (window.document.hasFocus()) {
      return false;
    }

    if (Helpers.isElectron() && Helpers.isElectronLatest('1.1.1') && window.hasElectronFocus && typeof window.hasElectronFocus === 'function' && window.hasElectronFocus()) {
      return false;
    }

    if (this.profileService.me.id === message.sender.id) {
      return false;
    }

    if (this.profileService.me.mutedUsers.includes(message.sender.id)) {
      return false;
    }

    const channelNotification = this.notifications.find(n => n.chatroomId === message.chatroom.id);

    // channel override
    if (typeof channelNotification !== 'undefined') {
      return this.checkNotification(channelNotification, message);
    }

    return true;
    // team global
    // const teamNotification = this.notifications.find(n => n.default);
    // return this.checkNotification(teamNotification, message);

  }

  public checkNotification(notification: AppNotification, message: Message): boolean {
    if (!notification) {
      return true; // send by default
    }
    if (notification.all) {
      return true;
    }
    if (notification.none) {
      return false;
    }
    if (notification.mentions) { // mention notifications enabled
      if (message.text && message.text.indexOf('@everyone') > -1) { // if @everyone is found in message, send notification
        return true;
      }
      if (message.mentions && message.mentions.filter(m => m.userId === this.profileService.me.id).length > 0) {
        return true;
      }
    }

    return false;
  }

  log(...arg) {
    /* istanbul ignore if  */
    if (environment.config.debug) {
      console.log('%c[NOTIFICATION-SERVICE]', 'color:#2296F3', ...arg);
    }
  }
}
