<div class="modal-header">
    <h4 class="modal-title">Create your Community</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body pd-2 create-team-body">
    <form *ngIf="invitationIsValid" id="team" [formGroup]="teamForm" (ngSubmit)="doCreateTeam()">
        <div class="alert alert-success" *ngIf="!loading && status=='success'">
            <strong>Community Created!</strong>
        </div>
        <div class="alert alert-danger" *ngIf="!loading && status=='fail-in-use'">
            <strong>Community name "{{failedName}}" is taken</strong>
            <hr>
            Please try another one
        </div>
        <div class="alert alert-danger" *ngIf="!loading && status=='fail-reserved'">
            <strong>Community name "{{failedName}}" is reserved.</strong>
            <hr>
            Please try another one
        </div>
        <div class="alert alert-danger" *ngIf="!loading && status=='fail-generic'">
            <strong>Something went wrong</strong>
            <hr>
            {{err}}
        </div>
        <div class="col-12" *ngIf="status!='success'">
            <div class="form-group">
                <label for="Name">Community Name</label>
                <div class="input-group mb-3">
                    <input formControlName="Name" type="text" class="form-control  border-secondary"
                        placeholder="CommunityName">
                    <div class="input-group-append">
                        <span class="input-group-text bg-primary" id="basic-addon2">.ensemble.chat</span>
                    </div>
                </div>
                <p class="d-block">
                    <small>Your community name is also your subdomain on Ensemble. You can modify the title of your Community and full branding details at a later stage.</small>
                </p>
                <p class="create-team-err"
                    *ngIf="teamForm.controls.Name.touched && teamForm.controls.Name.dirty && teamForm.controls.Name.errors">
                    <small class="d-block" *ngIf="teamForm.controls.Name.errors.required">Community name is
                        required.</small>
                    <small class="d-block" *ngIf="teamForm.controls.Name.errors.maxlength">Community name must be at most
                        200 characters long.</small>
                    <small class="d-block" *ngIf="teamForm.controls.Name.errors.minlength">Community name must be at
                        least 2 characters long.</small>
                    <small class="d-block" *ngIf="teamForm.controls.Name.errors.pattern">Community name must contain only
                        lower case letters, numbers, dashes and underscores.</small>
                </p>
            </div>
            <hr />
            <div class="row">
                <div class="col-md-4 form-group logo-formgroup">
                    <label>Logo</label>
                    <div class="d-flex flex-column" style="align-items: center;">
                        <div class="team-logo-container">
                            <img #logo id="logo" alt="team logo" 
                                (click)="changeLogo()"
                                class="d-block team-logo"
                                [src]="(teamForm.controls.Logo && teamForm.controls.Logo.value)? (teamForm.controls.Logo.value + filestackUrlParameters) : '/assets/no-logo.png'">
                        </div>
                        <div class="text-muted  p-2 flex-grow-1 text-center"><small>Square Image<br />Max dimensions 512x512</small></div>
                        <button type="button" class="btn btn-sm btn-block btn-secondary" (click)="changeLogo()">
                            <fa-icon *ngIf="pickerLoading" icon="sync-alt" [fixedWidth]="true" [spin]="true"></fa-icon>
                            <fa-icon *ngIf="!pickerLoading" [icon]="['fas','upload']" [fixedWidth]="true"></fa-icon>
                            <span class="ml-2">Change</span>
                        </button>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="form-group">
                        <label>Description</label>
                        <textarea formControlName="Description" class="form-control" rows="9"></textarea>
                    </div>
                    <p class="create-team-err"
                        *ngIf="teamForm.controls.Description.errors && teamForm.controls.Description.dirty">
                        <small class="d-block" *ngIf="teamForm.controls.Description.errors.maxlength">Community
                            description must be at most 250 characters long.</small>
                    </p>
                </div>
            </div>
            <hr />
            <div class="form-group">
                <label>Tags and categories:<Br /><small>Type any word to see more suggestions</small></label>
                <input id="typeahead-focus" type="text" class="form-control" [ngbTypeahead]="search"
                    [disabled]="tagsValue.length>4"
                    [placeholder]="tagsValue.length>4 ? 'Max items selected' : 'You can select up to 5'"
                    (focus)="focus$.next($event.target.value)" (click)="click$.next($event.target.value)"
                    (selectItem)="onSelectItem($event, typeahead)" #typeahead="ngbTypeahead"
                    [inputFormatter]="clearInput" />
                <div class="mb-2 mt-2 tags-container">
                    <button type="button" *ngFor="let tag of tagsValue" class="btn btn-primary btn-sm mr-1 mb-1"
                        (click)="removeTag(tag)">
                        {{tag}} <fa-icon icon="times"></fa-icon>
                    </button>
                </div>
            </div>
            <div style="display: none;">
                <hr />
                <div class="form-group">
                    <label>Invitation Code:<br/></label>
                    <input type="text" formControlName="invitation" class="form-control" value="{{soloInviation}}" />
                </div>
            </div>
        </div>
    </form>

    <form *ngIf="!invitationIsValid" id="verificationForm" style="font-family: 'Poppins';"
        (ngSubmit)="verifyInvitation()" #invForm="ngForm">
        <div>
            Due to high demand, we were forced to limit new community registrations on an invite-only basis.
            <br><br>
            Please use your invite code in order to create your community.<br><br>
        </div>
        <div class="form-group">
            <input type="text" [formControl]="invitation" placeholder="Enter your invite code" class="form-control" />
        </div>
        <div *ngIf="invitationIsValid===false || (invitation.dirty && invitation.touched && invitation.invalid)"
            class="alert alert-danger text-center">
            <small>The invite code is invalid!</small>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button *ngIf="invitationIsValid" class="btn btn-block btn-success" [disabled]="loading" type="submit"
        form="team">
        <fa-icon *ngIf="loading" icon="sync-alt" [fixedWidth]="true" [spin]="true"></fa-icon>
        <fa-icon *ngIf="!loading" [icon]="['fas', 'users-crown']" [fixedWidth]="true"></fa-icon>
        <span class="ml-2">Create My Community</span>
    </button>
    <button *ngIf="!invitationIsValid" form="verificationForm" class="btn btn-block btn-md btn-success"
        [disabled]="loading||(invForm && invForm.form.valid)" type="submit">
        <fa-icon *ngIf="loading" icon="sync-alt" [fixedWidth]="true" [spin]="true"></fa-icon>
        <fa-icon *ngIf="!loading" [icon]="['far', 'badge-check']" [fixedWidth]="true"></fa-icon>
        <span class="ml-2">Verify Invite Code</span>
    </button>
</div>