<div *ngIf="showBar" class="d-flex h-100 rightbar-container">
  <div class="rightbar rightbar-users" 
  [ngClass]="{'open':apps[teamId + '_Members']}">
    <app-member-directory *ngIf="apps[teamId + '_Members']"></app-member-directory>
  </div>

  <div class="rightbar rightbar-widgets" [ngClass]="{'open':apps[teamId + '_Toolkit']}"
  *ngIf="panelWidgets.length || (this.teamService.activeTeam && this.teamService.activeTeam.permissions && this.teamService.activeTeam.permissions.dashboard_access)">
    <ng-container *ngIf="floatWidgets.length">
      <div class="float-widgets-buttons">
        <!--cannot centralize float widget click - icons may be svg - custom also?-->
        <button *ngFor="let widget of floatWidgets" (click)="clickToOpenFloatModal(widget)">
          <span class="icon" [innerHTML]="(widget.icon || defaultIcon) | htmlsafe"></span>
          <span class="w-100">{{widget.name}}</span>
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="panelWidgets.length; else noWidgets">
      <div class="panel-widgets-buttons btn-group">
        <button [id]="'open-panel-'+widget.id" *ngFor="let widget of panelWidgets"
          [ngClass]="{'active': visibleWidget === widget.id}">
          {{widget.name}}
        </button>
      </div>
      <ng-container *ngFor="let widget of openedWidgets">
        <div class="widget-wrapper" [hidden]="visibleWidget != widget.id">
          <ng-container *ngIf="!widget.hasAccess">
            <div class="widget-lock">
              <div class="text-center">
                <i class="fal fa-lock" style="font-size: 3rem;color: #aaa;margin-bottom: 1rem;"></i>
                <h4>Widget locked</h4>
                <div style="width: 300px;margin: 0 auto 1rem">
                  Please join any of the following chatrooms to unlock the Widget
                </div>
                <div class="unlocks">
                  <ng-container *ngFor="let c of widget.unlockChatrooms">
                    <span>#{{c}}</span>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="widget.hasAccess">
            <ng-container *ngTemplateOutlet="widgetDisclaimer"></ng-container>
            <iframe #iFrame [src]="widget.url | safe"></iframe>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div class="app-bar d-flex flex-column">
    <div class="app-bar-menu">
      <ng-container *ngIf="panelWidgets.length || (this.teamService.activeTeam && this.teamService.activeTeam.permissions && this.teamService.activeTeam.permissions.dashboard_access)">
  
        <div class="btn-container" ngbTooltip="Widgets" container="body" placement="left">
          <button id="appbar-toolkit" class="animated-scaled" [ngClass]="{'opened':apps[teamId + '_Toolkit']}"
            [disabled]="!activeTeam">
            <i class="fad fa-box-full"></i>
          </button>
        </div>
        <div class="border-divider"></div>
      </ng-container>
    </div>
    <div class="app-bar-menu scrollable"
      [ngClass]="{'is-admin': teamService.activeTeam?.permissions?.dashboard_access }">
      <div class="btn-container" ngbTooltip="Directory" container="body" placement="left">
        <button id="appbar-members" class="animated-scaled" [ngClass]="{'opened':apps[teamId + '_Members']}"
          [disabled]="!activeTeam">
          <i class="fad fa-address-book"></i>
        </button>
      </div>
      <!-- <div class="btn-container" ngbTooltip="Marketplace" container="body" placement="left">
        <button id="appbar-marketplace" class="animated-scaled" [disabled]="!activeTeam">
          <i class="fad fa-shopping-cart"></i>
        </button>
      </div> -->
      <div class="btn-container" ngbTooltip="Mentions" container="body" placement="left">
        <button id="appbar-mentions" class="animated-scaled" [class]="cssClass[panelType.Mentions]"
          [disabled]="!activeTeam">
          <i class="fad fa-at"></i>
        </button>
      </div>
      <div class="btn-container" ngbTooltip="Attachments" container="body" placement="left">
        <button id="appbar-files" class="animated-scaled" [ngClass]="cssClass[panelType.Files]"
          [disabled]="!activeTeam">
          <i class="far fa-paperclip"></i>
        </button>
      </div>
      <div class="btn-container" ngbTooltip="Team Pinboard" container="body" placement="left">
        <button id="appbar-bookmarks" class="animated-scaled" [ngClass]="cssClass[panelType.PinboardMessages]"
          [disabled]="!activeTeam">
          <i class="fad fa-thumbtack"></i>
          <app-pinboard-message-count *ngIf="activeTeam" [teamId]="activeTeam.id"></app-pinboard-message-count>
        </button>
      </div>
      <div class="btn-container" ngbTooltip="My Bookmarks" container="body" placement="left">
        <button id="appbar-favorites" class="animated-scaled" [ngClass]="cssClass[panelType.BookmarkMessages]"
          [disabled]="!activeTeam">
          <i class="fad fa-bookmark"></i>
        </button>
      </div>
      <!-- <div class="btn-container" ngbTooltip="Team Pulse" container="body" placement="left">
        <button id="appbar-pulse" class="animated-scaled" [ngClass]="cssClass[panelType.PulseMessages]"
          [disabled]="!activeTeam">
          <i class="fad fa-heart-rate"></i>
        </button>
      </div>
      -->
      <div class="btn-container" ngbTooltip="Search" container="body" placement="left">
        <button id="appbar-search" class="animated-scaled" [ngClass]="cssClass[panelType.Search]"
          [disabled]="!activeTeam">
          <i class="fad fa-search"></i>
        </button>
      </div>
    </div>

    <div class="app-bar-menu mt-auto text-center pb-2">
      <div *ngIf="teamService.activeTeam?.permissions?.dashboard_access" class="btn-container" ngbTooltip="Admin Dashboard"
        container="body" placement="left">
        <button class="animated-scaled" id="dashboard-btn">
          <i class="fad fa-tools"></i>
        </button>
      </div>
    
      <!-- <button class="animated-scaled" id="guide-btn" (click)="test()">
        <fa-icon [icon]="['fad','question']"></fa-icon>
      </button> -->
      <!-- <div class="btn-container" ngbTooltip="Support / Feedback" container="body" placement="left">
        <a class="animated-scaled" id="feedback-btn" href="https://support.echofin.com/" target="_blank">
          <i class="fad fa-comment-alt-smile"></i>
        </a>
      </div> -->

      <div class="btn-container" ngbTooltip="Connection Status" container="body" placement="left">
        <ng-container [ngSwitch]="currentSocketStatus">
          <i *ngSwitchCase="socketStatus.Loading" class="fas fa-wifi text-muted"></i>
          <i *ngSwitchCase="socketStatus.Connecting" class="fas fa-wifi text-light"></i>
          <i *ngSwitchCase="socketStatus.Authenticating" class="fas fa-wifi text-warning"></i>
          <i *ngSwitchCase="socketStatus.Connected" class="fas fa-wifi text-success"></i>
          <i *ngSwitchCase="socketStatus.Unauthorized" class="fas fa-wifi-slash text-warning"></i>
          <i *ngSwitchCase="socketStatus.Disconnected" class="fas fa-wifi-slash text-danger"></i>
        </ng-container>
      </div>
    </div>
  </div>

</div>

<div class="moveModalBackdrop" *ngIf="movingModal" [ngStyle]="{'z-index': openedModals[openedModals.length-1].zIndex}">
</div>

<ng-container *ngFor="let modal of openedModals; let i = index">
  <div *ngIf="modal" [hidden]="!isFloatModalVisible(modal)" class="float-modal modal-content" ngDraggable ngResizable
    (started)="onStart($event,modal)" (rzStart)="onStart($event,modal)"
    (rzStop)="onStop($event,modal); onStopResizing($event,modal)" (stopped)="onStop($event,modal)" rzHandles="se"
    [rzAspectRatio]="modal.type !== 'STREAM'" [bounds]="wrapper" [inBounds]="true" [preventDefaultEvent]="true"
    [ngStyle]="getFloatModalStyle(modal)" [rzMinWidth]="modal.config.minWidth || modal.config.width"
    [rzMinHeight]="modal.config.minHeight || modal.config.height" [rzMaxWidth]="window.innerWidth"
    [rzMaxHeight]="window.innerHeight" [handle]="modalHandle" [ngResizable]="modal.resizable">
    <ng-container *ngIf="modal.type === 'WIDGET'">
      <div #modalHandle class="modal-header">
        <h5 class="modal-title">{{modal.label||modal.name}}</h5>
        <div class="header-toolbox">
          <button *ngIf="!modal.hideMinimize" id="float-modal-minimize" [attr.data-float-position]="i" class="btn">
            <i class="fas fa-window-minimize"></i>
          </button>
          <button id="float-modal-close" [attr.data-float-position]="i" class="btn">
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
      <ng-container *ngIf="!modal.hasAccess">
        <div class="widget-lock">
          <div class="widget-lock-box">
            <i class="fal fa-lock" style="font-size: 3rem;color: #aaa;margin-bottom: 1rem;"></i>
            <h4>Widget locked</h4>
            <div style="width: 300px;margin: 0 auto 1rem">
              Please join any of the following chatrooms to unlock the Widget
            </div>
            <div class="unlocks">
              <ng-container *ngFor="let c of modal.unlockChatrooms">
                <span>#{{c}}</span>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="modal.hasAccess">
        <iframe [src]="modal.url | safe"></iframe>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="modal.type === 'STREAM-YOUTUBE'">
      <div #modalHandle class="modal-header">
        <h5 class="modal-title">
          <i class="fas fa-circle text-danger"></i> Stream - {{modal.streamer}}
        </h5>
        <div class="header-toolbox">
          <button id="float-modal-minimize" [attr.data-float-position]="i" class="btn">
            <i class="fas fa-window-minimize"></i>
          </button>
          <button id="float-modal-close" [attr.data-float-position]="i" class="btn">
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
      <iframe src="https://www.youtube.com/embed/Ga3maNZ0x0w"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ng-container>
    <ng-container *ngIf="modal.type === 'STREAM'">
      <div #modalHandle class="modal-header">
        <h5 class="modal-title">
          <i class="fas fa-circle text-danger"></i>
          {{modal.title}}
        </h5>
        <div class="header-toolbox">
          <button id="float-modal-close" [attr.data-float-position]="i" class="btn">
            <i class="fas fa-times"></i>
          </button>
        </div>
      </div>
      <app-stream-jitsi [settings]="modal.settings"></app-stream-jitsi>
    </ng-container>
  </div>
</ng-container>

<app-notifications></app-notifications>

<ng-template #noWidgets>
  <ng-container *ngTemplateOutlet="widgetDisclaimer"></ng-container>
  <div class="widget-wrapper">
    <div class="p-4">
      <div class="alert alert-neutral">
        <p>This community has not yet added any widgets in this section.</p>
        <p *ngIf="this.teamService.activeTeam && this.teamService.activeTeam.permissions && this.teamService.activeTeam.permissions.dashboard_access">
          <small>Tip: You can install widgets from your <a href="javascript:void(0);" (click)="openDashboard()">Admin Dashboard</a>.</small>
        </p>
       
      </div>
    </div>
  </div>
</ng-template>

<ng-template #widgetDisclaimer>
  <div *ngIf="showDisclaimer" class="widget-disclaimer">
    <h6 class="text-primary">Disclaimer</h6>
    <p>
      <i class="fad fa-exclamation-triangle text-warning"></i> The widget and its contents are
      provided by a third-party. Information provided by the widget and data collected, are not controlled by Ensemble.
      <a id="widget-disclaimer-showmore" href="javascript:void(0)" *ngIf="!showMoreDisclaimer">Learn more</a>
    </p>
    <p id="full-disclaimer" *ngIf="showMoreDisclaimer">The widgets' content is provided
      by third-party service providers. These third parties can use cookies, web beacons, and other technologies to
      collect information, such as your IP address, identifiers associated with your device. The content that is
      provided comes under their own agreement, terms and conditions and copyrights. As a result, Ensemble has no
      liability whatsoever arising from or relating to your use of the widgets. For information on how to use this
      widget or technical assistance, please contact your community's administrator.</p>
    <div class="mt-3 text-center pb-3">
      <button id="widget-consent" type="button" class="btn btn-secondary btn-sm">Consent</button>
    </div>
  </div>
</ng-template>