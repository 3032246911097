<div class="form-group m-0">
  <div class="input-container" [ngClass]="isQuote ? 'input-group-prepend' : 'input-group'">
    <button *ngIf="isQuote" (click)="removeQuotes()" class="btn quote-btn" type="button" title="Remove quote">
      <i class="fas fa-quote-right"></i>
    </button>
    <ng-container *ngIf="isReplyPanel">
      <button class="btn reply-btn" type="button" title="Remove reply" style="cursor: default;">
        <i class="fas fa-reply"></i>
      </button>
    </ng-container>
    <ng-container *ngIf="!isReplyPanel">
      <button *ngIf="isReply" (click)="removeReplies()" class="btn reply-btn" type="button" title="Remove reply">
        <i class="fas fa-reply"></i>
      </button>
    </ng-container>
    <input #text class="form-control bg-dark " [placeholder]="placeholder" (focus)="inputFocus($event)"
      (blur)="inputBlur($event)" (paste)="inputPaste($event)" tabindex="{{chatroomTabIndex}}">
    <i *ngIf="isSecret" class="fas fa-lock input-lock"></i>
    <span class="input-group-btn ml-2">
      <!-- <button id="send" *ngIf="allowPost" [disabled]="!canPost" type="button"
        (click)="inputSend($event)">
        <fa-icon [icon]="['fal','level-down-alt']" [rotate]="90"></fa-icon>
      </button> -->
      <button id="expand" *ngIf="!isReadOnly && inFocus" [disabled]="disabled" type="button" (click)="onExpandInput()">
        <i class="fal fa-expand-alt"></i>
      </button>
      <button *ngIf="isReadOnly || noCharsLeft" type="button" class="readonlyBtn">
        <i class="fal fa-ban"></i>
      </button>
    </span>
  </div>
  <div class="borderfx"></div>
</div>

<ng-container *ngIf="!readonly">
  <div class="mt-1 foot-wrap">
    <div class="foot-icons">
      <input type="file" (change)="importFile($event)" class="d-none" #file>
      <button class="btn btn-sm mr-1 attachment-icon animated-scaled" style="position: relative;overflow: hidden;"
        (click)="file.value=null;file.click()" title="Attach File">
      </button>

      <button class="btn btn-sm mr-1 media-icon animated-scaled" style="position: relative;overflow: hidden;"
      (click)="openAttachment()" title="Share Media">
    </button>

  
    <button class="btn btn-sm mr-1 emoji-icon animated-scaled" title="Emoji" (click)="toggleEmojis($event)">
    
    </button>

      <button class="btn btn-sm mr-1 market-overview-icon animated-scaled" (click)="openMarket()"
      title="New Story">
    </button>

    <button class="btn btn-sm mr-1 giphy-icon animated-scaled" 
        title="Search & Share GIFs" (click)="openGiphy($event)">
    </button>

    <button *ngIf="room && room.type=='Team'" class="btn btn-sm mr-1 stream-icon animated-scaled"
      [ngClass]="{active:hasMeeting}" (click)="openMeeting()" title="Start or Join a Meeting">
    </button>

      <!-- <button *ngIf="room.type=='Direct'" class="btn btn-sm mr-1 stream-icon animated-scaled" (click)="startMeeting()"
      title="Start a call">
      <fa-icon [icon]="['fas','phone']"></fa-icon>
    </button> -->

      <button class="btn btn-sm mr-1 formatting-icon animated-scaled" (click)="openMarkdown()"
        title="Formatting Options">
      </button>
    </div>
  </div>
</ng-container>


<ng-container *ngIf="readonly">
  <div class="mt-1 foot-wrap">
    <div class="foot-icons">
      <button class="btn btn-sm mr-1" style="position: relative;overflow: hidden;" title="Attach File" disabled="true">
        <i class="fas fa-paperclip"></i>
      </button>

      <button class="btn btn-sm mr-1" style="position: relative;overflow: hidden;" title="Attach Media" disabled="true">
        <i class="fas fa-photo-video"></i>
      </button>

      <button class="btn btn-sm mr-1 attachment-icon" style="position: relative;overflow: hidden;" title="Attachment">
       
      </button>

      <button class="btn btn-sm mr-1 emoji-icon " title="Emoji">
       
      </button>

     

      <button class="btn btn-sm mr-1 market-overview-icon" title="Story">
      </button>

      <button class="btn btn-sm mr-1  giphy-icon" title="Signals">
        
      </button>

      <button *ngIf="room && room.type=='Team'" class="btn btn-sm mr-1 stream-icon animated-scaled"
        [ngClass]="{active:hasMeeting}" (click)="openMeeting()" title="Start or Join a Meeting">
        
      </button>



      <button class="btn btn-sm mr-1" style="float:right" title="Formatting Options" disabled="true">
        <i class="fas fa-font"></i>
      </button>
    </div>
  </div>
</ng-container>

<div class="d-flex align-items-center justify-content-between">
  <app-is-typing *ngIf="!isReplyPanel" [channelId]="room.id"></app-is-typing>
  <small *ngIf="charsLeft !== null" [ngClass]="{'text-danger': charsLeft < 0, 'text-warning': charsLeft >= 0}">
    {{charsLeft}} characters left</small>
</div>

<ng-template #smilies>
  <div class="">
    <div class="btn-group btn-group-sm smilies-tabs text-center mb-1">
      <button *ngFor="let smilieTab of smilieTabs" class="btn border-0"
        [ngClass]="{'active':smilieTab.key === smilieSelTab}" (click)="smilieSelTab = smilieTab.key">
        {{smilieTab.key}}
      </button>
    </div>
    <ng-container *ngFor="let smilieTab of smilieTabs">
      <div *ngIf="smilieTab.key === smilieSelTab" class="smilies-icons">
        <button class="btn btn-sm" *ngFor="let s of smilieTab.smilies" (click)="addSmilie(s)">
          <span>{{s.char}}</span>
        </button>
      </div>
    </ng-container>
  </div>
</ng-template>


<div #autocomplete *ngIf="autocompleteList.length" class="autocomplete"
  [ngStyle]="{'transform': 'translateY(-'+autocompleteList.length*32+'px)'}">
  <app-autocomplete-list [autocompleteList]="autocompleteList" [autocompleteIndex]="autocompleteIndex"
    [autocompleteBaseLength]="autocompleteBaseLength" (selectItem)="selectItem($event)">
  </app-autocomplete-list>
</div>

<!-- 
<ng-template #commandTpl let-r="result" let-t="term">
  <div>
    <ngb-highlight [result]="r" [term]="getLastTerm(t)"></ngb-highlight>
  </div>
</ng-template> -->