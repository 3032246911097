import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef } from '@angular/core';
import { BaseComponent } from '../base-component';
import { PanelsService } from '../../_core/services/panels.service';
import { Panel, PanelType } from '../../_shared/models/room/channel';
import { TimelineComponent } from '../timeline/timeline.component';
import { Events } from '@echofin/libraries';
import { Chatroom } from '@echofin/libraries/api/message/models/chatroom';
import { DirectRoom } from '../../_shared/models/room/room';
import { MessageService } from '../../_core/services/message.service';
import { TeamService } from '../../_core/services/team.service';
import { TimelineType } from '../timeline/models/timeline-type.enum';
import { Message } from './../../_shared/models/commons/message';
import { DEFAULTS } from '../../_shared/models/defaults';
import { environment } from '../../../environments/environment';
import { RULE } from '../../_shared/models/commons/permissions.constants';
import { ChannelChange } from '../../_shared/models/commons/channel-change';
import { RoomService } from '../../_core/services/room.service';
import { SocketService } from '../../_core/services/socket.service/socket.service';
import { MessageTextFormatterService } from '../../_core/services/message-text-formatter.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MessageType } from '../../_shared/models/message/message-type';
import { ProfileService } from '../../_core/services/profile.service';

@Component({
  selector: 'app-panel-reply-message',
  templateUrl: './panel-reply-message.component.html',
  styleUrls: ['./panel-reply-message.component.scss'],
})
export class PanelReplyMessageComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() panel: Panel;
  @ViewChild('timeline') timeline: TimelineComponent;

  room: Chatroom & DirectRoom;

  timelineType: TimelineType = TimelineType.RepliedMessages;
  panelType: PanelType = PanelType[PanelType.ReplyMessage];
  rules = RULE;

  type = MessageType;
  replyId: string; // derive from message id
  sender: string; // thread starter username
  roomName: string;

  canDelete = false;
  canPin = false;
  canPost = false;

  messageHovering = false;
  menuHovering = false;

  @Input()
  parentReplyMessage: Message;

  get replies(): Message[] {
    return this.messageService.messages[this.panel.id];
  }

  get roomStyle() {
    return {
      'background-image': '-webkit-gradient(linear, 0% 0%, 100% 0%)'
    };
  }

  get maximized() {
    return this.panelsService.maximizedPanels[this.teamService.activeTeamId] === this.panel.id;
  }

  get color() {
    if (!this.room) {
      return DEFAULTS.room.color;
    }
    return this.room.color || DEFAULTS.room.color;
  }

  get hasMoreMessages() {
    return (this.panel && this.panel.id) ? this.messageService.hasMoreMessages[this.panel.id] : false;
  }

  constructor(
    private mtf: MessageTextFormatterService,
    private domSanitizer: DomSanitizer,
    private panelsService: PanelsService,
    private messageService: MessageService,
    private teamService: TeamService,
    private roomService: RoomService,
    private socketService: SocketService,
    private profileService: ProfileService,
    private hostElement: ElementRef
  ) {
    super();
  }

  async ngOnInit() {
    this.panelsService.panelTitle$.next([this.panel.id, 'Thread loading...']);
    this.panelsService.panelElements[this.panel.id] = this.hostElement;
    this.parentReplyMessage = this.messageService.replyHeaders[this.panel.id];
    await this.initialLoad();
    this.parentReplyMessage = this.messageService.replyHeaders[this.panel.id];
    // this.log(this.parentReplyMessage, this.panel, this.replies);
  }

  async initialLoad() {
    this.panelsService.panelLoading$.next([this.panel.id, true]);
    const replyResponse = await this.messageService.getReplies(this.panel.id);

    const room = this.teamService.getRoom(replyResponse.chatroomId);
    this.room = { ...room };
    this.sender = replyResponse.owner.username;
    this.replyId = this.panel.id;
    this.roomName = this.room.name;
    this.canDelete = this.room.permissions.user[RULE.DELETE_ALL_MESSAGES];
    this.panelsService.panelTitle$.next([this.panel.id, `Thread @${this.sender} on #${this.roomName}`]);

    this.panelsService.panelLoading$.next([this.panel.id, false]);
  }

  chatboxFocus() {
    this.panelsService.focusedItem = this.panel.id;
    this.panelsService.clickedMenuItem$.next(this.panel.id);
    this.panelsService.slideToPanel(this.panelsService.focusedItem);
    // this.log('focus room', this.panel.id);
  }

  chatboxBlur() {
    this.panelsService.focusedItem = null;
    this.panelsService.clickedMenuItem$.next(null);
    // this.socketService.focus({ chatrooms: [{ id: this.panel.id, focused: false }] });
    // this.log('unfocus room', this.panel.id);
  }

  msgSent() {
    if (this.timeline) {
      this.timeline.scrollToBottom();
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.panelsService.panelElements) {
      this.panelsService.panelElements[this.panel.id] = undefined;
    }
  }

  private log(...arg) {
    /* istanbul ignore if  */
    if (environment.config.debug) {
      console.log('%c[PANEL-MESSAGE-REPLY]', 'color:#1556B3', ...arg);
    }
  }
}
