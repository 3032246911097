import { Component, ElementRef, HostListener, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Events } from '@echofin/libraries';
import { LocalStorage } from '@efaps/ngx-store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ScriptService } from 'app/_core/services/script.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { ModalsService } from '../../_core/services/modals.service';
import { PanelsService } from '../../_core/services/panels.service';
import { ProfileService } from '../../_core/services/profile.service';
import { RoomService } from '../../_core/services/room.service';
import { SocketStatus } from '../../_core/services/socket.service/models/socket-status';
import { SocketService } from '../../_core/services/socket.service/socket.service';
import { TeamService } from '../../_core/services/team.service';
import { TrackingService } from '../../_core/services/tracking.service';
import { PanelType } from '../../_shared/models/room/channel';
import { Member } from '../../_shared/models/teams/member';
import { Widget } from '../../_shared/models/teams/widget';
import { BannedTeamUserComponent } from '../banned-team-user/banned-team-user.component';
import { BaseComponent } from '../base-component';
import { MessageCountService } from '../message-count/services/message-count.service';
import { StreamComponent } from '../stream/stream.component';
import { environment } from './../../../environments/environment';
import { KJUR } from 'jsrsasign';
import { Md5 } from 'md5-typescript';

declare var window;

@Component({
  selector: 'app-rightbar',
  templateUrl: './rightbar.component.html',
  styleUrls: ['./rightbar.component.scss'],
})
export class RightbarComponent extends BaseComponent implements OnInit {

  @Input() wrapper: ElementRef;
  @ViewChild('videoElement') videoView: ElementRef;

  @LocalStorage() apps: ({ [key: string]: boolean } & { save?: any }) = {};

  @LocalStorage() widgetsSize: ({ [key: string]: { width: number, height: number } } & { save?: any }) = {};

  searchField: FormControl;
  statusStyle = ['AVAILABLE', 'BUSY', 'AWAY', 'OFFLINE'];
  lastRatio: number = null;
  members: Member[] = [];

  floatWidgets: Widget[] = [];
  panelWidgets: Widget[] = [];

  openedWidgets: any[] = [];

  movingModal = false;

  streamingUsername: string = null;

  canBan = false;

  panelType = PanelType;

  cssClass: { [panelType: string]: string; } = {};

  banModal: any;

  defaultIcon = '<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 512 512\"><path d=\"M239.1 6.3l-208 78c-18.7 7-31.1 25-31.1 45v225.1c0 18.2 10.3 34.8 26.5 42.9l208 104c13.5 6.8 29.4 6.8 42.9 0l208-104c16.3-8.1 26.5-24.8 26.5-42.9V129.3c0-20-12.4-37.9-31.1-44.9l-208-78C262 2.2 250 2.2 239.1 6.3zM256 68.4l192 72v1.1l-192 78-192-78v-1.1l192-72zm32 356V275.5l160-65v133.9l-160 80z\"/></svg>';
  unlockChatrooms: string;

  showMoreDisclaimer = false;

  get showBar() {
    return !!this.profileService.me;
  }

  socketStatus = SocketStatus;

  get currentSocketStatus() {
    return this.socketService.status$.value;
  }

  @LocalStorage() visibleWidget: string = null;

  @HostListener('document:keydown', ['$event'])
  openSearch(ev: KeyboardEvent) {
    if (ev.code === "KeyF" && ev.ctrlKey) {
      ev.preventDefault()
      this.openPanel(this.panelType.Search);
    }
  }

  get showDisclaimer() {
    return !this.profileService.widgetsDisclaimer.acknowledged &&
      ((this.floatWidgets && this.floatWidgets.length) ||
        (this.panelWidgets && this.panelWidgets.length));
  }

  get panels() {
    return this.panelsService.panels;
  }

  get teamId() {
    return this.teamService.activeTeamId;
  }

  get activeTeam() {
    return this.teamService.activeTeam;
  }

  get openedModals() {
    return this.modalsService.openedModals;
  }

  get window() {
    return window;
  }

  @HostListener('click', ['$event'])
  rightbarClickHandler(event) {
    if (event.target.id && event.target.id.startsWith('open-panel-')) {
      const widgetId = event.target.id.substr(11);
      const widget = this.panelWidgets.find(w => w.id === widgetId);
      this.clickToOpenPanelWidget(widget);
    } else if (event.target.id === 'appbar-toolkit' || event.target.parentElement?.id === 'appbar-toolkit') {
      this.toggleToolkit();
    } else if (event.target.id === 'appbar-members' || event.target.parentElement?.id === 'appbar-members') {
      this.toggleMembers();
    } else if (event.target.id === 'appbar-marketplace' || event.target.parentElement?.id === 'appbar-marketplace') {
      this.marketplace();
    } else if (event.target.id === 'appbar-mentions' || event.target.parentElement?.id === 'appbar-mentions') {
      this.openPanel(this.panelType.Mentions);
    } else if (event.target.id === 'appbar-files' || event.target.parentElement?.id === 'appbar-files') {
      this.openPanel(this.panelType.Files);
    } else if (event.target.id === 'appbar-bookmarks' || event.target.parentElement?.id === 'appbar-bookmarks' || event.target.parentElement?.parentElement?.id === 'appbar-bookmarks') {
      this.openPanel(this.panelType.PinboardMessages);
    } else if (event.target.id === 'appbar-favorites' || event.target.parentElement?.id === 'appbar-favorites') {
      this.openPanel(this.panelType.BookmarkMessages);
    } else if (event.target.id === 'appbar-search' || event.target.parentElement?.id === 'appbar-search') {
      this.openPanel(this.panelType.Search);
    } else if (event.target.id === 'appbar-pulse' || event.target.parentElement?.id === 'appbar-pulse') {
      this.openPanel(this.panelType.PulseMessages);
    } else if (event.target.id === 'appbar-signals' || event.target.parentElement?.id === 'appbar-signals' || event.target.parentElement?.parentElement?.id === 'appbar-signals') {
      this.openPanel(this.panelType.SignalMessages);
    } else if (event.target.id === 'dashboard-btn' || event.target.parentElement?.id === 'dashboard-btn') {
      this.openDashboard();
    } else if (event.target.id === 'widget-consent') {
      this.acceptWidgetDisclaimer();
    } else if (event.target.id === 'widget-disclaimer-showmore') {
      this.showMoreDisclaimer = true;
    } else if (event.target.id === 'float-modal-close') {
      const position = event.target.getAttribute('data-float-position');
      this.closeFloatModal(this.openedModals[position]);
    } else if (event.target.parentElement?.id === 'float-modal-close') {
      const position = event.target.parentElement.getAttribute('data-float-position');
      this.closeFloatModal(this.openedModals[position]);
    } else if (event.target.id === 'float-modal-minimize') {
      const position = event.target.getAttribute('data-float-position');
      this.minimizeFloatModal(this.openedModals[position]);
    } else if (event.target.parentElement?.id === 'float-modal-minimize') {
      const position = event.target.parentElement.getAttribute('data-float-position');
      this.minimizeFloatModal(this.openedModals[position]);
    }
  }

  constructor(
    private profileService: ProfileService,
    private teamService: TeamService,
    private roomService: RoomService,
    private panelsService: PanelsService,
    private modalsService: ModalsService,
    private modal: NgbModal,
    private socketService: SocketService,
    private messageCountService: MessageCountService,
    private tracking: TrackingService,
    private zone: NgZone,
    private scriptService: ScriptService
  ) {
    super();
    console.log('RIGHTBAR CONSTRUCTION');
  }

  async ngOnInit() {
    this.subscribe(
      // distinct until changed to skip multiple emits for the same id
      this.teamService.activeTeamId$.pipe(distinctUntilChanged()),
      async (teamId: string) => {
        if (this.activeTeam.member.isBanned) {
          this.banned();
        } else {
          this.unbanned();
        }
        this.loadWidgets();

        this.setButtonClasses();

      });

    this.subscribe(
      this.teamService.teamMemberBanned$,
      async (data: Events.MemberBanned) => {
        if (data && this.teamService.activeTeam.id === data.teamId) {
          if (this.profileService.me.id === data.userId) {
            this.banned();
          }
        }
      });

    this.subscribe(
      this.teamService.teamMemberUnbanned$,
      async (data: Events.MemberUnbanned) => {
        if (data && this.activeTeam.id === data.teamId) {
          if (this.profileService.me.id === data.userId) {
            this.unbanned();
          }
        }
      });

    this.subscribe(
      this.teamService.widgetAdded$,
      async (data: Events.WidgetAdded) => {
        if (!data || data.teamId !== this.teamId) return;

        const widget = data.widget as Widget;

        if (widget.position === 'floating') {
          widget.zIndex = this.floatWidgets.length * 100 + 1;
          widget.type = 'WIDGET';
          widget.teamId = this.teamId;
          widget.resizable = true;
          this.floatWidgets.push(widget);
        } else {
          this.panelWidgets.push(widget);

          if (!this.openedWidgets || !this.openedWidgets.length) {
            this.openedWidgets = [widget];
            this.openPanelWidget(this.panelWidgets[0]);
          }
        }
        this.caclulatePermissions();
      });

    this.subscribe(
      this.teamService.widgetRemoved$,
      async (data: Events.WidgetRemoved) => {
        if (!data || data.teamId !== this.teamId) return;

        const pi = this.panelWidgets.findIndex(x => x.id === data.widgetId);
        if (pi !== -1) {
          this.panelWidgets.splice(pi, 1);

          const oi = this.openedWidgets.findIndex(x => x.id === data.widgetId);
          if (pi !== -1) {
            this.openedWidgets.splice(pi, 1);
            this.openedWidgets = this.openedWidgets.filter((elem, pos, arr) => {
              return arr.findIndex(e => e.id === elem.id) === pos;
            });
            if (this.visibleWidget === data.widgetId && this.panelWidgets[0]) {
              this.openPanelWidget(this.panelWidgets[0]);
            }
          }
        }

        const fi = this.floatWidgets.findIndex(x => x.id === data.widgetId);
        if (fi !== -1) {
          this.closeFloatModal(this.floatWidgets[fi]);
          this.floatWidgets.splice(fi, 1);
        }
      });

    this.subscribe(
      this.roomService.teamRoomUpdated$,
      (data: Events.RoomPermUpdated) => {
        this.caclulatePermissions();
      });

    this.subscribe(
      this.roomService.teamRoomRemoved$,
      (data: Events.RoomPermUpdated) => {
        this.caclulatePermissions();
      });

    this.subscribe(
      this.teamService.widgetsReordered$,
      async (data: Events.WidgetsReordered) => {
        if (!data || data.teamId !== this.teamId) return;

        this.panelWidgets.sort((a, b) => {
          return data.widgetIds.findIndex(x => x === a.id) - data.widgetIds.findIndex(x => x === b.id);
        });
      });

    this.subscribe(
      this.panelsService.panelClosed$,
      (id) => {
        switch (id) {
          case `${this.teamId}_${this.panelType.Mentions}`:
            this.setClass(this.panelType.Mentions);
            break;
          case `${this.teamId}_${this.panelType.Files}`:
            this.setClass(this.panelType.Files);
            break;
          case `${this.teamId}_${this.panelType.PinboardMessages}`:
            this.setClass(this.panelType.PinboardMessages);
            break;
          case `${this.teamId}_${this.panelType.BookmarkMessages}`:
            this.setClass(this.panelType.BookmarkMessages);
            break;
          case `${this.teamId}_${this.panelType.Search}`:
            this.setClass(this.panelType.Search);
            break;
          case `${this.teamId}_${this.panelType.PulseMessages}`:
            this.setClass(this.panelType.PulseMessages);
            break;
          case `${this.teamId}_${this.panelType.SignalMessages}`:
            this.setClass(this.panelType.SignalMessages);
            break;
        }
      });

    if (this.activeTeam && this.activeTeam.member.isBanned) {
      this.banned();
    }

    this.loadWidgets();
    this.setButtonClasses();
  }

  acceptWidgetDisclaimer() {
    this.profileService.widgetsDisclaimer.acknowledged = true;
    this.profileService.widgetsDisclaimer.save();
  }

  banned() {

    // close panels
    this.panelsService.closeAll();
    this.modal.dismissAll();
    this.panelWidgets = [];
    this.visibleWidget = null;
    this.apps[`${this.teamId}_Toolkit`] = false;
    this.apps.save();
    // open ban modal
    this.banModal = this.modal.open(BannedTeamUserComponent, {
      backdrop: 'static',
      backdropClass: 'backdrop-shifted',
      keyboard: false,
      centered: true,
      windowClass: 'modal-dark shifted',
    });

  }

  unbanned() {
    if (this.banModal) {
      this.banModal.close();
    }
  }

  toggleToolkit() {
    if (this.teamService.activeTeam.member.isBanned) {
      this.banned();
      return;
    }

    if (this.panelWidgets && this.panelWidgets[0]) {
      this.openPanelWidget(this.panelWidgets[0]);
    }

    this.toggleApp(`${this.teamId}_Toolkit`);
    this.apps.save();
  }

  toggleMembers() {
    this.toggleApp(`${this.teamId}_Members`);
  }

  toggleApp(id: string) {
    this.apps[id] = !this.apps[id];
    this.apps.save();
  }

  async marketplace() {
    const ret = await this.scriptService.loadScript('getsitecontrol');
    // this.log('GETSITECONTROL', ret);
    if (!window['gsc']) {
      return;
    }
    window['gsc']('params', {
      username: this.profileService.me.username,
      email: this.profileService.me.email,
      team: this.teamService.activeTeam.name,
    });
    window['gsc']('show', 34053);
  }

  stream() {
    const modal = this.modal.open(StreamComponent, {
      centered: true,
      scrollable: true,
      backdrop: false,
      size: 'lg',
      windowClass: 'meetings-modal modal-dark'
    });
  }

  loadWidgets() {
    // this.log('load widgets');
    if (!this.teamService.activeTeam || !this.teamService.activeTeam.widgets) {
      this.panelWidgets = [];
      this.visibleWidget = null;
      this.apps[`${this.teamId}_Toolkit`] = false;
      this.apps.save();
      return;
    }

    console.log(this.teamService.activeTeam.widgets);

    this.floatWidgets = this.teamService.activeTeam.widgets
      .filter(w => w.config && w.position === 'floating')
      .map((w, i) => {
        w['zIndex'] = 100 + i;
        w['type'] = 'WIDGET';
        w['teamId'] = this.teamId;
        w['resizable'] = true;
        return w;
      })
      .map(x => x as Widget);

    this.panelWidgets = this.teamService.activeTeam.widgets
      // tslint:disable-next-line:max-line-length
      .filter(w => w.config && (w.position === 'sidebar' || !w.position) || !w.config)
      .map(x => x as Widget);

    if (!this.panelsService.defaultwidgets[this.teamService.activeTeamId]) {
      this.toggleToolkit();

      this.panelsService.defaultwidgets[this.teamService.activeTeamId] = true;
      this.panelsService.defaultwidgets.save();
    }

    this.caclulatePermissions();

    // if (this.apps[`${this.teamId}_Toolkit`] && this.panelWidgets && this.panelWidgets[0]) {
    //   this.openPanelWidget(this.panelWidgets[0]);
    // }
    
    if (this.apps[`${this.teamId}_Toolkit`]
      && this.panelWidgets && this.panelWidgets[0]) {
      this.openPanelWidget(this.panelWidgets[0]);
    }
  }
  genJWT() {

    const oHeader = { alg: 'HS256', typ: 'JWT' };
    const tNow = KJUR.jws.IntDate.get('now');
    const tEnd = KJUR.jws.IntDate.get('now + 1day') ;
    const oPayload = {
      context: {
        user: {
          id: `!${Md5.init(this.profileService.me.email)}1`,
          avatar: `${this.profileService.me.avatar}`,
          email: `${this.profileService.me.email}`,
          displayName: `${this.profileService.me.username}`,
          isadmin: `${this.teamService.activeTeam.permissions.dashboard_access}`
        },
        community: `${this.teamService.activeTeam.name}`
      },
      iat: tNow,
      exp: tEnd
    };
    const sHeader = JSON.stringify(oHeader);
    const sPayload = JSON.stringify(oPayload);
    const sJWT = KJUR.jws.JWS.sign('HS256', sHeader, sPayload, 'widgets.ensemble');
    // console.log(sJWT);
    return sJWT;
  }

  caclulatePermissions() {
    //console.log('calculate widgets permissions');

    this.floatWidgets.forEach(w => {
      if (w.url.includes('ensemble.chat/widgets/')) {
        w.url += `?t=${this.genJWT()}`;
      }

      if (w['billingChatrooms'].length) {
        const roomPermissions = this.teamService.activeTeam.chatrooms
          .filter(c => w['billingChatrooms'].indexOf(c.id) > -1)
          .map(c => c.permissions.user.read_messages);
        w['hasAccess'] = roomPermissions.some(x => x) || roomPermissions.length === 0;

        w['unlockChatrooms'] = this.teamService.activeTeam.chatrooms
          .filter(c => w['billingChatrooms'].indexOf(c.id) > -1)
          .map(c => `#${c.name}`)
          .join(', ');
      } else {
        w['hasAccess'] = true;
      }
    });

    this.panelWidgets.forEach(w => {
      if (w.url.includes('ensemble.chat/widgets/')) {
        w.url += `?t=${this.genJWT()}`;
      }

      if (w['billingChatrooms'].length) {
        const roomPermissions = this.teamService.activeTeam.chatrooms
          .filter(c => w['billingChatrooms'].indexOf(c.id) > -1)
          .map(c => c.permissions.user.read_messages);
        w['hasAccess'] = roomPermissions.some(x => x) || roomPermissions.length === 0;

        w['unlockChatrooms'] = this.teamService.activeTeam.chatrooms
          .filter(c => w['billingChatrooms'].indexOf(c.id) > -1)
          .map(c => `#${c.name}`)
          .join(', ');
      } else {
        w['hasAccess'] = true;
      }
    });

    this.openedWidgets.forEach(w => {
      if (w.teamId === this.teamService.activeTeamId) {
        if (w['billingChatrooms'].length) {
          const roomPermissions = this.teamService.activeTeam.chatrooms
            .filter(c => w['billingChatrooms'].indexOf(c.id) > -1)
            .map(c => c.permissions.user.read_messages);
          w['hasAccess'] = roomPermissions.some(x => x) || roomPermissions.length === 0;

          w['unlockChatrooms'] = this.teamService.activeTeam.chatrooms
            .filter(c => w['billingChatrooms'].indexOf(c.id) > -1)
            .map(c => `#${c.name}`)
            .join(', ');
        } else {
          w['hasAccess'] = true;
        }
      }
    });
  }

  clickToOpenPanelWidget(widget: Widget) {
    this.tracking.trackEvent('widget', {
      widgetname: widget.name,
      teamname: this.activeTeam ? this.activeTeam.name : null,
      teamid: this.activeTeam ? this.activeTeam.id : null
    });
    this.openPanelWidget(widget);
  }

  openPanelWidget(widget: Widget) {
    this.openedWidgets.push(widget);
    this.openedWidgets = this.openedWidgets.filter((elem, pos, arr) => {
      return arr.findIndex(e => e.id === elem.id) === pos;
    });
    this.visibleWidget = widget.id;
  }

  // float modals
  clickToOpenFloatModal(modal) {
    this.tracking.trackEvent('widget', {
      widgetname: modal.name,
      teamname: this.activeTeam ? this.activeTeam.name : null,
      teamid: this.activeTeam ? this.activeTeam.id : null
    });
    this.openFloatModal(modal);
  }

  openFloatModal(modal) {
    return this.modalsService.openFloatModal(modal);
  }

  focusFloatModal(modal) {
    return this.modalsService.focusFloatModal(modal);
  }

  minimizeFloatModal(modal) {
    return this.modalsService.minimizeFloatModal(modal);
  }

  closeFloatModal(modal) {
    return this.modalsService.closeFloatModal(modal);
  }

  isFloatModalVisible(modal) {
    return this.modalsService.isFloatModalVisible(modal);
  }

  onStart(event, modal) {
    this.modalsService.focusFloatModal(modal);
    this.movingModal = true;
  }

  onStop(event, modal) {
    this.movingModal = false;
  }

  // end floats

  getFloatModalStyle(modal) {

    if (modal.id === 'STREAM' && this.widgetsSize['STREAM']) {
      return {
        'z-index': modal.zIndex,
        'width.px': this.widgetsSize['STREAM'].width,
        'height.px': this.widgetsSize['STREAM'].height,
      };
    }

    if (this.widgetsSize[`${this.teamId}_${modal.id}`]) {
      return {
        'z-index': modal.zIndex,
        'width.px': this.widgetsSize[`${this.teamId}_${modal.id}`].width,
        'height.px': this.widgetsSize[`${this.teamId}_${modal.id}`].height,
      };
    }
    return {
      'z-index': modal.zIndex,
      'width.px': modal.config.width,
      'height.px': modal.config.height,
    };
  }

  async openPanel(type: PanelType) {
    const panelId = `${this.teamId}_${type}`;

    if (type === PanelType.PinboardMessages) {
      // set unread counter 0
      await this.messageCountService.setPinboardZero();
    }

    if (!this.panelsService.isOpened(panelId)) {
      this.panelsService.open({
        id: panelId,
        teamId: this.teamId,
        type: PanelType[type],
      });
    }

    this.panelsService.show(panelId);

    this.setClass(type);

    this.scrollToPanel(panelId);
  }

  scrollToPanel(id: string) {
    setTimeout(
      () => {
        this.panelsService.slideToPanel(id);
      },
      50);
  }

  onStopResizing(event, modal) {
    if (event.size.height > window.innerHeight || event.size.width > window.innerWidth) {
      return;
    }
    if (this.teamId) {
      if (modal.id === 'STREAM') {
        this.widgetsSize['STREAM'] = { height: event.size.height, width: event.size.width };
      } else {
        this.widgetsSize[`${this.teamId}_${modal.id}`] = { height: event.size.height, width: event.size.width };
      }
      this.widgetsSize.save();
      // modal.config.height = event.size.height;
      // modal.config.width = event.size.width;
    }
  }

  log(...arg) {
    /* istanbul ignore if  */
    if (environment.config.debug) {
      console.log('%c[RIGHT-BAR]', 'color:#198E85', ...arg);
    }
  }

  setClass(term: PanelType) {
    if ((this.panels || []).find(p => p.id === `${this.teamId}_${term}`)) {
      this.cssClass[term] = 'opened';
    } else {
      this.cssClass[term] = '';
    }

    // console.log('CLASSES', this.cssClass);
  }

  setButtonClasses() {
    this.setClass(this.panelType.Mentions);
    this.setClass(this.panelType.Files);
    this.setClass(this.panelType.PinboardMessages);
    this.setClass(this.panelType.BookmarkMessages);
    this.setClass(this.panelType.Search);
    this.setClass(this.panelType.PulseMessages);
    this.setClass(this.panelType.SignalMessages);
  }

  openDashboard() {
    window.open('https://dashboard.ensemble.chat/login', '_blank');
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
