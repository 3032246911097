import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../_core/services/auth.service';
import { BrandingService } from '../../_core/services/branding.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-intro-shell',
  templateUrl: './intro-shell.component.html',
  styleUrls: ['./intro-shell.component.scss']
})
export class IntroShellComponent implements OnInit, AfterViewInit {

  @ViewChild('sso', { static: true }) sso: ElementRef;

  background: string;
  ssoUrl: string = `${environment.config.endpoints.authentication}/authentication/sso.html`;
  sidebarClasses: string[];
  colorCode: string;
  defaultBackgroundImg = 'url("https://www.ensemble.chat/assets/bg3.jpg")';

  // applySmallLogo = false;

  constructor(
    private authService: AuthService,
    private brandingService: BrandingService,
    private elRef: ElementRef,
    private titleService: Title
  ) { }

  get team() {
    return this.brandingService.team;
  }

  ngOnInit() {
    this.authService.sso = this.sso;
    this.titleService.setTitle((this.brandingService.team?.label ?? this.brandingService.team?.name) ?? '');
  }

  ngAfterViewInit() {
    this.hideLoader();
  }

  hideLoader() {
    const loader = this.elRef.nativeElement.ownerDocument.querySelector('.root-loader');
    if (loader) {
      loader.style.display = 'none'; // hide loader
    }
  }

}
